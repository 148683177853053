import {
  Button,
  Typography,
  useTheme,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dataset } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const BillDetails = ({
  type,
  data,
  total,
  addressStore,
  store,
  dataStore,
  deliveryFee,
}) => {
  const cart = useSelector((state) => state.shopReducer.cart);
  const user = useSelector((state) => state.authReducer.userData);
  const [serviceFees, setServiceFees] = useState(0);
  const { code } = useSelector((state) => state.shopReducer.cartData);
  const { discount, isVerify } = useSelector(
    (state) => state.shopReducer.cartData
  );

  const [open, setOpen] = React.useState(false);
  const [opentaxes, setOpentaxes] = React.useState(false);
  const [dataSet, setDataSet] = useState(data);
  const [address, setAddress] = useState(addressStore);
  const [storee, setStore] = useState(store);
  useEffect(() => {
    if (user?.pickupOrDelivery === 'dinein') {
      setServiceFees(dataStore?.service_fees_dineIn);
    } else if (user?.pickupOrDelivery === 'pickup') {
      setServiceFees(dataStore?.service_fees_pickUp);
    } else if (user?.pickupOrDelivery === 'delivery') {
      setServiceFees(dataStore?.service_fees);
    }
  }, [user]);

  useEffect(() => {
    setDataSet(data);
    setAddress(addressStore);
  }, [addressStore, data]);
  useEffect(() => {
    setStore(store);
  }, [store, dataStore, data]);

  const handleCloseTaxes = () => {
    setOpentaxes(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [t, i18n] = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px 10%',
        display: 'contents',
        
      }}
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
    >
      
      {!!cart && !!cart.length && cart.length > 0 && (
        <Box sx={{backgroundColor:"#323232", marginTop:"20px" , padding :"15px" ,borderRadius:"30px" ,width:"92%" , mx:"auto"}}>
          {' '}
          <Box
            sx={{
              width: '100%',
              padding: '12px 2%',
              
            }}
          >
            {' '}
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: 'Outfit, sans-serif', 
                color: "white",
                fontWeight: 500,
                display: 'flex',
              }}
            >
              {t('basket.billDetails')}
            </Typography>
          </Box>
         
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: 'gray',
              }}
            >
              {t('basket.subtotal')}
            </Typography>
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color:'white',
              }}
            >
              $
              {isVerify && code != '' ? (
                <>
                  <span>
                    {parseFloat(
                      (total - discount).toString().replaceAll(',', '')
                    )?.toFixed(2)}
                  </span>
                  <br />
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'gray',
                      marginLeft: '5px',
                    }}
                  >
                    $
                    {parseFloat(
                      total?.toString()?.replaceAll(',', '')
                    )?.toFixed(2)}
                  </span>
                </>
              ) : (
                parseFloat(total?.toString()?.replaceAll(',', ''))?.toFixed(2)
              )}
              <br />
            </Typography>
          </div>
          {type === 'delivery' && (
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 2%',
              }}
            >
              <Typography
                component="a"
                variant="h6"
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  display: 'flex',
                  color: 'gray',
                }}
              >
                {t('basket.deliveryFees')}{' '}
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    marginTop: '5px',
                    marginleft: '4px',
                    paddingTop: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(true)}
                />
              </Typography>

              <Typography
                component="a"
                variant="h6"
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  display: 'flex',
                  color:"white",
                }}
              >
                <p key={'key'}>
                  {dataSet?.id === address ? (
                    <p>
                      $
                      {parseFloat(
                        deliveryFee + total * dataSet?.fee_miscellaneous
                      )?.toFixed(2)}
                  
                    </p>
                    
                  ) : (
                    ''
                  )}
                </p>
              </Typography>
            </div>
          )}
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: 'gray',
              }}
            >
              {/* not the dialog */}
              {type === 'pickup' || type === 'dinein' ? (
                <span>{t('basket.estTaxesAndFees')}</span>
              ) : (
                <span>{t('basket.estTaxes')}</span>
              )}

              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  marginTop: '5px',
                  marginleft: '4px',
                  paddingTop: '4px',
                  cursor: 'pointer',
                }}
                style={{ direction: 'initial' }}
                onClick={() => setOpentaxes(true)}
              />
            </Typography>

            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
              }}
              style={{ direction: 'initial' }}
            >
              <p style={{color:"white",fontFamily:"Outfit"}}>
                <Dialog
                  open={opentaxes}
                  onClose={handleCloseTaxes}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {' '}
                    <Typography
                      component="p"
                      variant="h6"
                      style={{ display: 'flex', justifyContent: 'center'  ,color:"white"}}
                    >
                      {type === 'pickup' || type === 'dinein' ? (
                        <span>{t('basket.estTaxesAndFees')}</span>
                      ) : (
                        <span>{t('basket.estTaxes')} </span>
                      )}
                    </Typography>
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {(type == 'pickup' || type == 'dinein') && (
                        <div>
                          <span style={{ color: 'white' }}>
                            {' '}
                            Service fee:{' '}
                            {serviceFees !== undefined && serviceFees !== null
                              ? `$${serviceFees.toFixed(2)}`
                              : 'N/A'}
                          </span>
                          <br />
                          {t(
                            'basket.thisServiceFeesHelpsUsOperateTheOnlineOrderingSystem'
                          )}
                          <br />
                        </div>
                      )}
                      <br />
                      <span style={{ color: 'white' }}>
                        {' '}
                        {t('basket.estTaxes')}: $
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {' '}
                            {type === 'dinein'
                              ? dataStore?.tax_percentage.toFixed(2)
                              : type === 'delivery'
                              ? dataSet?.tax_percentage.toFixed(2)
                              : dataStore?.tax_percentage.toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''}
                            {type === 'dinein'
                              ? (dataStore?.tax_percentage * total).toFixed(2)
                              : type === 'delivery'
                              ? (dataSet?.tax_percentage * total).toFixed(2)
                              : (dataStore?.tax_percentage * total).toFixed(2)}
                          </>
                        )}{' '}
                      </span>
                      <br />
                      {t('basket.finalisedTaxWillBeShownOnYourOrderReceipt')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseTaxes}
                      color="primary"
                      style={{
                        backgroundColor: environment.mainColor,
                        color: 'white',
                      }}
                    >
                      {t('basket.oK')}
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* finish dialog */}
                {type === 'dinein' && (
                  <p>
                    {dataStore?.restaurant_name === storee ? (
                      <p>
                        {' '}
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''}$
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage -
                                    discount +
                                    dataStore?.service_fees_dineIn
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_dineIn
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage *
                                      (total - discount) +
                                    dataStore?.service_fees_dineIn
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage * total +
                                    dataStore?.service_fees_dineIn
                                  ).toFixed(2)
                                )}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>
                )}
                {type === 'delivery' && (
                  <p>
                    {dataSet?.id === address ? (
                      <p style={{color:"white"}}>
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''} ${' '}
                            {isVerify
                              ? parseFloat(
                                  dataSet?.tax_percentage
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  dataSet?.tax_percentage

                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''} ${' '}
                            {isVerify
                              ? parseFloat(
                                  (dataSet?.tax_percentage * (total - discount))
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (dataSet?.tax_percentage * total)
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>
                )}

                {type === 'pickup' && (
                  <p>
                    {dataStore?.restaurant_name === storee ? (
                      <p>
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''} ${' '}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_pickUp
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_pickUp
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage *
                                      (total - discount) +
                                    dataStore?.service_fees_pickUp
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage * total +
                                    dataStore?.service_fees_pickUp
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>
                )}
              </p>
            </Typography>
          </div>
          <Divider />
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: "white",
                fontWeight: 'bold',
              }}
            >
              {t('basket.total')}
            </Typography>
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: "white",
                fontWeight: 'bold',
              }}
            >
              {type === 'pickup' && (
                <div>
                  <p>
                    {dataStore?.restaurant_name === store ? (
                      <p>
                        {' '}
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_pickUp +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_pickUp +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''} ${' '}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage *
                                      (total - discount) +
                                    dataStore?.service_fees_pickUp +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage * total +
                                    dataStore?.service_fees_pickUp +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
              {type === 'dinein' && (
                <div>
                  <p>
                    {dataStore?.restaurant_name === storee ? (
                      <p>
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_dineIn +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage +
                                    dataStore?.service_fees_dineIn +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax_percentage *
                                      (total - discount) +
                                    dataStore?.service_fees_dineIn +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax_percentage * total +
                                    dataStore?.service_fees_dineIn +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
              {type === 'delivery' && (
                <div>
                  <p>
                    {dataSet?.id === address ? (
                      <p>
                        {' '}
                        {dataStore?.percentage_type === 'amount' ? (
                          <>
                            {''} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataSet?.tax_percentage +
                                    (total - discount) +
                                    deliveryFee +
                                    total * dataSet?.fee_miscellaneous
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataSet?.tax_percentage +
                                    total +
                                    deliveryFee +
                                    total * dataSet?.fee_miscellaneous
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {} $
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataSet?.tax_percentage *
                                      (total - discount) +
                                    (total - discount) +
                                    deliveryFee
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataSet?.tax_percentage * total +
                                    total +
                                    +total * dataSet?.fee_miscellaneous +
                                    deliveryFee
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
            </Typography>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {' '}
              <Typography
                component="p"
                variant="h6"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {t('basket.deliveryFees')}
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  'basket.deliveryFeesAreCalculatedBasedOnYourLocationAndOtherFactors'
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                style={{
                  backgroundColor: environment.mainColor,
                  color: 'white',
                }}
              >
                {t('basket.oK')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};
export default BillDetails;
