import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import HomeIcon from '@mui/icons-material/Home';
import AddressHandlerDialog from './AddressHandler';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import AddressPickerWithGoogle from './GoogleAutoComplete';
import { useSelector } from 'react-redux';
import { firestore } from '../../../../../../libs/firebase-service/src';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useDispatch } from 'react-redux';
import { userAddresses } from '../../../../../../libs/redux-slices/src';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CustomButton } from '../../../../../../libs/components/src';

const Address = () => {
  const [open, setOpen] = React.useState(false);
  const [googleAddress, setGoogleAddress] = useState('');
  const [zz, setZipCode] = useState('');
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const adresses = useSelector((state) => state.authReducer.userAddresses);
  const [adressess, setAdresses] = useState([]);
  const token = useSelector((state) => state.authReducer.token);
  const [adressToDelete, setAdressToDelete] = useState('');
  const [editingAddress, setEditingAddress] = useState(null); 

  
const [formChanged, setFormChanged] = useState(false); 
const [showCancelDialog, setShowCancelDialog] = useState(false);
const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  const user = useSelector((state) => state.authReducer.userData);
  
  const dispatch = useDispatch()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', (e) => setIsLargeScreen(e.matches));
    
    return () => mediaQuery.removeEventListener('change', (e) => setIsLargeScreen(e.matches));
  }, []);


  const getGoogleAddress = ({
    country,
    state,
    city,
    zipCode,
    placeId,
    displayName,
    lat,
    lng,
    streetNumber,
    route,
  }) => {
    setGoogleAddress({
      country,
      state,
      city,
      zipCode,
      placeId,
      displayName,
      lat,
      lng,
      streetNumber,
      route,
    });
  };
  const handleSubmit = async (values) => {
    setZipCode(values.zipCode);
    setErr('');
    setLoading(true);
  
    // Preserve old address when editing if no new address is selected
    let addressToSave = googleAddress && Object.keys(googleAddress).length > 0 
      ? googleAddress 
      : editingAddress
        ? {
            country: editingAddress.country,
            state: editingAddress.state,
            city: editingAddress.city,
            zipCode: editingAddress.zipCode,
            placeId: editingAddress.placeId,
            displayName: editingAddress.displayName,
            lat: editingAddress.lat,
            lng: editingAddress.lng,
            streetNumber: editingAddress.streetNumber,
            route: editingAddress.route,
          }
        : null;
  
    if (!addressToSave || !addressToSave.country || !addressToSave.city) {
      setErr('Please enter a valid address.');
      setLoading(false);
      return;
    }
  
    if (addressToSave.zipCode !== '' ? addressToSave.zipCode : values.zipCode) {
      try {
        let docRef;
        let newAddressData = {
          ...addressToSave,
          additionalDetails: values.additionalDetails,
          nickName: value,
          deliveryInstructions: values.deliveryInstructions,
          validated_on: new Date(),
          primaryLine: '',
          is_deliverable: false,
          delivery_store_id: null,
        };
  
        if (editingAddress) {
          // **Update Existing Address**
          docRef = firestore
            .collection('users')
            .doc(user?.uid)
            .collection('addresses')
            .doc(editingAddress.id);
  
          await docRef.update(newAddressData);
  
          // Update Redux store
          const updatedAddresses = adresses.map((addr) =>
            addr.id === editingAddress.id ? { ...addr, ...newAddressData } : addr
          );
  
          dispatch(userAddresses(updatedAddresses));
        } else {
          // **Add New Address**
          docRef = firestore
            .collection('users')
            .doc(user?.uid)
            .collection('addresses')
            .doc();
  
          await docRef.set({ ...newAddressData, id: docRef.id, createdAt: new Date() });
  
          // Update Redux store
          const updatedAddresses = adresses
            ? [...adresses, { ...newAddressData, id: docRef.id }]
            : [{ ...newAddressData, id: docRef.id }];
          dispatch(userAddresses(updatedAddresses));
        }
  
        // Reset State After Submission
        setGoogleAddress('');
        setShowAddAddressForm(false);
        setEditingAddress(null);
        setFormChanged(false);
      } catch (error) {
        console.error('Error saving address:', error);
      }
    } else {
      setErr('Please add a zipcode');
    }
  
    setLoading(false);
  };
  
  
  const handleClosee = () => {
    setOpenDelete(false);
  };

  const handleCancelAddAddress = () => {
    if (formChanged) {
      setShowCancelDialog(true); 
    } else {
      setShowAddAddressForm(false); 
    }
  };
  const addressOptions = ['Home', 'Work', 'University'];

  const deleteAdress = async () => {

    if (adressToDelete) {
      const docRef = firestore
        .collection('users')
        .doc(token)
        .collection('addresses')
        .doc(adressToDelete);
      await docRef.delete().catch((e) => {
        console.log(e);
      }).then(() => {
        const indexToDelete = adresses.findIndex((obj) => obj.id === adressToDelete);
        if (indexToDelete !== -1) {
          const newArray = adresses
            .slice(0, indexToDelete)
            .concat(adresses.slice(indexToDelete + 1));
          dispatch(userAddresses(newArray));
        } else {
          console.log("Object not found");
        }

      })
    }
    setOpenDelete(false);
    setAdressToDelete('');
  };

  const handleClickOpen = () => {
    
    setEditingAddress(null); 
    setGoogleAddress(''); 
    setShowAddAddressForm(true); 


  };
  const handleClose = () => {
    setOpen(false);
  };
  const [t, i18n] = useTranslation()

  return (
    <>
    {showAddAddressForm ? (<Box
     sx={{
      backgroundColor: '#151414',
borderRadius: '15px',
padding: '20px',
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
marginTop: { xs: '10px',sm:'50px' , md: '20px' },
marginLeft: { xs: '10px', sm: '40px' ,md:'50px' },
marginRight: { xs: '10px', md: '50px' },
marginBottom :{xs:"170px" ,md :"170px" ,lg:"170px",},
maxWidth: '900px',
minHeight: '510px',
      
    }}>
      <Typography
            component="p"
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: '30px',
              color:"white",
              fontFamily: 'Outfit, sans-serif'
            }}
          >
            {t('profilee.addressDetails')}
          </Typography>
          <Formik
  initialValues={{
    additionalDetails: editingAddress?.additionalDetails || '',
    deliveryInstructions: editingAddress?.deliveryInstructions || '',
    nickName: editingAddress?.nickName || '',
    zipCode: editingAddress?.zipCode || '',
  }}
  enableReinitialize={true} // Ensures values update when editingAddress changes
  onSubmit={handleSubmit}
>
            {({
              handleBlur,
              handleChange,
              values,
              errors,
              handleSubmit,
              touched,
            }) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={1}>
                {/* Address and Zip Code Row */}
                <Grid container item spacing={1} md={12} xs={12}>
                  <Grid item md={6} xs={12}>
                    <AddressPickerWithGoogle
                      initialValue={editingAddress?.displayName || ''}
                      getValues={getGoogleAddress}
                      style={{ cursor: 'pointer !important' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel
                      htmlFor="zipcode"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      {t('profilee.zipCode')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Zip Code*"
                      name="zipCode"
                      required
                      onChange={(e) => {
                        handleChange(e);
                        setFormChanged(true);
                      }}
                      onClick={() => setZipCode(values.zipCode)}
                      onBlur={handleBlur('zipCode')}
                      value={values.zipCode ? values.zipCode : googleAddress.zipCode}
                      variant="outlined"
                      id="zipcode"
                      sx={textFieldStyles}
                    />
                    {errors.zipCode && touched.zipCode && (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.zipCode}
                      </span>
                    )}
                  </Grid>
                </Grid>
            
                {/* Additional Details and Address Name Row */}
                <Grid container item spacing={1} md={12} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel
                      htmlFor="additionalDetails"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      {t('profilee.additionalDetails')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Additional Details*"
                      name="additionalDetails"
                      onChange={handleChange('additionalDetails')}
                      onBlur={handleBlur('additionalDetails')}
                      value={values.additionalDetails}
                      variant="outlined"
                      sx={textFieldStyles}
                    />
                    {errors.additionalDetails && touched.additionalDetails && (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.additionalDetails}
                      </span>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel
                      htmlFor="nickname"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      Address Name
                    </InputLabel>
                    <Autocomplete
                      freeSolo
                      options={addressOptions}
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter or Select Address Name"
                          variant="outlined"
                          sx={textFieldStyles}
                        />
                      )}
                    />
                    {/* <Grid item lg={12} style={{ marginTop: '10px' }}>
                      {addressOptions.map((option) => (
                        <Button
                          key={option}
                          variant="contained"
                          style={{
                            backgroundColor: '#F2F0F0',
                            color: 'black',
                            marginRight: '10px',
                          }}
                          onClick={() => setValue(option)}
                        >
                          {option}
                        </Button>
                      ))}
                    </Grid> */}
                  </Grid>
                </Grid>
            
                {/* Delivery Instructions Row (Single Field) */}
                <Grid item md={12} xs={12}>
  <InputLabel
    htmlFor="deliveryInstructions"
    shrink
    sx={{
      color: 'gray',
      fontSize: '17px',
      marginLeft: '5px',
      fontWeight: '400',
      fontFamily: 'Outfit, sans-serif',
      marginBottom: '5px',
    }}
  >
    {t('profilee.deliveryInstructions')}
  </InputLabel>
  <TextField
    // placeholder={`${t('profilee.deliveryInstructions')}`}
    fullWidth
    variant="outlined"
    name="deliveryInstructions"
    onChange={handleChange('deliveryInstructions')}
    onBlur={handleBlur('deliveryInstructions')}
    sx={textFieldStyles}
    value={values.deliveryInstructions}
    multiline
    rows={3} // Set the number of rows to 3
  />
  {errors.deliveryInstructions && touched.deliveryInstructions && (
    <span
      style={{
        marginTop: '10px',
        color: 'red',
        fontSize: '10px',
      }}
    >
      {errors.deliveryInstructions}
    </span>
  )}
</Grid>

            
                {/* Save and Cancel Buttons */}
                <Grid
                  item
                  lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '15px',
                    marginTop: '20px',
                    marginLeft: '20px',
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#A61B1B',
                      color: 'white',
                      textTransform: 'none',
                      padding: '10px 30px',
                      borderRadius: '25px',
                      fontFamily: 'Outfit, sans-serif',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      minWidth: '120px',
                    }}
                    onClick={handleSubmit}
                  >
                    {t('profilee.saveAddress')}
                  </Button>
            
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#333333',
                      color: 'white',
                      textTransform: 'none',
                      padding: '10px 30px',
                      borderRadius: '25px',
                      fontFamily: 'Outfit, sans-serif',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      minWidth: '120px',
                    }}
                    onClick={handleCancelAddAddress}
                  >
                    {t('profilee.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </div>
            
            )}
          </Formik>

    

   
    </Box>):(
     <Box
     sx={{
      backgroundColor: '#151414',
borderRadius: '15px',
padding: '20px',
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
marginTop: { xs: '10px',sm:'150px', md: '20px' },
marginLeft: { xs: '10px', sm: '40px' ,md:'50px' },
marginRight: { xs: '10px', md: '50px' },
marginBottom :{xs:"170px" ,md :"170px" ,lg:"170px",},
maxWidth: '900px',
minHeight: '510px',
      
    }}
                >
    <div
      className="mt-3.5"
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px'  ,fontFamily: 'Outfit, sans-serif', }}>
  <Typography
    variant="h6"
    style={{
      color: '#fff', 
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 'bold',
      margin: 0,
    }}
  >
    Saved Address
  </Typography>

  <Button
    disableRipple
    disableFocusRipple
    disableTouchRipple
    variant="contained"
    style={{
      backgroundColor: environment.mainColor, 
      color: '#fff',
      padding: '10px 20px', 
      borderRadius: '30px',
      fontFamily: 'Outfit, sans-serif', 
      fontWeight: 'bold',
      textTransform: 'none', 
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    }}
    onClick={handleClickOpen}

  >
    {t("profilee.addNewAddress")}
  </Button>
</div>

      <AddressHandlerDialog open={open} handleClose={handleClose} />
      <div
  style={{
    display: 'grid',
    gap: '20px',
    padding: '20px',
    gridTemplateColumns: isLargeScreen ? '1fr 1fr' : '1fr',
  }}
 
>
  {adresses?.map((c) => (
    <div
      key={c.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#1c1c1c',
        color: '#fff',
        fontFamily: 'Outfit, sans-serif',
        padding: '40px',
        borderRadius: '15px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
        <Typography
      variant="h6"
      style={{
        margin: '0',
        fontFamily: 'Outfit, sans-serif',
        fontSize: '18px',
        
        color: '#fff',
      }}
    >
      {user?.fullName}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontFamily: 'Outfit, sans-serif',
        fontSize: '14px',
        my: 2,
        color: '#DADADA',
      }}
    >
      {user?.phoneNumber}
    </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      
        <div>
         
          <p style={{ margin: '0', fontSize: '14px', color: '#a0a0a0' }}>
            {c.displayName}
          </p>

        </div>
      </div>

      <div style={{ flex: 1 }}>
  <Typography
    variant="body2"
    style={{
      fontSize: '14px',
      margin: '10px 0',
      color: '#807D7E',
    }}
  >
    {c.address}
  </Typography>
</div>

   
{c.nickName && (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '10px',
      minHeight: '40px',
    }}
  >
    <Box
      variant="contained"
      style={{
        backgroundColor: '#353535',
        color: '#BEBEBE',
        fontSize: '12px',
        padding: '10px 15px',
        borderRadius: '5px',
        textTransform: 'none',
        fontFamily: 'Outfit, sans-serif',
      }}
    >
      {c.nickName}
    </Box>
  </div>
)}

<div
  style={{
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px', 
    marginBottom:"0px",
  }}
>
  <Button
    variant="text"
    style={{
      color: '#FF0004', // Red color for Remove
      textTransform: 'none',
      fontFamily: 'Outfit, sans-serif',
     
      fontSize: '15px',
    }}
    onClick={() => {
      setAdressToDelete(c.id);
      setOpenDelete(true);
    }}
  >
    Remove
  </Button>

  {/* Separator */}
  <span style={{ color: '#a0a0a0', fontSize: '14px' }}>|</span>

  <Button
    variant="text"
    style={{
      color: '#A5FF69', 
      textTransform: 'none',
      
      fontSize: '15px',
      fontFamily: 'Outfit, sans-serif',
    }}
    onClick={() => {
      setEditingAddress(c); 
      setShowAddAddressForm(true); 
    }}
  >
    Edit
  </Button>

  
 
</div>

    </div>
  ))}
</div>
<Dialog
  open={showCancelDialog}
  onClose={() => setShowCancelDialog(false)}
  aria-labelledby="confirm-cancel-dialog"
  PaperProps={{
    style: {
      backgroundColor: '#151414',
      borderRadius: '10px',
      padding: '20px',
      color: 'white',
    },
  }}
>
  <DialogContent>
    <DialogContentText
      style={{
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: 'Outfit, sans-serif',
      }}
    >
      {t('profilee.unsavedChanges')}
    </DialogContentText>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center', gap: '10px' }}>
    {/* Cancel Button */}
    <Button
      style={{
        backgroundColor: '#333333',
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: '20px',
        fontFamily: 'Outfit, sans-serif',
      }}
      onClick={() => setShowCancelDialog(false)} // Close dialog
    >
      {t('profilee.continueEditing')}
    </Button>

    {/* Confirm Discard Button */}
    <Button
      style={{
        backgroundColor: '#FF4C4C',
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: '20px',
        fontFamily: 'Outfit, sans-serif',
      }}
      onClick={() => {
        setShowCancelDialog(false);
        setShowAddAddressForm(false);
        setFormChanged(false); // Reset form state
      }}
    >
      {t('profilee.discardChanges')}
    </Button>
  </DialogActions>
</Dialog>
<Dialog
  style={{ zIndex: 10 }}
  open={openDelete}
  keepMounted
  onClose={handleClose}
  aria-describedby="alert-dialog-slide-description"
  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
  slotProps={{
    backdrop: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
      },
    },
  }}
  PaperProps={{
    style: {
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)', 
      backgroundColor: '#151414', 
      borderRadius: '10px', 
      padding: '20px', 
      color: 'white', 
    },
  }}
>
  <DialogContent>
    <DialogContentText
      id="alert-dialog-slide-description"
      style={{
        color: '#FFFFFF', 
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        fontFamily: 'Outfit, sans-serif', 
      }}
    >
      {t('profilee.youWantDeleteAdress')}
    </DialogContentText>
  </DialogContent>
  <DialogActions
    style={{
      display: 'flex',
      justifyContent: 'space-between', 
      padding: '0 24px 16px 24px',
    }}
  >
    <Button
      style={{
        backgroundColor: '#FF4C4C', 
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: '20px',
        fontFamily: 'Outfit, sans-serif', 
      }}
      onClick={handleClosee}
    >
      {t('profilee.cancel')}
    </Button>
    <Button
      style={{
        backgroundColor: environment.mainColor, 
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: '20px',
        fontFamily: 'Outfit, sans-serif', 
      
      }}
      onClick={() => deleteAdress()}
    >
      {t('profilee.delete')}
    </Button>
  </DialogActions>
</Dialog>

    </div>
    </Box>)}
    </>
  );
};

export default Address;


const textFieldStyles = {
  backgroundColor: '#121212', 
  '& label.Mui-focused': { color: 'gray' }, 
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px', 
    '& fieldset': { borderColor: '#333' }, 
    '&:hover fieldset': { borderColor: '#555' }, 
    '&.Mui-focused fieldset': { borderColor: 'gray' }, 
    '& input': { color: 'white' }, 
    '& input::placeholder': { color: 'gray', opacity: 1 }, 
  },
};
