import React, { useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Checkbox from '@mui/material/Checkbox';
import { environment } from '../../../environments/environment';
import ChildItemModifier from './ChildItemModifier';

function InnerItem({
  modifier,
  modifiersGroup,
  handleChooseModifires,
  option,
  index,
  setOptionsPrice,
  optionsPrice,
  showResults,
  max,
  setMax,
  setErr,
  extraQuantity,
  setExtraQuantity,
  setZeros,
  checkSumM,
  checkSumAdd,
  checkBoxchange,
  handleChange,
  handleChangeChild,
  setCalculatedPrice,
  zeros,
  checkedModifiers,
  setCheckedModifiers,
  checkedOptions,
  setMaxErr,
  summErr,
  modid,
  optionid,
  errReq,
  setCheckedOptions,
  setShowChildP,
  setOptionsPriceChild,
  optionsPriceChild,
  checkBoxchangeChild,
}) {
  // const [value, setValue] = useState(option?.defaultQuantity);
  const [value, setValue] = useState(option?.defaultQuantity);
  const [state, setState] = useState({});
  const [showChild, setShowChild] = useState(false);
  const [sum, setSum] = useState({});
  return (
    <>
      <Grid item lg={6}>
        <FormControlLabel
          value={option?.title}
          control={
            <Checkbox
              name={option?.title}
              onChange={(e) => {
                // sum[option?.id] = value;
                checkSumM(sum, option.id, modifier);
                if (e?.target?.checked) {
                  if (option?.childModifiers?.length > 0) {
                    setShowChild(true);
                  } else {
                    setShowChild(false);
                  }
                }

                checkBoxchange(
                  e,
                  state,
                  setState,
                  option,
                  modifier,
                  value,
                  setValue,
                  setMax,
                  max
                );

                handleChooseModifires(
                  e,
                  option,
                  modifier?.maxPermittedUnique,
                  index,
                  modifier
                );
              }}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
          label={
            option?.price > 0
              ? option?.title + '         ($' + option?.price + ')'
              : option?.title
          }
          sx={{
            color: 'white',
            '& .MuiFormControlLabel-label': {
              color: 'white', 
            },
          }}
        />
      </Grid>
      {/* quantity of options */}
      {state[option?.title] &&
        modifier?.maxPermitted > modifier?.maxPermittedUnique && (
          <Grid
            item
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'end',
              // padding: '1rem 2.5rem 1rem 1rem',
            }}
          >
            <Typography
              component="p"
              variant="h5"
              style={{ fontWeight: 'bold'  ,color:"white"}}
            >
              <div
                style={{
                  display: 'flex',
                  border: '2px solid white',
                  borderRadius: '20px',
                  width: '100%',
                }}
              >
                <div
                  style={{ padding: '5px 10px 5px' }}
                  onClick={() => {
                    if (value > option?.defaultQuantity) {
                      sum[option?.id] = value - 1;
                      checkSumM(sum, option.id, modifier);
                      setValue((v) => v - 1);
                      setOptionsPrice((old) =>
                        old - option?.price > 0 ? old - option?.price : 0
                      );

                      if (
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0]
                      ) {
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id == option?.id
                        )[0].quantity -= 1;
                        setExtraQuantity(extraQuantity);
                      }
                    }
                  }}
                >
                  <RemoveCircleRoundedIcon
                    style={{
                      color: value > option?.defaultQuantity ? 'white' : 'gray',
                    }}
                  />
                </div>

                <Typography
                  component="a"
                  variant="h6"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    display: 'flex',
                    fontWeight: 'bold',
                    marginTop: '3px',
                  }}
                >
                  {value}
                </Typography>
                <div
                  style={{ padding: '5px 10px 5px' }}
                  onClick={() => {
                    if (
                      value < option?.maxPermitted &&
                      modifier?.maxPermitted > value
                    ) {
                      sum[option?.id] = value + 1;
                      checkSumAdd(sum, option.id, modifier);
                      setValue((v) => v + 1);

                      setOptionsPrice((old) => old + option?.price);

                      if (
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0]
                      ) {
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0].quantity += 1;
                        setExtraQuantity(extraQuantity);
                      }
                    }
                  }}
                >
                  <AddCircleOutlinedIcon
                    style={{
                      color:
                        value < option?.maxPermitted &&
                        modifier?.maxPermitted > value
                          ? 'white'
                          : 'gray',
                    }}
                  />
                </div>
              </div>
            </Typography>
          </Grid>
        )}
      {/* <Divider style={{ border: '3px solid #C4C4C4' }} /> */}
      <Grid item lg={12} xl={12} xs={12}>
        {showChild && (
          <Dialog
            maxWidth="xl"
            PaperProps={{ style: { maxHeight: '500px', maxWidth: '600px' } }}
            onClose={() => setShowChild(false)}
            aria-labelledby="customized-dialog-title"
            open={showChild}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            {' '}
            {modifiersGroup?.map((h) =>
              option?.childModifiers.includes(h?.id) ? (
                <div style={{ width: '30rem', height: 'auto' }}>
                  <DialogTitle>
                    {' '}
                    <p style={{ color: 'gray', fontSize: '17px' }}>
                      {modifier?.title}
                    </p>
                    {/* <p style={{ fontSize: '18px' }}>{h?.title}</p> */}
                  </DialogTitle>
                  <DialogContent
                    dividers
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <ChildItemModifier
                      d={h}
                      id={h?.id}
                      modifier={h}
                      handleChange={handleChange}
                      setOptionsPrice={setOptionsPrice}
                      zeros={zeros}
                      setZeros={setZeros}
                      modifiersGroup={modifiersGroup}
                      showResults={showResults}
                      setExtraQuantity={setExtraQuantity}
                      extraQuantity={extraQuantity}
                      optionsPrice={optionsPrice}
                      handleChooseModifires={handleChooseModifires}
                      checkedModifiers={checkedModifiers}
                      setCheckedModifiers={setCheckedModifiers}
                      checkedOptions={checkedOptions}
                      errReq={errReq}
                      checkSumAdd={checkSumAdd}
                      checkSumM={checkSumM}
                      setMaxErr={setMaxErr}
                      summErr={summErr}
                      modid={modid}
                      optionid={optionid}
                      setCalculatedPrice={setCalculatedPrice}
                      setShowChildP={setShowChildP}
                      handleChangeChild={handleChangeChild}
                      optionsPriceChild={optionsPriceChild}
                      setOptionsPriceChild={setOptionsPriceChild}
                      checkBoxchangeChild={checkBoxchangeChild}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        onClick={(e) => {
                          setShowChild(false);
                          setState({});
                        }}
                        style={{
                          color: 'white',
                          background: environment?.mainColor,
                          borderRadius: '20px',
                          padding: '5px 30px 4px 30px',
                          width: '100%',
                        }}
                      >
                        Save{' '}
                      </Button>
                    </Grid>
                  </DialogActions>
                </div>
              ) : (
                ''
              )
            )}
          </Dialog>
        )}
      </Grid>
    </>
  );
}

export default InnerItem;
