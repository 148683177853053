import React from 'react';
import { createStyles, Container, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

const useStyles = styled((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    Button: {
      backgroundColor: '#3f51b5',
      color: 'white',
    },
    paper: {
      padding: '10px',
    },
  })
);
export function CustomButton(props) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.paper}
      elevation={3}
      style={{ backgroundColor: props.bg, borderRadius: '30px' ,  marginTop :"15px"}}
      onClick={props.onClick}
    >
      <Container maxWidth={'lg'}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:"10px" ,
          }}
        >
          <Grid
            item
            lg={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                color: 'white',
                borderRadius: '30px',
                textAlign: 'center',
                fontWeight: 'bold',
               
              }}
            >
              {props.buttonText}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}
export default CustomButton;
