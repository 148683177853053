import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { MenuList, Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonalInfo from '../../components/Profie/personalInfo';
import banner from '../../../assets/banner.png';
import logo from '../../../assets/white-logo.png';
import Frame from '../../../assets/Profile.png';
import OrderHistory from '../../components/OrderHistory/OrderHistory';
import Address from '../../components/Address/Address';
import Payment from '../../components/PaymentMethod/Payment';
import footer from '../../../assets/background.png';
import { environment } from '../../../environments/environment';
import { useSelector } from 'react-redux';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { CiCreditCard1, CiHeart, CiShoppingBasket, CiUser } from "react-icons/ci";

import FavoriteItems from '../../components/Profie/FavoriteItems';
import { useTranslation } from 'react-i18next';
import {
  FaUser,
  FaShoppingBasket,
  FaHeart,
  FaMapMarkerAlt,
  FaCreditCard,
  FaSignOutAlt,
} from "react-icons/fa";
import { FiChevronRight, FiLogOut, FiMapPin } from "react-icons/fi";
import { loggedOutSuccess } from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Profile = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const token = useSelector((state) => state.authReducer.token);
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [active, setActive] = useState(false);
  const [lastOrder, setLastOrder] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [orderStatus, setOrderStatus] = useState('delivered');
  const sentinel = useRef(null);
  const env = useSelector((state) => state.authReducer.env);
  const [cancelOrderget, setCancelOrderget] = useState(false);
  const location = useLocation();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const user = useSelector((state) => state.authReducer.userData);

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'address':
        return licenses?.frontend?.profile_details?.address;
      case 'account_info':
        return licenses?.frontend?.profile_details?.account_info;
      case 'profile':
        return licenses?.frontend?.profile_details?.account_info;
      case 'payment':
        return licenses?.frontend?.profile_details?.payment;
      case 'orderhistory':
        return licenses?.frontend?.profile_details?.orderhistory;
      case 'favorites':
        return licenses.frontend?.profile_details?.favorites;

      default:
        return false;
    }
  };

  const logout = async () => {
    // await firebaseUtility.SignOut();
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      // dispatch(setCart([]));
      localStorage.removeItem('isAuth');
      localStorage.removeItem('userID');
      window.location.href ="https://taqwasbakery.com/";
      localStorage.clear();
    } catch (e) {
      console.log(e.message);
    }
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const digits = number.replace(/\D/g, '');
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };


  const handleClick = () => {
    setActive(!active);
  };
  const docid = localStorage.getItem('docid');
  const getOrders = async () => {
    await firestore
      .collection('orders')
      .where('uid', '==', docid)
      .where('order_status', '==', orderStatus) 
      .orderBy('created_at', 'desc')
      .limit(10)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          let array = [];
          let lastdoc = null;
          snapshot.forEach((doc) => {
            array.push(doc.data());
            lastdoc = doc;
          });
          setLastOrder(lastdoc);
          setData(array);
          setHasMore(true);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    (async () => {
      getOrders();
    })();
  }, [cancelOrderget, orderStatus]);

  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) observer.observe(sentinel.current);

    return function cleanup() {
      observer.disconnect();
    };
  });

  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (hasMore) {
          let lastdoc = null;
          firestore
            .collection('orders')
            .where('uid', '==', auth?.currentUser?.uid)
            .orderBy('created_at', 'desc')
            .limit(5)
            .startAfter(lastOrder)
            .get()
            .then((snapshot) => {
              if (snapshot.size > 0) {
                let array = [...data];
                snapshot.forEach((doc) => {
                  array.push(doc.data());
                  lastdoc = doc;
                });
                setLastOrder(lastdoc);
                setData(array);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            });
        }
      }
    });
  };
  const [t, i18n] = useTranslation();
  return (
    <>
      <div
        style={{
          backgroundColor: '#050505FC',
          position: 'relative',
          width: '100%',
          height: '450px',
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
        }}
      >
       <a href="https://taqwasbakery.com/" >
  <img
    src={logo}
    alt="logo"
    style={{
      paddingTop: smDown?'50px':'10px',
      paddingLeft: '20px',
      display: 'flex',
      cursor: 'pointer',
      width: '6rem',
    }}
  />
</a>
        <img
          src={Frame}
          alt="title"
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>

      <Box
        sx={{
          zIndex: 0,
          display: { xs: 'grid', md: 'flex', lg: 'flex' },
          backgroundColor: '#050505FC',
        }}
      >
        
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            // maxWidth: !smDown && '30%',
            flexBasis: '30%',
            background:
              environment?.restaurantId === 'elmbrookschools' ||
              environment?.restaurantId === 'filfil' ||
              environment?.restaurantId === 'alyousef'
                ? '#151414'
                : '',
            height: '100vh',
            padding: '20px 10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: !smDown &&  '30px',
          }}
        >
          {/* Profile Section */}
          <div
            style={{
              background: '#1e1e1e',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '12px',
              textAlign: 'center',
              marginBottom: '20px',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              style={{ color: 'white', marginBottom: '5px' }}
            >
            {user?.fullName}
            </Typography>
            <Typography variant="body2" style={{ color: '#888888' }}>
            {formatPhoneNumber(user?.phoneNumber)}
            </Typography>
          </div>

          {/* Menu List */}
          <MenuList
            style={{
              width: '100%',
              background: '#151414',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '10px 0',
            }}
          >
            {[
              {
                label: t('account'),
                icon: <CiUser />,
                type: 'personalInfo',
              },
              {
                label: t('ordersHistory'),
                icon: <CiShoppingBasket />,
                type: 'order',
              },
              {
                label: t('favoriteItems'),
                icon: <CiHeart />,
                type: 'favoriteItems',
              },
              {
                label: t('savedAddresses'),
                icon: <FiMapPin/>,
                type: 'address',
              },
              {
                label: t('paymentMethods'),
                icon: <CiCreditCard1 />,
                type: 'payment',
              },
            ].map((item, index, arr) => (
              <div key={item.type}>
                <MenuItem
                  onClick={() =>
                    navigate(
                      `/profile/${auth?.currentUser?.uid}?type=${item.type}`
                    )
                  }
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '15px 20px',
                    my:1,
                    fontFamily: 'Outfit, sans-serif',
                    color:
                      query.get('type') === item.type
                        ?  '#FFFFFF'
                        : environment.primaryColor,
                    fontSize: '13px',
                   
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' ,textTransform: 'none' }}>
                    <div
                      style={{
                        marginRight: '10px',
                        color:
                          query.get('type') === item.type
                            ? '#FFFFFF'
                            :environment.primaryColor ,
                        fontSize: '18px',
                      }}
                    >
                      {item.icon}
                    </div>
                    {item.label}
                  </div>
                  <FiChevronRight
                    style={{
                      color:
                        query.get('type') === item.type
                          ? '#FFFFFF'
                          : environment.primaryColor,
                    }}
                  />
                </MenuItem>
                {index !== arr.length - 1 && (
                  <div
                    style={{
                      height: '1px',
                      background: '#2C2C2C',
                      margin: '0 20px',
                    }}
                  />
                )}
              </div>
            ))}

            <MenuItem
             onClick={logout}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '15px 20px',
                color: '#FF2F28',
                fontSize: '16px',
                fontFamily: 'Outfit, sans-serif',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FiLogOut
                  style={{ marginRight: '10px', fontSize: '18px'  }}
                />
                {t('logOut')}
              </div>
            </MenuItem>
          </MenuList>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={8}
          style={{
            maxWidth: !smDown && '100%',
            flexBasis: '100%',
            minHeight:"700px",
            
          }}
        >
          <div>
            <Typography component="p" sx={{  fontFamily: 'Outfit'}}>
              {query.get('type') === 'order' ? (
                <Box
                  sx={{
                    backgroundColor: '#151414',
      borderRadius: '15px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      marginTop: { xs: '10px',sm:'150px', md: '20px' },
      marginLeft: { xs: '10px', sm: '40px' ,md:'50px' },
      marginRight: { xs: '10px', md: '50px' },
      marginBottom :{xs:"170px" ,md :"170px" ,lg:"170px",},
      maxWidth: { xs: '340px', md: '900px' },
      minHeight: '510px',
                    
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Outfit, sans-serif',
                      color: 'white',
                      fontSize: '20px',
                      marginBottom: '20px',
                      marginTop: '10px',
                    }}
                  >
                    {t('ordersHistory')}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', gap: '10px', marginBottom: '20px' }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setOrderStatus('confirmed')}
                      sx={{backgroundColor:`${orderStatus === 'confirmed' ? '#A01913' : '#303030'}`, borderRadius:"10px", fontWeight:"bold",fontFamily: 'Outfit', '&:hover': {
                        backgroundColor: `${orderStatus === 'cancelled' ? '#A01913' : '#303030'}`, 
                      },}}
                    >
                      {t('inProgress')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setOrderStatus('cancelled')}
                      sx={{backgroundColor:`${orderStatus === 'cancelled' ? '#A01913' : '#303030'}`,borderRadius:"10px", fontWeight:"bold",fontFamily: 'Outfit','&:hover': {
                        backgroundColor: `${orderStatus === 'cancelled' ? '#A01913' : '#303030'}`, 
                      },}}
                    >
                      {t('cancelled')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setOrderStatus('delivered')}
                      sx={{backgroundColor:`${orderStatus === 'delivered' ? '#A01913' : '#303030'}`,borderRadius:"10px" , fontWeight:"bold",fontFamily: 'Outfit', '&:hover': {
                        backgroundColor: `${orderStatus === 'cancelled' ? '#A01913' : '#303030'}`, 
                      },}}
                    >
                      {t('completed')}
                    </Button>
                  </Box>
                  {data.length > 0 ? (
                    data.map((order, index) => (
                      <>
                        {checkLicenses('orderhistory') && (
                          <>
                            <OrderHistory
                              order={order}
                              setCancelOrderget={setCancelOrderget}
                            />
                            <div ref={sentinel} />
                          </>
                        )}
                      </>
                    ))
                  ) : (
                    <Typography
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {t('noOrder')}
                    </Typography>
                  )}
                </Box>
              ) : query.get('type') === 'address' ? (
                <>{checkLicenses('address') && <Address />}</>
              ) : query.get('type') === 'personalInfo' ? (
                <> {checkLicenses('account_info') && <PersonalInfo />}</>
              ) : query.get('type') === 'payment' ? (
                <> {checkLicenses('payment') && <Payment />}</>
              ) : query.get('type') === 'favoriteItems' ? (
                <Grid item lg={4}>
                  {checkLicenses('favorites') && <FavoriteItems />}
                </Grid>
              ) : (
                <PersonalInfo />
              )}
            </Typography>
          </div>
        </Grid>
       
      </Box>
    </>
  );
};
export default Profile;
