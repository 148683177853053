import { Box, Hidden, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { firestore, RootFirestore } from '@tabletuck/firebase-services';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import { HiOutlineEnvelope } from "react-icons/hi2";
import axios from "axios";
import firebase from "firebase";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import footerSchool from '../../../assets/footerSchool.png';
import footerVector from '../../../assets/footerVector.png';
import footerAlyosef from '../../../assets/alyousef/footer1.jpg';
import bgfilfil from '../../../assets/bgfilfil.jpeg';
import { useSelector } from 'react-redux';
import { FaFacebookF, FaYelp } from 'react-icons/fa';
import logo from '../../../assets/white-logo.png';

function Footer() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const user = useSelector((state) => state.authReducer.userData);
  const id = user?.uid ;
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'ar':
        return licenses?.lng?.ar;
      case 'en':
        return licenses?.lng?.en;
      case 'es':
        return licenses?.lng?.es;
      case 'he':
        return licenses?.lng?.he;
      case 'zh':
        return licenses?.lng?.zh;

      default:
        return false;
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^(?![._,-])[a-zA-Z0-9._-]*[a-zA-Z]+[a-zA-Z0-9._-]*@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
  
    if (!emailRegex.test(email)) {
      return false; 
    }
  
    return true; 
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const receiverEmail = "info@taqwasBakery.com"; 
    const subject = "TaqwasBakery- Subscription Form";
    const emailTemplate = `
      <h1>New Contact Request</h1>
      <p><strong>Email:</strong> ${email}</p>
      <p>A new user has subscribed to the newsletter.</p>
    `;

    const apiData = {
      customerEmail: email,
      receiverEmail: receiverEmail,
      subject: subject,
      message: emailTemplate,
    };

    try {
      const response = await axios.post(
        "https://us-central1-zetaton-web.cloudfunctions.net/sendEmail",
        apiData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setSubmitted(true);
        setError("");
        setEmail(""); // Reset email input field
      } else {
        throw new Error("Failed to send email");
      }
    } catch (err) {
      console.error("Error sending email:", err);
      setError("An error occurred while sending the email. Please try again later.");
    }
  };

  

  return (
    <div
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
      style={{
        width: '100%',
        padding: '32px 0',
        background:
          environment.restaurantId === 'elmbrookschools' ||
          environment.restaurantId === 'alyousef' ||
          environment.restaurantId === 'filfil'
            ? ''
            : '#151414',
        backgroundImage:
          environment.restaurantId === 'elmbrookschools'
            ? `url(${footerSchool})`
            : environment.restaurantId === 'alyousef'
            ? `url(${footerAlyosef})`
            : environment.restaurantId === 'filfil'
            ? `url(${bgfilfil})`
            : ``,
      }}
    >
         

         <Box
  sx={{
    height: "75px",
    backgroundImage: `url(${footerVector})`,
    position: "relative",
    top: "-60px",
  }}
>
  {environment?.restaurantId === "istanbulDk" ||
    (environment.restaurantId === "taqwasbakery" && (
      <div
        style={{
          position: "absolute", // Changed to absolute for precise placement
          top: "-80px", // Adjust the SVG above the main container
          right: "20px", // Keep it near the form on larger screens
          width: "160px", // Fixed width for consistent size
          height: "160px",
          transform: "translateX(-50%)", // Center align on small screens
        }}
      >
        <link
          rel="stylesheet"
          href="https://cdn6.localdatacdn.com/badges/restaurantji/css/badge-1.css"
        ></link>
        <div id="circle_v2" className="v5 v5_1" tabIndex="0">
          <div className="rb_flex rb_top">
            <div className="arc-heading">
              <svg
                height="160px"
                viewBox="0 0 160 160"
                width="160px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <path
                    d="M 30 80 a 50 50 0 1 1 110 0"
                    id="heading-arc"
                  ></path>
                </defs>
                <text
                  className="arc-heading__heading"
                  fill="#000"
                  textAnchor="middle"
                >
                  <textPath
                    startOffset="50%"
                    xlinkHref="#heading-arc"
                  >
                    <a
                      href="https://www.restaurantji.com/wi/greenfield/taqwas-bakery-and-restaurant-/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Recommended
                    </a>
                  </textPath>
                </text>
              </svg>
            </div>
            <div className="arc-heading arc-heading__bottom">
              <svg
                height="140px"
                viewBox="0 0 140 140"
                width="140px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <path
                    d="M 12 60 a 55 55 0 0 0 140 0"
                    id="subheading-arc"
                    stroke="red"
                  ></path>
                </defs>
                <text
                  className="arc-heading__subheading"
                  fill="#000"
                  textAnchor="middle"
                >
                  <textPath
                    startOffset="50%"
                    xlinkHref="#subheading-arc"
                  >
                    <a
                      target="_blank"
                      href="https://www.restaurantji.com"
                      rel="noopener noreferrer"
                    >
                      Restaurantji
                    </a>
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    ))}
</Box>

    <div style={{ textAlign: 'center', padding: '20px',paddingTop:"0px", color: 'white' ,fontFamily:"OutFit" }}>
      <h3 style={{ fontWeight: 'bold', marginBottom: '15px' ,fontSize:"24px" }}>
        {t('newsletter.title')}
      </h3>
      <p style={{ fontSize: '14px', maxWidth: '600px', margin: '0 auto' }}>
        {t('newsletter.description')}
      </p>
    </div>
    <div
      style={{
        textAlign: "center",
        width: width <= 768 ? "80%" : "50%", 
        margin: "auto",
        padding: "10px",
      }}
    >
      {submitted ? (
        <p
          style={{
            color: "#A01913",
            fontSize: "20px",
            fontFamily: "Outfit",
            fontWeight: "bold",
          }}
        >
          Thank you for subscribing!
        </p>
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #ccc",
            borderRadius: "30px",
            padding: "5px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderRadius: "30px",
              backgroundColor: "white",
              flex: 1,
            }}
          >
            <span
              style={{
                marginRight: "10px",
                color: "#151414",
                fontSize: "20px",
              }}
            >
              <HiOutlineEnvelope size={20} />
            </span>
            <input
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                border: "none",
                outline: "none",
                width: "100%",
                fontSize: "16px",
                padding: "5px",
                color: "#151414", 
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: "#a00000",
              color: "white",
              border: "none",
              borderRadius: "30px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              marginLeft: "10px",

            }}
          >
            Submit
          </button>
        </form>
      )}
      {error && (
        <p style={{ color: "red", marginTop: "10px", fontSize: "14px" }}>
          {error}
        </p>
      )}
    </div>
    <div
          style={{
           marginTop:"50px" ,
           marginBottom:"20px",
            background: 'rgb(70,70,80)',
            height: '1px',
          }}
        />
       
      <Container maxWidth={'lg'}>
        <Grid
          container
          spacing={4}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item lg={4} md={6} sm={6} xs={12} >
            <Box sx={{padding:"20px" ,fontFamily:"Outfit"}}>
              <a href="https://taqwasbakery.com/" >
              <img
          src={logo}
          alt="logo"
          style={{
            paddingTop: '10px',
            marginBottom:"20px",

            display: 'flex',
            cursor: 'pointer',
            width: '6rem',
          }}
        />
              </a>
           
            <Typography
              variant="body1"
              style={{
                color: '#FFFFFF',
                fontFamily:"Outfit",
                fontSize:"14px",
              }}
            >
              {t('footerDetails')}
            </Typography>

            <Grid
  container
  direction="row"
  justifyContent="flex-start"
  alignItems="center"
  marginTop="20px"
  spacing={2} // Add spacing between Grid items
>
  {environment.facebookLink && (
    <Grid item>
      <a href={environment.facebookLink} target="_blank" style={{ textDecoration: 'none' }}>
        <div
          style={{
            backgroundColor: '#A01913', // Red background
            borderRadius: '30px',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaFacebookF style={{ color: 'white', fontSize: '20px' }} />
        </div>
      </a>
    </Grid>
  )}
  {environment.instagramLink && (
    <Grid item>
      <a href={environment.instagramLink} target="_blank" style={{ textDecoration: 'none' }}>
        <div
          style={{
            backgroundColor: '#A01913', 
            borderRadius: '30px',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InstagramIcon style={{ color: 'white', fontSize: '20px' }} />
        </div>
      </a>
    </Grid>
  )}
  {environment.twitterLink && (
    <Grid item>
      <a href={environment.twitterLink} target="_blank" style={{ textDecoration: 'none' }}>
        <div
          style={{
            backgroundColor: '#A01913', // Red background
            borderRadius: '30px',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TwitterIcon style={{ color: 'white', fontSize: '20px' }} />
        </div>
      </a>
    </Grid>
  )}
  {(environment.yelpLink &&
    (environment.restaurantId === 'istanbulDk' || environment.restaurantId === 'taqwasbakery')) && (
    <Grid item>
      <a href={environment.yelpLink} target="_blank" style={{ textDecoration: 'none' }}>
        <div
          style={{
            backgroundColor: '#A01913', 
            borderRadius: '30px',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaYelp style={{ color: 'white', fontSize: '20px' }} />
        </div>
      </a>
    </Grid>
  )}
</Grid>
</Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
  <div style={{ color: 'white' , padding: '20px', fontFamily:"Outfit"}}>
    <h3 style={{ fontWeight: '700', marginBottom: '15px' ,fontSize:"18px"}}>Quick Actions</h3>
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0, lineHeight: '2' }}>
      <li>
        <a
          href="https://taqwasbakery.com/"
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '14px',
          }}
        >
          Home
        </a>
      </li>
      <li>
        <a
          href="/"
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '14px',
          }}
        >
          Menu
        </a>
      </li>
      <li>
        <a
          href="https://taqwasbakery.com/blogs"
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '14px',
          }}
        >
          Blog
        </a>
      </li>
      {/* <li>
  <a
    href={`/profile/${id}?type=payment`}
    style={{
      color: 'white',
      textDecoration: 'none',
      fontSize: '14px',
    }}
  >
    Payments
  </a>
</li> */}
    </ul>
  </div>
</Grid>


<Grid
            item
            lg={4} md={6} sm={6} xs={12}
            style={{ marginTop: '20px', marginBottom: '10px'}}
          >
            <Box>
            <Typography
              variant="h6"
              style={{
                color: 'white',
                marginBottom:"20px",
              }}
            >
              {t('contact')}
            </Typography>
            <Typography
              variant="caption"
              style={{
                color: 'white',
                fontFamily:"Outfit",
                height: '85%',
                marginTop: '3px',
                cursor: 'pointer',
              }}
            >
              {' '}
              <LocationOnIcon />{' '}
              <a
                href={
                  environment?.restaurantId === 'istanbulDk' || environment.restaurantId === 'taqwasbakery' 
                    ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                    : environment?.restaurantId === 'alyousef'
                    ? 'https://www.google.com/maps/place/Al-Yousef+Supermarket+%26+Restaurant/@42.9295555,-87.9399237,17z/data=!3m1!4b1!4m6!3m5!1s0x88051151327e486b:0x7c529f0a50e1089d!8m2!3d42.9295555!4d-87.9399237!16s%2Fg%2F11bw50pjkw?entry=ttu'
                    : 'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
                }
                target="_blank"
              >
                {environment.address}
              </a>
            </Typography>{' '}
            {environment?.restaurantId === 'filfil' && (
              <>
                <br />
                <Typography
                  variant="caption"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    height: '85%',
                    marginTop: '3px',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  <LocationOnIcon />{' '}
                  <a
                    href={
                      'https://www.google.com/maps/place/1125+N+9th+St,+Milwaukee,+WI+53233,+USA/@43.0449532,-87.926173,17z/data=!3m1!4b1!4m6!3m5!1s0x8805197a07844bf1:0x6753493f28655d70!8m2!3d43.0449532!4d-87.9235981!16s%2Fg%2F11c1xpx0z9?entry=ttu'
                    }
                    target="_blank"
                  >
                    1125 N 9th St, Milwaukee, WI 53233
                  </a>
                </Typography>{' '}
              </>
            )}
            <br />
            <Typography
              variant="caption"
              style={{
                color: 'white',
                fontFamily:"Outfit",
                height: '85%',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            >
              {' '}
              <MailIcon />
              <a
                href={`mailto:${environment.email}`}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                {environment.email}
              </a>
            </Typography>
            <br />
            {/* <Typography
              variant="caption"
              style={{
                color: 'white',
                opacity: '0.8',
                height: '85%',
                marginTop: '30px',
                marginBottom: '20px',
                display: 'initial',
              }}
            >
              {' '}
              <LocalPhoneIcon />{' '}
              <a
                href={`tel:${environment.phoneNumber}`}
                style={{
                  cursor: 'pointer',
                }}

              >
                {environment.phoneNumber}
              </a>
            </Typography> */}
            </Box>
          </Grid>


         
          {/* <Hidden mdUp>
            <Grid
              item
              lg={5}
              xl={3}
              md={12}
              sm={12}
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              <Typography
                variant="h6"
                style={{
                  color: 'white',
                }}
              >
                {t('getInTouch')}
              </Typography>
              <Typography
                variant="caption"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  height: '85%',
                  marginTop: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/contactus');
                }}
              >
                {' '}
                {t('contact')}
              </Typography>{' '}
              <br />
              <Typography
                variant="caption"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  height: '85%',
                  marginTop: '30px',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/careers');
                }}
              >
                {' '}
                {t('career')}
              </Typography>
            </Grid>
          </Hidden> */}
         

          {/* <Typography
            variant="caption"
            style={{
              color: 'white',
              opacity: '0.8',
              height: '85%',
              marginTop: '3px',
              cursor: 'pointer',
            }}
          >
            {' '}
            {t("language")}
          </Typography> */}
          <br />

          <Grid
            item
            lg={5}
            xl={3}
            md={12}
            sm={12}
            style={{ marginTop: '20px', marginBottom: '10px' }}
            display="flex"
            gap={5}
          >
            {checkLicenses('ar') && (
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  i18n.changeLanguage('ar');
                }}
              >
                {/* AR */}
                العربية
              </Typography>
            )}
            {checkLicenses('es') && (
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  i18n.changeLanguage('en');
                }}
              >
                {/* EN */}
                English (US)
              </Typography>
            )}
            {checkLicenses('zh') && (
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  i18n.changeLanguage('zh');
                }}
              >
                {/* CH */}
                中文
              </Typography>
            )}
            {checkLicenses('es') && (
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  i18n.changeLanguage('es');
                }}
              >
                {/* ES */}
                Español
              </Typography>
            )}
            {checkLicenses('he') && (
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  i18n.changeLanguage('he');
                }}
              >
                {/* HE */}
                עִברִית
              </Typography>
            )}
          </Grid>
        </Grid>
        
        <div
          style={{
            margin: '24px 0',
            background: 'rgb(70,70,80)',
            height: '1px',
          }}
        />
        <Grid
          container
          spacing={4}
          justify={'space-between'}
          alignItems={'center'}
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <Typography
                variant={'body2'}
                style={{ color: 'white', opacity: '0.5' }}
              >
                Copyright ©{environment?.headerTitle} All Rights Reserved.
                {/* Copyright © {environment.restaurantId} All Rights Reserved. */}
                {/* {t('copyRight')} */}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <Typography
                variant={'body2'}
                style={{ color: 'white', opacity: '0.5', cursor: 'pointer' }}
              >
<a 
  href="https://taqwasbakery.com/privacy-policy" 
  
>
  {t('privacyPolicy')}
</a>
                |{' '}
                <a href="https://taqwasbakery.com/terms-conditions" >
                  {t('termsAndConditions')}
                </a>{' '}
                {environment?.restaurantId != 'alyousef' &&
                environment?.restaurantId != 'filfil' ? (
                  <>
                    | {'  '}{' '}
                    <a href="https://zetaton.com/" target="_blank">
                      {t('powered')}
                    </a>
                  </>
                ) : (
                  ''
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default Footer;
