import React from 'react';
import { Link } from 'react-router-dom';
import Illustration from '../../../assets/Illustration.png'; // Import your illustration image

function PageNotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white text-center" style={{zIndex:5000}}>
      <img src={Illustration} alt="404 Illustration" className="w-48 mb-8" /> {/* Image size can be adjusted */}
      <p className="text-lg mb-4" style={{fontFamily:"Outfit"}}>Oops! Page not found</p>
      <p className="text-sm mb-6" style={{fontFamily:"Outfit"}}>
        The page you're looking for might have been removed or is temporarily unavailable.
      </p>
      <a
        href="https://taqwasbakery.com/"
        className="text-white py-2 px-6 rounded-lg text-md hover:bg-red-700 rounded-lg"
        style={{ fontFamily: 'Outfit', borderRadius: '30px', backgroundColor: '#A01913' }}
      >
        Back to Home
      </a>
    </div>
  );
}

export default PageNotFound;
