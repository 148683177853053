import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Card, CardContent, Grid, Hidden, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, ShoppingCart, Close } from "@mui/icons-material";
import { CustomButton } from '@tabletuck/components';
import FulPageDialoge from '../../../../../apps/table-tuck-web/src/app/components/Dialouge/FullpageDialoge';
import { RiMenu2Line, RiMenu3Fill } from "react-icons/ri";
import  FiShoppingBag, { FiClock } from "react-icons/fi";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Drawer from '@mui/material/Drawer';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartDrawer from '../../../../../apps/table-tuck-web/src/app/components/CartDrawer/CartDrawer';
import { auth, firestore } from '@tabletuck/firebase-services';
import { useDispatch, useSelector } from 'react-redux';
import LoginHeader from './loginHeader';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import {
  loggedOutSuccess,
  setCart,
  setUser,
  loggedInSuccess,
  activeMenu,
  allactiveMenues,
  menues,
  setStores,
  checkStore,
} from '@tabletuck/redux-slices';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useParams } from 'react-router';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router';
import logo from '../../../../../apps/table-tuck-web/src/assets/white-logo.png';
import blacklogo from '../../../../../apps/table-tuck-web/src/assets/blacklogo.png';
import TTdemoLogo from '../../../../../apps/table-tuck-web/src/assets/TTdemoLogo.png';
import schoolLogo from '../../../../../apps/table-tuck-web/src/assets/logoschool.png';
import alyousefLogo from '../../../../../apps/table-tuck-web/src/assets/alyousef/alyousof-logo.png';


import MailIcon from '@mui/icons-material/Mail';
import { styled, alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dialog } from '@mui/material';
import { RootFirestore } from '@tabletuck/firebase-services';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Formik } from 'formik';
import { setDefaultStore } from 'apps/table-tuck-web/src/app/components/CartDrawer/utils';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { FaClock, FaRegClock } from 'react-icons/fa';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export function Header(props) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const cart = useSelector((state) => state.shopReducer.cart);
  const token = useSelector((state) => state.authReducer.token);
  const userData = useSelector((state) => state.authReducer.userData);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = auth?.currentUser;
  const res = useSelector((state) => state.authReducer.res);

  // const [userData, setUserData] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [env, setEnv] = useState([]);
  const stores = useSelector((state) => state.shopReducer.stores);
  const [store, setStore] = React.useState('');
  const [t, i18n] = useTranslation();
  const menuDocs = useSelector((state) => state.authReducer.menuDocs);
  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  const storedItems = JSON.parse(localStorage.getItem('cartItems'));
  const [showHours, setShowHours] = useState(false);
  const [todayHours, setTodayHours] = useState("Closed");

  
  const openMenu = Boolean(anchorEl);

  const toggleHours = () => setShowHours(!showHours);
  const getStrores = async () => {
    try {
      let storeRef = firestore.collection('stores');
      let list = [];
      const StoresList = await storeRef.get();
      for (let index = 0; index < StoresList?.docs?.length; index++) {
        let element = StoresList?.docs[index]?.data();
        element.id = StoresList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setStores(list));
      setStore(list);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (stores?.length > 0) {
      setTodayHours(getTodayHours(stores[0].timing));
    }
  }, [stores]);
  useEffect(() => {
    getStrores();
  }, []);
  const groupConsecutiveDaysWithSameHours = (timing) => {
    const daysOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const sortedTiming = [...timing].sort(
      (a, b) => daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
    );
  
    const grouped = [];
    let currentGroup = null;
  
    sortedTiming.forEach(({ day, slots }, index) => {
      const hours =
        slots.length > 0
          ? `${slots
              .map(
                (slot) =>
                  `${new Date(
                    slot.open_time.seconds * 1000 + slot.open_time.nanoseconds / 1e6
                  ).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  })} - ${new Date(
                    slot.close_time.seconds * 1000 + slot.close_time.nanoseconds / 1e6
                  ).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  })}`
              )
              .join(", ")}`
          : "closed";
  
      if (!currentGroup || currentGroup.hours !== hours) {
        if (currentGroup) grouped.push(currentGroup);
        currentGroup = { days: [day], hours };
      } else {
        currentGroup.days.push(day);
      }
  
      if (index === sortedTiming.length - 1) {
        grouped.push(currentGroup);
      }
    });
  
    return grouped.map((group) => ({
      days:
        group.days.length > 1
          ? `${group.days[0]} - ${group.days[group.days.length - 1]}`
          : group.days[0],
      hours: group.hours,
    }));
  };
  
  // Get current day
  const getCurrentDay = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[new Date().getDay()];
  };
  
  // Function to get today's hours
  const getTodayHours = (timing) => {
    const currentDay = getCurrentDay();
    const groupedHours = groupConsecutiveDaysWithSameHours(timing);
  
    const todayHours = groupedHours.find((group) => group.days.includes(currentDay));
  
    return todayHours ? `${todayHours.hours}` : "Closed";
  };
  
  const groupedHours =
  stores?.length > 0 ? groupConsecutiveDaysWithSameHours(stores[0].timing) : [];

  
  useEffect(() => {
    stores?.filter((store) =>
      store.id === userData?.defaultStore
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, [defaultStoreAdress]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await firestore.collection('users').doc(user?.uid).get();
        if (res.exists) {
          const data = res.data();
          if (data?.email && data.fullName) {
            dispatch(loggedInSuccess({ token: user.uid }));
            dispatch(setUser({ user: user, userData: data }));
          }
        }
      }
    });
  }, [auth, window.location.pathname]);
  const getENV = async () => {
    const doc = await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .get();
    setEnv(doc.data());
  };

  useEffect(() => {
    getENV();
  }, []);

  const handleSubmit = async (values) => {
    await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .set(
        {
          paymentFlow: values?.paymentFlow,
          orderFlow: values?.orderFlow,
        },
        { merge: true }
      )
      .catch((err) => {
        console.warn('success', err);
      });
    setOpenDialog(false);
  };

  const getCount = () => {
    let count = 0;
    for (let index = 0; index < cart?.length; index++) {
      const element = cart[index];
      count = count + element?.quantity;
    }
    setCounter(count);
  };
  useEffect(() => {
    getCount();
  }, [cart, auth?.currentUser?.uid, token]);
  const check = window.localStorage.getItem('items');

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'categories':
        return licenses.dashboard.categories;
      case 'signup':
        return check != 'true' ? licenses.frontend.signup : false;
      case 'profile':
        return check != 'true' ? licenses.frontend.profile : false;
      case 'checkout':
        return check != 'true' ? licenses.frontend.checkout : false;
      case 'items':
        return licenses.dashboard.items;
      case 'drivers':
        return licenses.dashboard.drivers;
      case 'modifierGroup':
        return licenses.dashboard.modifierGroup;
      case 'admins':
        return licenses.dashboard.admins;
      case 'stores':
        return licenses.dashboard.stores;
      case 'offers':
        return licenses.dashboard.offers;
      case 'orderHistory':
        return licenses.dashboard.orderHistory;

      default:
        return false;
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      dispatch(setCart([]));
      window.location.href ="https://taqwasbakery.com/";
      localStorage.clear();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openCartDialoge, setOpenCartDialoge] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleClickOpen = () => {
    stores?.length > 1 ? setOpen(true) : navigate('/');
  };
  const handleClickOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleClickOpenCartDialoge = () => {
    setOpenCartDialoge(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleCloseCartDialoge = () => {
    setOpenCartDialoge(false);
  };
  // if (props.isAdmin && !auth?.currentUser?.uid)
  //   return (
  //     <LoginHeader color={props.env.mainColor} title={props.env.headerTitle} />
  //   );
  const numberToWord = (number) => {
    const words = [
      'Zero',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
    ];
    return words[number];
  };

  const menuDefaultStore = () => {
    dispatch(activeMenu(res[userData?.defaultStore]?.active_menu_now));
    dispatch(allactiveMenues(res[userData?.defaultStore]?.all_active_menus));
    dispatch(menues(res[userData?.defaultStore]?.menus));
    navigate('/');
  };
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  

  return (
    licenses && (
      <>
      <Hidden smUp>
     
      <AppBar
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      position="absolute"
      style={{
        
        
         
        backgroundColor: "transparent",
        
        boxShadow: props.location === '/'
          ? 'none'
          : 'none', 
       
        position: 'absolute', 
        zIndex: 1100, 
        display: 'flex',
        justifyContent: 'center',
        
      }}
      >
  <div className="bg-black bg-opacity-60 text-white  shadow-md  py-3   w-full" >
    <div className=" flex justify-center mx-auto">
      <FaRegClock className="w-5 h-6 mr-2 text-white" />
      <span className="text-lg ml-1">Today {todayHours}</span>
      </div>
    </div>     
<nav className="flex justify-end items-center p-4 bg-transparent">
      {/* User Icon */}

      
      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white bg-opacity-30  mr-4">
      {!props.isAdmin &&
                  auth?.currentUser?.uid &&
                  checkLicenses('profile') && (
            <IconButton>
              <PersonOutlineSharpIcon fontSize="large" style={{ color: "white" }} onClick={() => navigate('/profile')}/>
            </IconButton>
                  )}
       {!props.isAdmin &&
              !auth?.currentUser?.uid &&
              checkLicenses('signup') ? (
                <IconButton>
                <PersonOutlineSharpIcon fontSize="large" style={{ color: "white" }}  onClick={() => navigate('/signup')} />
              </IconButton>
              ):('')}
      </div>

      {/* Cart Icon with Notification Badge */}
    
{!props.isAdmin &&
            checkLicenses('checkout') &&
            auth?.currentUser?.uid ? (
              <>
                <CartDrawer
                  openCartDialoge={openCartDialoge}
                  handleCloseCartDialoge={handleCloseCartDialoge}
                  addresses={addresses}
                  userData={userData}
                  defaultStoreAdress={defaultStoreAdress}
                  setDefaultStoreAdress={setDefaultStoreAdress}
                />
               {counter > 0 ? (
  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white bg-opacity-30 mr-4">
    <IconButton onClick={handleClickOpenCartDialoge}>
      <Badge badgeContent={counter} color="error">
        <LocalMallOutlinedIcon size={30} style={{ color: "white" }} />
      </Badge>
    </IconButton>
  </div>
) : null}

                </>):( !auth?.currentUser?.uid &&
              storedItems && (
                <>
                  <CartDrawer
                    openCartDialoge={openCartDialoge}
                    handleCloseCartDialoge={handleCloseCartDialoge}
                    addresses={addresses}
                    userData={userData}
                    defaultStoreAdress={defaultStoreAdress}
                    setDefaultStoreAdress={setDefaultStoreAdress}
                  />
                {counter > 0 ? (
  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white bg-opacity-30 mr-4">
    <IconButton onClick={handleClickOpenCartDialoge}>
      <Badge badgeContent={counter} color="error">
        <LocalMallOutlinedIcon size={30} style={{ color: "white" }} />
      </Badge>
    </IconButton>
  </div>
) : null}

                   </>
                  ) )}
      

      {/* Hamburger Menu - Opens Drawer */}
      <IconButton onClick={toggleDrawer(true)}>
            <RiMenu3Fill fontSize="larger" style={{ color: "white" }} />
      </IconButton>

      {/* Sidebar Drawer */}
      <Drawer
  anchor="right"
  open={openDrawer}
  onClose={toggleDrawer(false)}
  sx={{
    "& .MuiDrawer-paper": {
      backgroundColor: "black", // Drawer background
      color: "white", // Text color
      padding: "20px", // Padding for spacing
      width: "250px", // Adjust the width as per your design
    },
  }}
>
  <div className="w-full">
    {/* Close Button */}
    <div className="flex justify-start mb-4">
      <IconButton sx={{ color: "white" }} onClick={toggleDrawer(false)}>
        <RiMenu2Line />
      </IconButton>
    </div>

    {/* Menu Items */}
    <List>
      <ListItem button onClick={() => (window.location.href = "https://taqwasbakery.com/")}>
        <ListItemText
          sx={{
            fontFamily: "Outfit",
            fontSize: "25px", // Adjusted font size to match the design
            fontWeight: "bold",
            color: "white",
          }}
          primary={<Typography sx={{ fontFamily: "Outfit", fontSize: "25px", fontWeight: "bold", color: "white" }}>Home</Typography>}

        />
      </ListItem>
      <ListItem button onClick={() => navigate('/')}>
        <ListItemText
          sx={{
            fontFamily: "Outfit",
            fontSize: "25px",
            fontWeight: "bold",
            color: "white",
          }}
          primary={<Typography sx={{ fontFamily: "Outfit", fontSize: "25px", fontWeight: "bold", color: "white" }}>Menu</Typography>}

        />
      </ListItem>
      <ListItem button onClick={() => (window.location.href = "https://taqwasbakery.com/blogs")}>
        <ListItemText
          sx={{
            fontFamily: "Outfit",
            fontSize: "25px",
            fontWeight: "bold",
            color: "white",
          }}
          primary={<Typography sx={{ fontFamily: "Outfit", fontSize: "25px", fontWeight: "bold", color: "white" }}>Blogs</Typography>}

        />
      </ListItem>
      <ListItem button onClick={() => (window.location.href = "https://taqwasbakery.com/contactus")}>
        <ListItemText
          sx={{
            fontFamily: "Outfit",
            fontSize: "25px",
            fontWeight: "bold",
            color: "white",
          }}
          primary={<Typography sx={{ fontFamily: "Outfit", fontSize: "25px", fontWeight: "bold", color: "white" }}>Contact us </Typography>}

        />
      </ListItem>

      {/* Additional Content */}
      
    </List>
    <div className="mt-4" style={{color:"gray" ,marginTop:"50px" }}>
    <Divider sx={{ backgroundColor: "gray", height: "1px", marginY: "15px" }} />

      <p
        style={{
          fontFamily: "Outfit",
          fontSize: "14px", // Smaller font for contact info
          color: "gray",
        }}
      >
        +1 (414) 539-6878
      </p>
      <p
        style={{
          fontFamily: "Outfit",
          fontSize: "14px",
          color: "gray",
        }}
      >
        info@taqwasBakery.com
      </p>
      <p
        style={{
          fontFamily: "Outfit",
          fontSize: "14px",
          color: "gray",
        }}
      >
        4651 S 27th St, Greenfield, WI 53221
      </p>
    </div>
  </div>
</Drawer>

    </nav>

      </AppBar>
      </Hidden>
      <Hidden smDown>
      <AppBar
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      position="absolute"
      style={{
        backgroundColor:
          props.env.restaurantId === 'istanbulDk' ||
          props.env.restaurantId === 'elmbrookschools' ||
          props.env.restaurantId === 'alyousef' ||
          props.env.restaurantId === 'filfil' ||
          props.env.restaurantId === 'taqwasbakery' ||
          props.env.restaurantId === 'ttDemo'
            ? 'White' 
            : 'White', 
        borderRadius: '24px', 
        margin: '0 auto',
        top: '16px', 
        left: 0,
        right: 0,
        width: 'fit-content',
        boxShadow: props.location === '/'
          ? 'none'
          : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
       
        position: 'absolute', 
        zIndex: 1100, 
        display: 'flex',
        justifyContent: 'center',
        
      }}
      >
        <Container maxWidth="xl" style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
          <Toolbar disableGutters>
            <div sx={{ display: 'flex', alignItems: 'center' }} />

            <Typography
              onClick={() => navigate('/')}
              noWrap
              component="a"
              style={{ display: 'flex', cursor: 'pointer' }}
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 700,
                color: 'black',
              }}
            >
              {props.env.restaurantId === 'istanbulDk'
            ? (
                <>
                  {props.location === '/menu-items' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {/* {props.location === '/confirmorder' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    ''
                  )} */}
                  {props.location === '/' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    <>
                      {props.location != '/menu-items' ? (
                        <img
                          src={blacklogo}
                          alt="logo"
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '4rem',
                          }}
                        />
                      ) : (
                        props.location != '/menu-items' && (
                          <img
                            src={logo}
                            alt="logo1"
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              width: '4rem',
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {props.env.restaurantId === 'istanbulDk' 
             && props.isAdmin ? (
                    <img
                      src={blacklogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'istanbulDk' || 
                  props.env.restaurantId === 'taqwasbakery' ? (
                    props.title
                  ) : props.env.restaurantId === 'ttDemo' ? (
                    <img
                      src={TTdemoLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'elmbrookschools' ? (
                    <img
                      src={schoolLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : (
                    <h2 style={{ color: 'white' }}>{props.title}</h2>
                  )}
                </>
              )}
            </Typography>
            {auth?.currentUser?.uid && userData?.defaultStore?.length > 0 && stores?.length > 1 && (
              <Typography
                variant="caption"
                style={{
                  color: '#c6c6c6',
                  cursor: 'pointer',
                  fontSize: mdDown ? '6px' : '16px',
                }}
                onClick={handleClickOpen}
              >
                (
                {stores
                  ?.find((c) => c.id === userData?.defaultStore)
                  ?.address?.displayName.split(',')
                  .slice(0, 1)
                  .join(',')}
                )
              </Typography>
            )}
            {props.isAdmin ? (
              <>
                <Hidden smUp>
                  <>
                    <IconButton
                      styly={{
                        backgroundColor: 'white',
                        marginLeft: '10px',
                      }}
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleClickOpenDrawer}
                    >
                      <MenuIcon
                        style={{
                          color: 'rgb(160, 25, 19)',
                          marginLeft: '10px',
                        }}
                      />
                    </IconButton>
                  </>
                </Hidden>
              </>
            ) : (
              <>
                {props.env.restaurantId !== 'filfil'  && (
                  <>
                  <Hidden smUp>

                  <FiClock
                     size={25}
                     style={{
                      color: "#081212",
                      width: '200%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '40px',
                     
                      mr:1,
                      cursor: 'pointer',
                    }}
                    onClick={toggleHours}
                    />
                  </Hidden>
                  <Hidden smDown>
                         <FiClock
                     style={{
                      color: "#081212",
                      width: '5%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '30px',
                      mr:1,
                      cursor: 'pointer',
                    }}
                    onClick={toggleHours}
                    />
                    </Hidden>
          {showHours && (
        <Box
          sx={{
            position: "absolute",
            top: "80px",
            left: "0",
            width: "250px",
            padding: "16px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            zIndex: 10,
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#081212", fontSize: "18px", fontWeight:400 ,fontFamily:"Outfit", marginBottom: "8px" }}
          >
            Opening Hours
          </Typography>
          {groupedHours.map((group, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: group.hours === "closed" ? "red" :  "#081212",
                  fontSize: "16px", fontWeight:400 ,fontFamily:"Outfit",
                }}
              >
                {group.days}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color:  "#081212",
                  fontSize: "14px", fontWeight:300 ,fontFamily:"Outfit",
                }}
              >
                {group.hours}
              </Typography>
            </div>
          ))}
        </Box>
      )}   
       <>
                   <Button
                        
                        style={{
                          color: '#081212',
                          backgroundColor: '#fff',
                          fontFamily:"Outfit",
                          fontSize:"16px",
                          textTransform:"capitalize",
                          fontWeight:"bold",
                          width:"70px",
                          padding: '8px 8px',
                        }}
                       
                        onClick={() => {
                          window.location.href = 'https://taqwasbakery.com/';
                        }}
                      >
                        {t('home')}
                      </Button>
                      <Button
              
                         style={{
                          color: '#081212',
                          backgroundColor: '#fff',
                          fontFamily:"Outfit",
                          fontSize:"16px",
                          textTransform:"capitalize",
                          borderRadius: '20px',
                          width:"70px",
                          fontWeight:"bold",
                          padding: '8px 8px',
                        }}
                        onClick={() =>
                          auth?.currentUser?.uid &&
                          userData?.defaultStore?.length > 0
                            ? menuDefaultStore()
                            : handleClickOpen()
                        }
                        
                      > {t('ourMenu')}</Button>
                       <Button
                        
                        style={{
                          color: '#081212',
                          backgroundColor: '#fff',
                          fontFamily:"Outfit",
                          fontSize:"16px",
                          textTransform:"capitalize",
                          fontWeight:"bold",
                          width:"70px",
                          padding: '8px 8px',
                        }}
                       
                        onClick={() => {
                          window.location.href = 'https://taqwasbakery.com/blogs';
                        }}
                       
                      >
                        Blogs
                      </Button>
                      
                        <Button
                        
                          style={{
                            color: '#081212',
                            backgroundColor: '#fff',
                            fontFamily:"Outfit",
                            fontSize:"16px",
                            textTransform:"capitalize",
                            fontWeight:"bold",
                            width:"120px",
                            padding: '8px 8px',
                          }}
                          aria-controls={
                            openMenu ? 'demo-customized-menu' : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openMenu ? 'true' : undefined}
                       
                          onClick={() => {
                            window.location.href = 'https://taqwasbakery.com/contactus';
                            
                          }}
                         
                        >
                          {t('contact')}
                        </Button>
                       
                       
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => {
                            window.location.href = 'https://taqwasbakery.com/contactus';
                            handleCloseMenu(); 
                          }}
                          disableRipple
                        >
                          {t('contact')}
                        </MenuItem>

                        <Divider />
                        <MenuItem
                          onClick={() => {
                            navigate('/careers');
                            handleCloseMenu();
                          }}
                          disableRipple
                        >
                          {t('career')}
                        </MenuItem>
                        <Divider />
                        {props.env.restaurantId === 'istanbulDk' ||
                        props.env.restaurantId === 'taqwasbakery' ||
                          (props.env.restaurantId === 'alyousef' && (
                            <MenuItem
                              onClick={() => {
                                navigate('/aboutus');
                                handleCloseMenu();
                              }}
                              disableRipple
                            >
                              About us
                            </MenuItem>
                          ))}
                      </StyledMenu>
                    </>
                    {store.length == 1 ? (
                      <>
                        {/* <Button
                        disableRipple={true}
                        disableFocusRipple={true}
                        sx={{ my: 2, display: 'block' }}
                        disabled={open}
                      >
                        {store &&
                          store?.map((c) => (
                            <CustomButton
                              bg={props.env.mainColor}
                              onClick={async () => {
                                // setDefaultStore(
                                //   token,
                                //   c.id,
                                //   c?.restaurant_name,
                                //   setStore,
                                //   c?.pickup_enabled,
                                //   c?.pickup_enabled,
                                //   c.dinein_enable,
                                //   c.dinein_enable,
                                //   c.address.displayName,
                                //   c?.address?.displayName
                                // );
                                // if (
                                //   userData?.bagItems?.length > 0 &&
                                //   cart?.length > 0
                                // ) {
                                //   dispatch(checkStore(true));
                                // } else dispatch(checkStore(false));

                                // onClickItem(item.restaurant_name)
                                handleClose();
                                const updatedUser = {
                                  ...userData,
                                  defaultStore: c.id,
                                };
                                navigate('/menu');
                                dispatch(setUser({ userData: updatedUser }));
                                dispatch(
                                  activeMenu(res[c?.id]?.active_menu_now)
                                );
                                dispatch(
                                  allactiveMenues(res[c?.id]?.all_active_menus)
                                );
                                dispatch(menues(res[c && c?.id]?.menus));
                              }}
                              buttonText={"sssssssss"}
                            />
                          ))}
                      </Button> */}
                      </>
                    ) : (
                      <>
                        {/* <Button
                          bg={props.env.mainColor}
                          style={{
                            fontWeight: 'bold',
                            color: props.env?.primaryColor
                            // color:
                            //   location.pathname == '/menu'
                            //     ? props.env?.primaryColor
                            //     : 'white',
                          }}
                          onClick={handleClickOpen}
                        >
                          Menu
                        </Button> */}

                        <Dialog onClose={handleClose} open={open}>
                          <div style={{ width: '100%' }}>
                            <div
                              style={{
                                padding: '15px 20px 0px 0px',
                                display: 'flex',
                                justifyContent: 'end',
                                borderRadius: '25px',
                              }}
                            >
                              {' '}
                              <CancelRoundedIcon
                                style={{
                                  color: 'black',
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={handleClose}
                              />
                            </div>

                            <DialogTitle
                              style={{
                                display: 'grid',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  component="p"
                                  variant="h5"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {' '}
                                  {numberToWord(stores?.length)} Branches, One
                                  Appetite!
                                </Typography>
                              </div>

                              <Typography
                                component="p"
                                variant="body1"
                                style={{ color: 'gray' }}
                              >
                                {numberToWord(stores?.length)} Branches, Endless
                                Choices! Explore our menu at your preferred
                                spot.
                              </Typography>

                              <div></div>
                            </DialogTitle>

                            <DialogContent
                              dividers
                              style={{ textAlign: 'center' }}
                            >
                              {stores?.map((c) => (
                                <Grid
                                  item
                                  key={c.id}
                                  lg={4}
                                  style={{
                                    display: 'inline-block',
                                    color: 'white',
                                    textAalign: 'center',
                                    padding: '14px',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Card
                                    style={{
                                      border: '2px solid lightgray',
                                      borderRadius: '10px',
                                      borderColor: 'gray',
                                      width: '180px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={async () => {
                                      setDefaultStore(
                                        token,
                                        c.id,
                                        c?.restaurant_name,
                                        setStore,
                                        c?.pickup_enabled,
                                        c?.pickup_enabled,
                                        c.dinein_enable,
                                        c.dinein_enable,
                                        c.address.displayName,
                                        c?.address?.displayName
                                      );
                                      if (
                                        userData?.bagItems?.length > 0 &&
                                        cart?.length > 0
                                      ) {
                                        dispatch(checkStore(true));
                                      } else dispatch(checkStore(false));
                                      // onClickItem(item.restaurant_name)
                                      handleClose();
                                      const updatedUser = {
                                        ...userData,
                                        defaultStore: c.id,
                                      };
                                      dispatch(
                                        setUser({ userData: updatedUser })
                                      );

                                      dispatch(
                                        activeMenu(res[c.id]?.active_menu_now)
                                      );

                                      dispatch(
                                        allactiveMenues(
                                          res[c.id]?.all_active_menus
                                        )
                                      );

                                      dispatch(menues(res[c?.id]?.menus));

                                      navigate('/menu');
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        height: '245px',
                                      }}
                                    >
                                      <img
                                        src={c.image}
                                        alt={c.image}
                                        style={{
                                          width: '100px',
                                          height: '80px',
                                        }}
                                      />
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 'bold',
                                          color: 'black',
                                        }}
                                      >
                                        {c.restaurant_name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        style={{ color: 'gray' }}
                                      >
                                        {c.address.displayName
                                          .split(',')
                                          .slice(0, 2)
                                          .join(',')}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </DialogContent>
                          </div>
                        </Dialog>
                      </>
                    )}
                  </>
                )}
                
                  <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                {!props.isAdmin &&
                  auth?.currentUser?.uid &&
                  checkLicenses('profile') && (
                    <>
                      <div
                        style={{
                        
                          borderRadius: '20px',
                          marginRight: '14px',
                          marginLeft: '10px',
                         

                        
                        }}
                      >
                        <PersonOutlineSharpIcon
                          size={30}
                          style={{
                            color: props.env.mainColor,
                            cursor: 'pointer',
                            fontSize:"30px",
                          

                          }}
                          onClick={() => navigate('/profile')}
                        />
                      </div>
                    </>
                  )}
                  </Box>
              </>
            )}
            <div>
              {!props.isAdmin &&
              !auth?.currentUser?.uid &&
              checkLicenses('signup') ? (
                // <Button
                //   disableRipple={true}
                //   disableFocusRipple={true}
                //   sx={{ my: 2, display: 'block' }}
                //   disabled={open}
                // >
                <>
                  <Hidden smUp>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('Joinus')}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('signIn')}
                    />
                  </Hidden>
                </>
              ) : (
                // </Button>
                ''
              )}
            </div>

            {!props.isAdmin &&
            checkLicenses('checkout') &&
            auth?.currentUser?.uid ? (
              <>
                <CartDrawer
                  openCartDialoge={openCartDialoge}
                  handleCloseCartDialoge={handleCloseCartDialoge}
                  addresses={addresses}
                  userData={userData}
                  defaultStoreAdress={defaultStoreAdress}
                  setDefaultStoreAdress={setDefaultStoreAdress}
                />
                 {counter > 0 ? (
  <div className="flex items-center justify-center w-12 h-12 rounded-full mr-4">
    <IconButton onClick={handleClickOpenCartDialoge}>
      <Badge badgeContent={counter} color="error">
        <LocalMallOutlinedIcon size={30} style={{ color: "#172B4D" }} />
      </Badge>
    </IconButton>
  </div>
) : null}

                

                {/* </IconButton> */}
              </>
            ) : (
              !auth?.currentUser?.uid &&
              storedItems && (
                <>
                  <CartDrawer
                    openCartDialoge={openCartDialoge}
                    handleCloseCartDialoge={handleCloseCartDialoge}
                    addresses={addresses}
                    userData={userData}
                    defaultStoreAdress={defaultStoreAdress}
                    setDefaultStoreAdress={setDefaultStoreAdress}
                  />
                   {counter > 0 ? (
  <div className="flex items-center justify-center w-12 h-12 rounded-full mr-4">
    <IconButton onClick={handleClickOpenCartDialoge}>
      <Badge badgeContent={counter} color="error">
        <LocalMallOutlinedIcon size={30} style={{ color: "#172B4D" }} />
      </Badge>
    </IconButton>
  </div>
) : null}

                  
                </>
              )
            )}



            
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Formik
                initialValues={{
                  paymentFlow: '',
                  orderFlow: '',
                }}
                onSubmit={handleSubmit}
                // validationSchema={validateSchema}
              >
                {({
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  handleSubmit,
                  touched,
                }) =>
                  env && (
                    <Dialog
                      style={{ zIndex: 10 }}
                      open={openDialog}
                      keepMounted
                      onClose={() => {
                        setOpenDialog(false);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <div style={{ height: '200px', width: '200px' }}>
                          <Box sx={{ minWidth: 120 }}>
                            <div style={{ height: '20px' }} />
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Payment flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={env?.paymentFlow}
                                value={env?.paymentFlow}
                                label="Payment flow"
                                name={'paymentFlow'}
                                onChange={handleChange('paymentFlow')}
                              >
                                <MenuItem value={'clover'}>Clover</MenuItem>
                                <MenuItem value={'stripe'}>Stripe</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <div style={{ height: '30px' }} />
                          <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Order flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={env?.orderFlow}
                                defaultValue={env?.orderFlow}
                                label="Order flow"
                                name={'orderFlow'}
                                onChange={handleChange('orderFlow')}
                              >
                                <MenuItem value={'tablet'}>
                                  Kitchen app
                                </MenuItem>
                                <MenuItem value={'pos'}>Point of sale</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                        >
                          cancel
                        </Button>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={handleSubmit}
                        >
                          save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )
                }
              </Formik>
              {auth?.currentUser?.uid && props?.isAdmin && env && (
                <Tooltip
                  title="App setting"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsIcon
                    style={{
                      color: props.env.mainColor,
                      marginLeft: '5px',
                      cursor: 'pointer',
                      height: 30,
                    }}
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      </Hidden>
      </>
    )
  );
}
export default Header;
