import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, TextField, InputLabel } from '@mui/material';
import { Theme } from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const searchOptions = {
  types: ['geocode'],
  componentRestrictions: { country: 'us' },
};

const AddressPickerWithGoogle = React.forwardRef(
  ({ initialValue, getValues }) => {
    const [googleAddress, setGoogleAddress] = useState(initialValue || ''); // Use old address

    useEffect(() => {
      if (initialValue) setGoogleAddress(initialValue); // Update when editing
    }, [initialValue]);

    const [showOptions, setShowOptions] = useState(false);

    const handleSelect = async (address) => {
      setShowOptions(false);
      setGoogleAddress(address);

      let route = '';
      let country = '';
      let state = '';
      let city = '';
      let zipCode = '';
      let streetNumber = '';

      const geoCode = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(geoCode[0]);

      const placeId = geoCode[0].place_id;
      const displayName = geoCode[0].formatted_address;

      geoCode[0]['address_components'].forEach((addressComponent) => {
        const { types } = addressComponent;

        if (types.includes('country')) {
          country = addressComponent['short_name'];
        }

        if (types.includes('route')) {
          route += addressComponent['short_name'];
        }
        if (types.includes('street_number')) {
          streetNumber += ' ' + addressComponent['short_name'];
        }

        if (types.includes('postal_code')) {
          zipCode = addressComponent['short_name'];
        }
        if (types.includes('administrative_area_level_1')) {
          state = addressComponent['short_name'];
        }
        if (
          types.includes('sublocality_level_1') ||
          types.includes('administrative_area_level_3') ||
          types.includes('locality') ||
          types.includes('sublocality_level_1')
        ) {
          city = addressComponent['short_name'];
        }
      });

      getValues({
        country,
        streetNumber,
        state,
        city,
        zipCode,
        placeId,
        displayName,
        lat,
        lng,
        route,
      });
    };

    useEffect(() => {
      if (googleAddress.trim() === '') {
        getValues({}); // Reset values if empty
      }
    }, [googleAddress]);

    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={googleAddress} // Show old address
        onChange={(value) => {
          setShowOptions(value ? true : false);
          setGoogleAddress(value);
        }}
        onSelect={handleSelect}
      >
        {({ getInputProps, loading, suggestions, getSuggestionItemProps }) => (
          <div>
            <InputLabel
                htmlFor="nickname"
                shrink
                sx={{
                  color: 'gray',
                  fontSize: '17px',
                  marginLeft: '5px',
                  fontWeight: '400',
                  fontFamily: 'Outfit, sans-serif',
                  marginBottom: '5px',
                }}
              >
                Address
              </InputLabel>
           <TextField
 
  variant="outlined"
  name="googleSearchField"
 
  sx={{
    backgroundColor: '#121212', // Dark background
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px', // Rounded edges
      '& fieldset': { borderColor: '#333' }, // Default border color
      '&:hover fieldset': { borderColor: '#555' }, // Hover effect
      '&.Mui-focused fieldset': { borderColor: 'gray' }, // Focused border color
      '& input': { color: 'white' }, // Text color when typing
      '& input::placeholder': { color: 'gray', opacity: 1 }, // Placeholder text color
    },
  }}
  fullWidth
  {...getInputProps({ placeholder: 'House number and street name' })} 
  onBlur={() => setShowOptions(false)}
/>

            {showOptions && (
              <Card id="search-address">
                <CardContent>
                  {suggestions.length === 0 ? (
                    <div>loading ...</div>
                  ) : (
                    suggestions.map((s, index) => (
                      <div {...getSuggestionItemProps(s)} key={index}>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography variant="body1" color="textPrimary">
                              {s.formattedSuggestion.mainText}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {s.formattedSuggestion.secondaryText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ))
                  )}
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
);

export default AddressPickerWithGoogle;
