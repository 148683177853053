import { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

const PersonalInfo = () => {
  const [fieldValues, setFieldValues] = useState({});
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.authReducer.userData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (field, value) => {
    setFieldValues({
      ...fieldValues,
      [field]: value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const updatedUser = { ...user, ...fieldValues };
    try {
      await firestore.collection('users').doc(user?.uid).set(updatedUser, { merge: true });
      dispatch(setUser({ userData: updatedUser }));
    } catch (error) {
      console.error('Error updating user info:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const digits = number.replace(/\D/g, '');
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  return (
    <Box
    sx={{
      backgroundColor: '#151414',
      borderRadius: '15px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      marginTop: { xs: '10px', sm:'180px', md: '20px' },
      marginLeft: { xs: '10px', sm: '40px' ,md:'50px' },
      marginRight: { xs: '10px', md: '50px' },
      marginBottom :{xs:"170px" ,md :"170px" ,lg:"0px",},
      maxWidth: '900px',
      minHeight: '510px',
    }}
    >
      <Typography
        style={{
          fontWeight: 'bold',
          color: '#FFFFFF',
          fontSize: '20px',
          marginBottom: '60px',
        }}
      >
        {t('editProfile')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            style={{
              fontWeight: 'bold',
              color: '#FFFFFF',
              marginBottom: '8px',
            }}
          >
            {t('profilee.name')}
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              fullWidth
              value={fieldValues.fullName || user?.fullName || ''}
              onChange={(e) => handleChange('fullName', e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: '#1F1F1F',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                },
              }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#FFFFFF',
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            style={{
              fontWeight: 'bold',
              color: '#FFFFFF',
              marginBottom: '8px',
            }}
          >
            {t('profilee.phone')}
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              fullWidth
              value={fieldValues.phoneNumber || formatPhoneNumber(user?.phoneNumber) || ''}
              onChange={(e) => handleChange('phoneNumber', e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: '#1F1F1F',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                },
              }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#FFFFFF',
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            style={{
              fontWeight: 'bold',
              color: '#FFFFFF',
              marginBottom: '8px',
            }}
          >
            {t('profilee.email')}
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              fullWidth
              value={fieldValues.email || user?.email || ''}
              onChange={(e) => handleChange('email', e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: '#1F1F1F',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                },
              }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#FFFFFF',
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <Button
        fullWidth
        style={{
          width:"25%",
          backgroundColor: '#A01913',
          color: '#FFFFFF',
          padding: '10px',
          borderRadius: '30px',
          fontWeight: 'bold',
          marginTop: '20px',
          textTransform: 'none',
          fontSize: '16px',
        }}
        onClick={handleSave}
        disabled={loading}
      >
        {loading ? t('profilee.saving') : t('profilee.save')}
      </Button>
    </Box>
  );
};

export default PersonalInfo;
