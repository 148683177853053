// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
export const environment = {
  production: false,
  restaurantId: 'taqwasbakery',
  firebaseConfig: {
    apiKey: 'AIzaSyCpZXC6DhXrnLCi2GIKXg75zF9-lWvjtYM',
    authDomain: 'taqwasbakery.firebaseapp.com',
    projectId: 'taqwasbakery',
    storageBucket: 'taqwasbakery.appspot.com',
    messagingSenderId: '809879353118',
    appId: '1:809879353118:web:b4cdc7424543f2064bbb0f',
    measurementId: 'G-CNNZ0NG8XY',
  },
  mainColor: '#6f0102',
 
  facebookLink: 'https://www.facebook.com/TaqwasBnR',
  twitterLink: 'https://twitter.com/Taqwas_BnR',
  yelpLink: 'https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?osq=Taqwa',
  instagramLink: 'https://www.instagram.com/Taqwas_BnR/',
  phoneNumber: '(414) 539 6878',
  address: '4651 S 27th St, Greenfield, WI 53221',
  email: 'info@taqwasBakery.com',
  about: `At a young age, the founders of Taqwa's Bakery & Restaurant
  discovered the importance of having the right ingredients to
  create the perfect recipe. Taqwa and her husband, the
  restaurant founders, collaborated and utilized their
  techniques to craft delicious Middle Eastern cuisine that
  delighted their loved ones.`,
  isRoot: false,
  primaryColor: '#8B8A8A',

  apiUrl: 'https://us-central1-wl-restaurants.cloudfunctions.net/api',
};
