import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Grid,
  Divider,
  IconButton,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { firestore } from '@tabletuck/firebase-services';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuCard } from '../../../../../../libs/components/src';
import { environment } from '../../../environments/environment';
import { auth } from '../../../../../../libs/firebase-service/src';
import image from '../../../../../../apps/table-tuck-web/src/assets/rafiki.png';
import { useTranslation } from 'react-i18next';
import CustomizeDialoge from '../CustomizeDialoge/CustomizeDialoge';
import { FaHeart, FaRegHeart, FaShoppingBasket } from 'react-icons/fa';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';


function FavoriteItems() {
  const theme = useTheme();
  const navigate = useNavigate();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  // const [items, setItems] = useState([]);
  // const [user, setUser] = useState();
  const [fav, setFav] = useState([]);
  const [name, setName] = useState([]);
  const [emptyfav, setEmptyfav] = useState('');
  const [option, setOption] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [extraQuantity, setExtraQuantity] = useState({});
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const [data, setData] = React.useState([]);
  const [defs, setDefs] = useState([]);
  const [enableAdd, setEnableAdd] = useState(false);
  // name, description, id, item_image_url
  const items = useSelector((state) => state.shopReducer.currentItem);
  const user = useSelector((state) => state.authReducer.userData);
  const [openn, setOpenn] = useState(false);
  const [item, setItem] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false); 
  const [selectedItem, setSelectedItem] = useState(null); 

  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const dispatch = useDispatch();
  const getFav = () => {
    let t = [];
    user?.favorites &&
      user?.favorites?.map((c) => {
        return items?.filter((d) => {
          if (d.id === c) {
            t.push(d);
          }
        });
      });
    setName(t);
  };
  useEffect(() => {
    if (user?.favorites?.length > 0) {
      setEmptyfav('');
      getFav();
    } else {
      setName([]);
      setEmptyfav(`${t('profilee.noFavItems')}`);
    }
  }, [user?.favorites]);

  const handleOpenn = () => {
    setId(id);
    setOpenn(true);
    setItem(item);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const updateEmptyFavState = (newState) => {
    setEmptyfav(newState);
  };
  const removeFavorite = async (selectedItem) => {
    try {
      const arr = user?.favorites || [];
      const updatedFavorites = arr.filter(
        (favoriteId) => favoriteId !== selectedItem?.id
      );
  
      console.log('Updated Favorites:', updatedFavorites);
  
      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: updatedFavorites,
          },
          { merge: true }
        );
  
      const updatedUser = { ...user, favorites: updatedFavorites };
      dispatch(setUser({ userData: updatedUser }));
  
      // Update empty favorites state if necessary
      updateEmptyFavState(
        updatedFavorites.length === 0 ? `${t('profilee.noFavItems')}` : ''
      );
  
      // Recalculate favorite items
      getFav();
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };
  
    

  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          storess?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };

  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraquantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item.modifier_group_ids.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setcustom((c) => (c ? c + `, ${opt.title}` : opt.title));
              setDefs((defs) =>
                defs.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
        setOptionsPrice(o > 0 ? o : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getModifiers = async () => {
    setData(modifiers);
  };
  const handleOpen = (id, item) => {
    setId(id);
    setOpen(true);
    setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [t, i18n] = useTranslation();

 

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        lg={8}
        sx={{
          backgroundColor: '#151414',
borderRadius: '15px',
padding: '20px',
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
marginTop: { xs: '10px',sm:'150px', md: '20px' },
marginLeft: { xs: '10px', sm: '40px' ,md:'50px' },
marginRight: { xs: '10px', md: '50px' },
marginBottom :{xs:"170px" ,md :"170px" ,lg:"170px",},
maxWidth: '900px',
minHeight: '510px',
          
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontFamily: 'Outfit, sans-serif', 
            color: 'white',
            fontSize: '20px',
            marginBottom: '20px',
            marginTop: '10px',
          }}
        >
          {t('favoriteItems')}
        </Typography>
        
        {name.length === 0 ? (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center',
      color: 'white',
    }}
  >
    <div
      style={{
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        backgroundColor: '#2C2C2C',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
      }}
    >
      <FaRegHeart style={{ color: environment.mainColor, fontSize: '40px' }} />
    </div>
    <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '10px' , fontFamily: 'Outfit, sans-serif',  }}>
    Your wishlist is empty
    </Typography>
    <Typography style={{ color: '#B7B7B7', marginBottom: '20px' , width:"70%" , fontFamily: 'Outfit, sans-serif',  }}>
    You don’t have any products in the wishlist yet. You will find a lot
of interesting products on our Shop page
    </Typography>
    <button
      style={{
        backgroundColor: environment.mainColor,
        fontFamily: 'Outfit, sans-serif', 
        color: 'white',
        padding: '10px 30px',
        borderRadius: '20px',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/')}
    >
      Continue Shopping
    </button>
  </div>
) : (
  name.map((item, index) => (
    <Grid container key={index} spacing={2} sx={{ my: "20px" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: { xs: "wrap", sm: "wrap", md: "nowrap" }, // Wrap on small screens, stay in row on large screens
          gap: "1rem",
        }}
      >
        {/* 🖼️ Image Section */}
        <img
          src={item?.item_image_url}
          style={{
            borderRadius: "50%",
            width: "80px",
            height: "80px",
          }}
          alt="Menu Item"
          className="menu-item-image"
        />
  
        {/* 📝 Info + Buttons Container */}
        <div
          style={{
            flex: 1,
            minWidth: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Keep text and buttons in the same row on large screens
            flexWrap: { xs: "wrap", md: "nowrap" }, // Wrap text and buttons in small screens
          }}
        >
          {/* 📝 Text Section */}
          <div style={{ flexGrow: 1 }}>
            <h6
              className="menu-item-title"
              style={{
                color: "white",
                fontSize: "18px", // Larger font for desktop
                fontWeight: 500,
              }}
            >
              {item?.name}
            </h6>
  
            <p
              className="menu-item-description"
              style={{
                color: "#9C9C9C",
                fontFamily: "Outfit",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
               {item?.description.length > (smDown ? 50 : 99)
    ? item?.description.slice(0, smDown ? 50 : 99) + "..."
    : item?.description}
            </p>
  
            {/* ⭐ Price (Optional) */}
            <p style={{ color: "red", fontWeight: "bold" }}>${item?.price}</p>
          </div>
  
          {/* ❤️ + 🛒 Buttons - Aligned Right on Large Screens, Below on Mobile */}
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "row", md: "row" }, // Buttons inline for large screens
              alignItems: "center",
              justifyContent: { xs: "center", md: "flex-end" },
              gap: "10px",
              width: "auto",
            }}
          >
            {/* ❤️ Favorite Icon */}
            <IconButton
              sx={{
                backgroundColor: "#ffffff",
                color: environment.mainColor,
                borderRadius: "50%",
                padding: "6px",
                "&:hover": {
                  backgroundColor: "#FF4C4C",
                  color: "white",
                },
              }}
              onClick={() => {
                setSelectedItem(item);
                setConfirmOpen(true);
              }}
            >
              <FaHeart />
            </IconButton>
  
            {/* 🛒 Add to Cart Button */}
            <button
              style={{
                backgroundColor: environment.mainColor,
                color: "white",
                padding: "8px 20px",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                border: "none",
                cursor: "pointer",
                maxWidth: "150px", 
              }}
              onClick={() => handleOpen(item?.id, item)}
            >
              <FaShoppingBasket style={{ color: "#ffffff", fontSize: "14px" }} />
              <span style={{ fontSize: "14px" }}>{t("menu.add")}</span>
            </button>
          </div>
        </div>
      </Grid>
  
      {/* 🔽 Divider for Separation */}
      <Grid item xs={12}>
        <Divider
          sx={{
            backgroundColor: "#2D2D2D",
            marginTop: "20px",
            height: "2px",
          }}
        />
      </Grid>
    </Grid>
  ))
  
  
)}
<Dialog
  open={confirmOpen}
  onClose={() => setConfirmOpen(false)}
  sx={{
    '& .MuiDialog-paper': {
      backgroundColor: '#252525',
      color: 'white',
      borderRadius: '16px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }}
>
  <DialogContent>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
      }}
    >
      <img
        src={image}
        alt="Delete from Favorite"
        style={{
          width: '80%',
          borderRadius: '10px',
        }}
      />
    </Box>
    <Typography
      variant="h6"
      sx={{
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#fff',
        marginBottom: '10px',
        textAlign: 'center',
        fontFamily:"Outfit" ,
      }}
    >
      Delete from Favorite
    </Typography>
    <DialogContentText sx={{ color: '#A2A2A2', textAlign: 'center' ,fontFamily:"Outfit", }}>
      Are you sure you want to delete this item from your favorites?
    </DialogContentText>
    
  </DialogContent>

  <DialogActions sx={{ width: '100%', justifyContent: 'center' }}>
    <Button
      onClick={() => setConfirmOpen(false)}
      sx={{
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '30px',
        padding: '8px 20px',

        marginRight: '10px',
        backgroundColor: '#4B4B4B',
        '&:hover': {
          backgroundColor: "#4B4B4B",
        },
      }}
    >
      Cancel
    </Button>
    <Button
      onClick={() => {
        removeFavorite(selectedItem);
        setConfirmOpen(false);
        setSelectedItem(null);
      }}
      sx={{
        color: "white",
        fontWeight: 'bold',
        fontFamily:"Outfit",
        borderRadius: '30px',
        padding: '8px 20px',
        backgroundColor: '#A01913',
        '&:hover': {
          backgroundColor: '#A01913',
        },
      }}
    >
      Confirm
    </Button>
  </DialogActions>
</Dialog>


      </Grid>
      {item?.id === id ? (
        <CustomizeDialoge
          open={open}
          handleClose={handleClose}
          option={option}
          c={item}
          priceToShow={item?.price + optionsPrice1}
          data={data}
          defs={defs}
          defaultPrices={optionsPrice1}
          extraQuantity={extraQuantity}
          setExtraQuantity={setExtraQuantity}
          outofStockDelivery={outofStockDelivery}
          openn={openn}
          handleClosee={handleClosee}
          handleOpenn={handleOpenn}
          enableAdd={enableAdd}
        />
      ) : (
        ''
      )}
    </>
  );
}
export default FavoriteItems;
