import React, { useState } from 'react';
import {
  Container,
  Typography,
  Divider,
  Grid,
  Paper,
  Box,
  TextField,
  InputLabel,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Formik } from 'formik';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { CustomButton } from '@tabletuck/components';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';
import {handleToaster} from '../../Utils/UtilsFunctions';
import { ToastContainer } from 'react-toastify';
const PaymentHandler = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const [t, i18n] = useTranslation()

  const appearance = {
    theme: 'flat',
    rules: {
      '.Tab': {
        border: '1px solid red',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        backgroundColor: 'var(--colorBackground)',
      },
      '.Input': {
        backgroundColor: 'red',
        border: '1px solid green',
      },
      '.Input:focus': {
        backgroundColor: 'red',
      },
      '.Input:hover': {
        color: 'var(--colorText)',
        backgroundColor: 'var(--colorBackground)',
      },
      '.Tab--selected': {
        border: '1px solid',
        borderColor: 'red',
        backgroundColor: 'var(--colorBackground)',
      },
      '.Input--invalid': {
        boxShadow:
          '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
      },
    },
    variables: {
      colorPrimary: 'red',
      colorBackground: 'grey',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
  };
  const elements = useElements({ appearance });
  const token = useSelector((state) => state.authReducer.token);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      // .matches(/^[a-zA-Z]+$/, 'Card name should contain characters only')
      .min(3, 'name must be at least 3 characters')
      .required('neme is a required field'),
    zip: Yup.number()
      .typeError('Please enter a valid zip code')
      .required('Required')
      .test(
        'len',
        'Zip code should contain 5 characters',
        (val) => !val || (val && val.toString().length === 5)
      ),
  });
  const handleSubmit = async (values) => {
    if (!stripe || !elements) return;
    try {
      setLoading(true);
      const cardElement = elements.getElement(CardNumberElement);
      let result = await stripe.createToken(cardElement, {
        name: values.name,
      });
      if (result.error) {
        setError(result.error.message);
        setLoading(false);
        return;
      }
      createPaymentSource(result, token, values.zip);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
      setLoading(false);
    }
  };
  const createPaymentSource = async (result, token, zipCode) => {
    try {
      auth?.currentUser.getIdToken().then((token) => {
        setLoading(true);
        const data = JSON.stringify({
          token: result.token.id,
          zipCode,
        });

        const headers = {
          'content-type': 'application/json',
          Authorization: token,
        };

        fetch(
          `${environment?.apiUrl}/addCard?restaurantId=${environment.restaurantId}`,
          {
            method: 'POST',
            headers: headers,
            body: data,
          }

        )
      
        .then(async (response) => {
          if (!response.ok) {
            const errorResponse = await response.json();
            console.log('Error inside response not ok:', errorResponse.message);
            setLoading(false);
            navigate(-1);
  
            handleToaster('error', errorResponse.message);
          }
  
          const Response = await response.json();
          console.log(' response inside  ok:', Response.msg);
          setLoading(false);
          navigate(-1);
  
          handleToaster('success', Response.msg);
        })
        .catch(async (e) => {
          console.log('api error catch', e);
          setLoading(false);
          navigate(-1);
          handleToaster('error', e.response.data.message || e.response.data.shortMessage);
        });
      });
    } catch (e) {
      // setError(e.message);
      console.log("api errorrr",e)
      console.log('api error', e.message);
      setLoading(false);
    }
  };
  // if(! stripe|| !elements) return ;
  return (
    <Box
    
   >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    
     
      <Box dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
        <Grid
          item
          style={{
            maxWidth: '100%',
            flexBasis: '100%',
          }}
        >
            {/* <Paper
              sx={{
                padding: '1.8rem 4.5rem 2rem 4.5rem',
                mx:"auto",
                backgroundColor: '#252525',
                borderRadius: '20px',
                maxWidth:"md",
              }}
            > */}
              
                <div style={{display:"flex" ,justifyContent:"start" ,}}>
                  <Typography
                    component="p"
                    variant="h6"
                    sx={{ fontWeight: 'bold', marginBottom: '20px' , color:"white" ,fontFamily:"Outfit"}}
                  >
                   
                   {t('profilee.addAPaymentMethod')}
                  </Typography>
                </div>
      
              <Formik
                initialValues={{
                  name: '',
                  zip: '',
                  disabled: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  handleSubmit,
                  touched,
                }) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                      <InputLabel htmlFor="zipcode" shrink sx={{color:'#C3C3C3' ,fontFamily:"Outfit" ,fontSize:"18px"}}>
                      {t('profilee.cardName')}
                    </InputLabel>
                        <TextField
                          fullWidth
                          // label={
                          //   i18n.language === 'ar'
                          //     ? ''
                          //     : `${t('profilee.cardName')}`
                          // }
                          name="name"
                          onChange={handleChange('name')}
                          onBlur={handleBlur('name')}
                          required
                          helperText=""
                          value={values.name}
                          variant="outlined"
                          sx={{
                            '& label': {
                              color: '#A0A0A0', 
                            },
                            '& label.Mui-focused': {
                              color: '#A0A0A0', 
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#333', 
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0', 
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#A0A0A0', 
                              },
                              '& input': {
                                color: 'white',
                              },
                            },
                          }}
                        />
                      </Grid>
                      {errors.name && touched.name ? (
                        <span
                          style={{
                            marginTop: '10px',
                            marginLeft: '30px',
                            color: 'red',
                            fontSize: '10px',
                          }}
                        >
                          {errors.name}
                        </span>
                      ) : (
                        ''
                      )}
                      <div style={{ height: '30px' }} />
                      <Grid sx={{ display: 'flex', width: '100%' , flexDirection: "column" ,marginLeft:"25px" ,marginTop:"20px"}}>
                     
  <InputLabel
    htmlFor="cvc"
    shrink
    sx={{
      color: '#C3C3C3',
      fontFamily: "Outfit",
      fontSize: "18px",
    }}
  >
  {t('profilee.cardNumber')}
  </InputLabel>
                        <div
                          style={{
                            padding: 10,
                            paddingBottom: 15,
                            paddingTop: 15,
                            height: 50,
                            borderRadius: 5,
                            border: 'solid 1px #333',
                            width: '100%',
                          }}
                        >
                          
                          <CardNumberElement
  id="cardNumber"
  options={{
    showIcon: false,
    style: {
      base: {
        color: "#ffffff", 
       
      },
      invalid: {
        color: "#ff4d4d", 
      },
    },
  }}
/>

                        </div>
                      
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        sx={{ display: 'flex', width: '100%' }}
                      >
                         <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
  <InputLabel
    htmlFor="cvc"
    shrink
    sx={{
      color: '#C3C3C3',
      fontFamily: "Outfit",
      fontSize: "18px",
    }}
  >
  MM/YY
  </InputLabel>
                        <div
                          style={{
                            marginRight: 10,
                            padding: 10,
                            paddingBottom: 15,
                            paddingTop: 15,
                            height: 50,
                            borderRadius: 5,
                            border: 'solid 1px #333',
                            width: '100%',
                          }}
                        >
                          <CardExpiryElement
                            options={{
                              
                              style: {
                                base: {
                                  color: "#ffffff", 
                                  
                                },
                                invalid: {
                                  color: "#ff4d4d", 
                                },
                              },
                            }}
                            
                            id="expiry"

                          />
                        </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" , marginLeft:"20px", }}>
  <InputLabel
    htmlFor="cvc"
    shrink
    sx={{
      color: '#C3C3C3',
      fontFamily: "Outfit",
      fontSize: "18px",
    }}
  >
    CVC
  </InputLabel>
  <div
    style={{
      padding: 10,
      paddingBottom: 15,
      paddingTop: 15,
      height: 50,
      borderRadius: 5,
      border: 'solid 1px #333',
      width: '100%', 
     
     
    }}
  >
    <CardCvcElement
      options={{
        style: {
          base: {
            color: "#ffffff",
          },
          invalid: {
            color: "#ff4d4d",
          },
        },
      }}
      id="cvc"
    />
  </div>
</div>

                      </Grid>
                      <Grid item md={12} xs={12}>
                      <InputLabel htmlFor="zipcode" shrink sx={{color:'#C3C3C3' ,fontFamily:"Outfit" ,fontSize:"18px"}}>
                      {t('profilee.zipCode')}
                    </InputLabel>
                        <TextField
                          fullWidth
                          // label={
                          //   i18n.language === 'ar'
                          //     ? ''
                          //     : `${t('profilee.zipCode')}`
                          // }
                          name="type"
                          onChange={handleChange('zip')}
                          onBlur={handleBlur('zip')}
                          required
                          helperText=""
                          type='number'
                          value={values?.zip}
                          variant="outlined"
                          sx={{
                            '& label': {
                              color: '#A0A0A0', // Adjust label color to match the second image
                            },
                            '& label.Mui-focused': {
                              color: '#A0A0A0', // Keeps label color the same when focused
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#333', // Set default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0', // Keeps the border color on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#A0A0A0', // Keeps border color when focused
                              },
                              '& input': {
                                color: 'white', // Input text color
                              },
                            },
                          }}
                        />
                      </Grid>
                      {errors?.zip && touched?.zip ? (
                        <span
                          style={{
                            marginTop: '10px',
                            marginLeft: '30px',
                            color: 'red',
                            fontSize: '10px',
                          }}
                        >
                          {errors.zip}
                        </span>
                      ) : (
                        ''
                      )}
                      <Grid item md={12} xs={12}></Grid>
                      <Box
                        sx={{
                          margin: 4,
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'start',
                        }}
                      >
                        {loading ? (
                          <p
                            style={{
                              color: environment.mainColor,
                              fontWeight: '700',
                              fontSize: 20,
                            }}
                          >
                            {t('profilee.loading')}
                          </p>
                        ) : (
                          <>
                            <button
                             style={{
                                       width: '20%',
                                       boxShadow:
                                         '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                       color: "#fff",
                                       fontFamily:"OutFit",
                                       backgroundColor:"#4B4B4B",
                                       padding: '13px',
                                       borderRadius: '20px',
                                       fontWeight: 'bold',
                                       marginRight:"20px",
                                     }}
                            
                             onClick={handleSubmit}
                           >
                             {t('profilee.cancel')}
                             </button>
                          <button
                            style={{
                                      width: '20%',
                                      boxShadow:
                                        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                      color: "#fff",
                                      fontFamily:"OutFit",
                                      backgroundColor:environment.mainColor,
                                      padding: '13px',
                                      borderRadius: '20px',
                                      fontWeight: 'bold',
                                    }}
                           
                            onClick={handleSubmit}
                          >
                            {t('menu.add')}
                            </button>
                           
                             </>
                        )}
                      </Box>
                    </Grid>
                  </div>
                )}
              </Formik>
            {/* </Paper> */}
          
        </Grid>
      </Box>
   
    </Box>
  );
  {
    /* </Elements> */
  }
};
export default PaymentHandler;
