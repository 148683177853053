import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Slide from '@mui/material/Slide';
import OrderSummary from './OrderSummary';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import Rating from '@mui/material/Rating';
import Loading from 'react-fullscreen-loading';

import {
  clearCartData,
  setCart,
  setUser,
  UdpateNote,
} from '../../../../../../libs/redux-slices/src';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';
import { useNavigate } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderHistory = (props) => {
  const [open, setOpen] = useState(false);
  const [openReorder, setOpenReorder] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [value, setValue] = useState(5);
  const [feedback, setFeedBack] = useState('');
  const [updatedData, setUpdatedData] = useState({});
  const navigate = useNavigate();
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const cart = useSelector((state) => state.shopReducer.cart);
  const user = useSelector((state) => state.authReducer.userData);

  const handleCloseReorder = () => {
    setOpenReorder(false);
  };
  const handleCloseRate = () => {
    setOpenRate(false);
    setFeedBack('');
  };
  const handleCloseCancel = () => {
    setCancelOrder(false);
  };
  const dispatch = useDispatch();
  const handleGetNewestOrder = async () => {
    try {
      const querySnapshot = await firestore
        .collection('orders')
        .orderBy('created_at', 'desc') // Order by 'created_at' in descending order
        .limit(1) // Limit the result to 1 document
        .get();

      if (!querySnapshot.empty) {
        const newestOrder = querySnapshot.docs[0];
        setUpdatedData(newestOrder.data());
      }
    } catch (error) {
      console.log('Error fetching newest order:', error);
    }
  };

  useEffect(() => {
    handleGetNewestOrder();
  }, []);
  const {
    uid,
    order_id,
    deliveryOption,
    store,
    order_total,
    order_items,
    created_at,
    order_status,
    payment_method,
    cancellation_time,
    rated,
  } = props.order;
  const token = useSelector((state) => state.authReducer.token);
  const [rate, setRate] = useState(rated);
// Use fallback if items are undefined
  const Reorder = async () => {
    try {
      const updatedCart = [...cart, ...order_items];
      const updatedUser = { ...user, bagItems: updatedCart };
      await firestore.collection('users').doc(auth?.currentUser?.uid).set(
        {
          bagItems: updatedCart,
        },
        { merge: true }
      );
      dispatch(setCart(updatedCart));
      dispatch(setUser({ userData: updatedUser }));
      setOpenReorder(false);
    } catch (error) {
      console.log('Error in Reorder function:', error);
    }
  };

  const CancelOrder = async () => {
    setLoading(true);
    setErr('');
    try {
      setLoading(true);

      auth?.currentUser.getIdToken().then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        setLoading(true);

        await fetchData(
          `${environment?.apiUrl}/cancelOrderV2?restaurantId=${environment.restaurantId}`,
          'post',
          {
            orderId: order_id,
            reason: 'cancel order',
            typeCancel: 'Customer',
            uid: auth?.currentUser?.uid,
          },
          headers
        )
          .then((c) => {
            handleToaster('success', c.data.msg);

            setLoading(false);
            props.setCancelOrderget(true);
          })
          .catch((c) => {
            handleToaster('error', c.response.data.message  || 'error');
        

            setLoading(false);
          });
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErr(err);
    }

    setCancelOrder(false);
    setLoading(false);
  };
  const RateOrder = async () => {
    try {
      setLoading(true);
      setErr('');

      const token = await auth?.currentUser.getIdToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };

      await fetchData(
        `${environment?.apiUrl}/review?restaurantId=${environment.restaurantId}`,
        'post',
        {
          orderId: order_id,
          text: feedback,
          rating: value,
        },
        headers
      );
      setLoading(false);
      setFeedBack('');
      handleToaster('success', 'sent successfully');

      handleCloseRate();
      setRate(true);
    } catch (err) {
      handleToaster('error', err.response.data.message);
      setLoading(false);
      handleCloseRate();

      // Handling errors
      console.log('Error:', err);
    }
  };

  const [created, setCreated] = useState(new Date(created_at?.seconds * 1000));
  const deliveryDate = created && moment(created).add(5, 'days').toISOString(); // Example calculation
  const items = order_items || []; 
  const [cancel, setCancel] = useState(
    new Date(cancellation_time?.seconds * 1000)
  );

  let storeName = store.restaurant_name;
  const handleClickOpen = () => {
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let d = new Date();
  const [t, i18n] = useTranslation();

  return (
    <>
{/*     
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
       
       <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#151414',
   
    marginTop: '15px',
    marginBottom: '40px',
    padding: '15px',
   
  }}
>
  {/* Order Details Card */}
  <Box
  sx={{
    backgroundColor: "#262626",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  }}
>
  <Grid container spacing={2} sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}>
    {/* Left Side: Order Details */}
    <Grid item xs={12} sm={12} md={6}>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "white",
        }}
      >
        Order no: <span style={{ color: "#B7B7B7" }}>#{order_id}</span>
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: "13px",
          color: "#B7B7B7",
          marginTop: "8px",
        }}
      >
        Order Date:{" "}
        <span style={{ color: "white" }}>{moment(created).format("LLL")}</span>
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: "13px",
          color: "#B7B7B7",
          marginTop: "8px",
        }}
      >
        Estimated Delivery Date:{" "}
        <span style={{ color: "white" }}>{moment(deliveryDate).format("LLL")}</span>
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: "13px",
          color: "#B7B7B7",
          marginTop: "8px",
        }}
      >
        Payment Method: <span style={{ color: "white" }}>{payment_method}</span>
      </Typography>
    </Grid>

    {/* Right Side: Status and Pickup */}
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "flex-start", md: "flex-end" }, // Left on mobile, right on large screens
        textAlign: { xs: "left", md: "right" },
      }}
    >
      <Typography
  variant="body2"
  sx={{
    fontSize: "13px",
    color: "#B7B7B7",
    display: "flex",
    alignItems: "center",
  }}
>
  Order Status:{" "}
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      backgroundColor:
        order_status === "new" || order_status === "ready_to_pickup" || order_status === "confirmed"
          ? "rgba(255, 165, 0, 0.2)" 
          : order_status === "delivered"
          ? "rgba(40, 167, 69, 0.2)" 
          : "rgba(220, 53, 69, 0.2)", 
      color:
        order_status === "new" || order_status === "ready_to_pickup" || order_status === "confirmed"
          ? "#FFA500" 
          : order_status === "delivered"
          ? "#28a745" 
          : "#dc3545", 
      padding: "3px 10px",
      borderRadius: "20px",
      fontSize: "12px",
      fontWeight: "bold",
      marginLeft: "8px",
    }}
  >
      ● { 
    order_status === "new" || order_status === "ready_to_pickup" || order_status === "confirmed" 
      ? "In Progress" 
      : order_status === "delivered"
      ? "Completed"
      : "Canceled" 
  }
  </Box>
</Typography>


      <Typography
        variant="body2"
        sx={{
          fontSize: "13px",
          color: "#B7B7B7",
          marginTop: "8px",
        }}
      >
        Picked Up: <span style={{ color: "white" }}>{storeName}</span>
      </Typography>
    </Grid>
  </Grid>
</Box>

  {/* Image and Button Section */}
  <Grid container sx={{ alignItems: 'center' }}>
    <Grid item lg={6} sm={12}>
      <Box sx={{ display: 'flex', alignItems: 'end', gap: 2 ,mt:1}}>
        <img
          src={items[0]?.image || '/fallback-image.jpg'}
          alt={items[0]?.name || 'Item Image'}
          style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%', 
            objectFit: 'cover',
          }}
        />
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: 'white',
            }}
          >
            {items[0]?.title
 || 'Item Name'}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '13px',
              color: '#B7B7B7',
              marginTop: '5px',
            }}
          >
            Qty: {items[0]?.quantity || 1}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              marginTop: '5px',
              color: 'white',
            }}
          >
            Total: ${items[0]?.price || '0.00'}
          </Typography>
        </Box>
      </Box>
    </Grid>
    <Grid item lg={6} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#A01913',
          fontFamily:'Outfit',
          borderRadius: '20px',
          textTransform:"capitalize",
          marginTop:{xs:"15px",sm:"10px" ,md:"0px",lg:"0px"},
          padding: '10px 25px',
          fontSize: '13px',
          color: 'white',
          '&:hover': {
                        backgroundColor: '#303030'}, 
        }}
        onClick={handleClickOpen}
      >
        View Detail
      </Button>
    </Grid>
  </Grid>
  <div>
          <OrderSummary
            order_id={order_id}
            storeName={storeName}
            order={props.order}
            open={open}
            handleClose={handleClose}
            Transition={Transition}
          />
          <Loading
            loading={!!loading}
            background="rgba(86, 100, 210, 0.1)"
            loaderColor={environment.mainColor}
          />
        </div>
        <Divider
  sx={{
    backgroundColor: '#2D2D2D',
    marginTop: '20px',
    height: '2px',
  }}
/>
</Box>

    </>
  );
};

export default OrderHistory;
