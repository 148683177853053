import { Container, Box, Divider } from '@mui/material';

import Typography from '@mui/material/Typography';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, firestore } from '@tabletuck/firebase-services';
import Snipper from 'libs/components/src/lib/snipper/Snipper';
import { IoAdd, IoRemove } from "react-icons/io5";
import { FaRegTimesCircle } from "react-icons/fa";
import {
  CheckItem,
  CurrentItemValue,
  setCart,
  setUser,
  updateCode,
} from '@tabletuck/redux-slices';
import { checkCoupon } from '../CartDrawer/utils';
import { updateUser } from '../../../../../../libs/redux-slices/src';

const CartDrawerCard = ({
  item,
  code,
  couponRes,
  setDiscount,
  setIsVerified,
  total,
  setTotal,
  setRest,
  typeState,
  itemErr,
  outofStock,
  setOutofStockcheck,
  setOutofStockcheckDelivery,
  outofStockDeliveryStore,
  ApplyCoupon,
  setCoupon,
  setCouponRes,
  page,
}) => {
  const price = useRef();
  const items = useSelector((state) => state.shopReducer.currentItem);
console.log('items in cartDrawer:',items);
  useEffect(() => {
    price.current = +item.price;

    if (item?.customization) {
      for (let modifier in item.customization) {
        for (let option of item.customization[modifier]) {
          price.current += +option.price * +option.quantity;
        }
      }
    }
  }, [item, outofStock]);

  const token = useSelector((state) => state.authReducer.token);
  const cart = useSelector((state) => state.shopReducer.cart);

  const deliveryStore = useSelector((state) => state.shopReducer.deliveryStore);
  const [calculatedPrice, setcalulatedPrice] = useState(
    item?.calculatedPrice || 0
  );
  const [loading, setLoading] = useState(false);
  const [changeload, setChangeload] = useState(false);
  const [value, setValue] = useState(item.quantity);
  const [changeType, setChType] = useState('');
  const dispatch = useDispatch();
  const [custom, setCustom] = useState('');
  const [errDelivery, setErrDelivery] = useState('');
  const [checkStock, setCheckStock] = useState('');
  const [checkStockDelivery, setCheckStockDelivery] = useState('');
  const [outofStockTimeDelivery, setOutofStockTimeDelivery] = useState(null);
  const [outofStockTime, setOutofStockTime] = useState(null);
  const [outofStockDelivery, setOutOfStockDelivery] = useState(null);
  let date = new Date();
  const user = useSelector((state) => state.authReducer.userData);

  useEffect(() => {
    setOutOfStockDelivery(outofStockDeliveryStore);
  }, [outofStockDeliveryStore]);
  useEffect(() => {
    setcalulatedPrice(item?.calculatedPrice);
    setValue(item?.quantity);
  }, [item]);

  const handleChange = async (type, data) => {
    setChType(type);
    try {
      //get item and change it's quantity
      const bagItems = [...user?.bagItems];
      const test = (item) =>
        item.itemId == data.itemId &&
        item.quantity === data.quantity &&
        item.calculatedPrice === data.calculatedPrice;
      const index = bagItems.findIndex(test);
      bagItems[index] = {
        ...bagItems[index],
        quantity: type == 'inc' ? value + 1 : value - 1,
        calculatedPrice:
          type == 'inc'
            ? calculatedPrice + price.current
            : calculatedPrice - price.current,
      };
      dispatch(setCart(bagItems));
      const updatedUser = { ...user, bagItems: bagItems };
      dispatch(setUser({ userData: updatedUser }));
      await firestore.collection('users').doc(auth?.currentUser?.uid).set(
        {
          bagItems,
        },
        { merge: true }
      );
      code?.length && ApplyCoupon();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleRemove = async (card) => {
    setOutofStockcheck('');

    dispatch(
      updateCode({
        code: '',
        discount: 0,
        isVerify: false,
      })
    );
    setCoupon('');
    setCouponRes('');
    try {
      setLoading(true);
      if (auth?.currentUser) {

      const bags = [...user?.bagItems];
      console.log('item to delete in handle remove', item);
      const isFound = (item) =>
        item.itemId === card.itemId &&
        item.calculatedPrice === card.calculatedPrice &&
        item.quantity === card.quantity;

      const res = bags.findIndex(isFound);
      if (res !== -1) {
        bags?.splice(res, 1);
      }

      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .update({
          bagItems: bags,
        })
        .then(() => {
          dispatch(setCart(bags));
          const updatedUser = { ...user, bagItems: bags };
          dispatch(setUser({ userData: updatedUser }));
          setLoading(false);
          checkItem();
        });
      } else {
        handleRemoveNotSignedIn(card);
        setLoading(false);
        checkItem();
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };


  const handleRemoveNotSignedIn = (card) => {
 
      try {
        const storedItems = JSON.parse(localStorage.getItem('cartItems'));
        const updatedItems = storedItems.filter(
          (item) => item.itemId !== card.itemId
        );
        const updatedUser = { ...user, bagItems: updatedItems };
    
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));
        dispatch(setCart(updatedItems));
        dispatch(setUser({ userData: updatedUser }));
      } catch (error) {
        console.error('Error removing item:', error.message);
      }
  };

  useEffect(() => {
    Object?.keys(item?.customization)?.map((key, index) => {
      item.customization[key].map((cust) => {
        setCustom((prev) => prev + cust.modifierTitle + ', ');
      });
    });
    return () => {
      setCustom('');
    };
  }, [item]);
  //pickup_enabled
  //dinein_enabled
  //delivery_enabled
  // const checkItem = () => {
  //   setErrDelivery('');
  //   dispatch(CheckItem(''));
  //   if (item && !item.delivery_enabled && typeState === 'delivery') {
  //     setErrDelivery('This item is not available for delivery');
  //     dispatch(CheckItem('This item is not available for delivery'));
  //   } else if (item && !item.pickup_enabled && typeState === 'pickup') {
  //     setErrDelivery('This item is not available for pickup');
  //     dispatch(CheckItem('This item is not available for pickup'));
  //   } else if (item && !item.dinein_enabled && typeState === 'dinein') {
  //     setErrDelivery('This item is not available for dine-in');
  //     dispatch(CheckItem('This item is not available for dine-in'));
  //   } else {
  //     dispatch(CheckItem(''));
  //     setErrDelivery('');
  //   }
  //   //
  // };
 
  const checkItem = () => {
    setErrDelivery(''); // Clear any existing error message
    dispatch(CheckItem('')); // Clear the error using the Redux action
    if (item) {
      const foundItem = items?.find((i) => i?.id === item.itemId);

      if (!foundItem) {
        setErrDelivery('This item is not available');
        dispatch(CheckItem('This item is not available'));
      } else {
        if (!foundItem?.delivery_enabled && typeState === 'delivery') {
          setErrDelivery('This item is not available for delivery');
          dispatch(CheckItem('This item is not available for delivery'));
        } else if (!foundItem?.pickup_enabled && typeState === 'pickup') {
          setErrDelivery('This item is not available for pickup');
          dispatch(CheckItem('This item is not available for pickup'));
        } else if (!foundItem?.dinein_enabled && typeState === 'dinein') {
          setErrDelivery('This item is not available for dine-in');
          dispatch(CheckItem('This item is not available for dine-in'));
        } else {
          dispatch(CheckItem(''));
          setErrDelivery('');
        }
      }
    }
  };

  useEffect(() => {
    checkItem();
  }, [typeState]);
  const checkoutOfstockDineInorPick = () => {
    setOutofStockcheck(false);
    if (outofStock && outofStock?.length === 0) {
      setCheckStock('');
    } else
      outofStock &&
        outofStock.filter((c) => {
          if (c?.id === item?.itemId && typeState != 'delivery') {
            setCheckStock('This item is out of stock');
            setOutofStockcheck(true);
            setOutofStockTime(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else {
            setCheckStock('');
            setOutofStockcheck(false);
          }
        });
  };

  const checkoutOfstockDelivery = () => {
    if (outofStockDelivery && outofStockDelivery?.length === 0) {
      setCheckStockDelivery('');
    } else
      outofStockDelivery &&
        outofStockDelivery.filter((c) => {
          if (c?.id === item?.itemId && typeState === 'delivery') {
            setCheckStockDelivery('This item is out of stock');
            setOutofStockcheckDelivery(true);
            setOutofStockTimeDelivery(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStockDelivery('');
        });
  };
  useEffect(() => {
    if (checkStock != '') {
      setOutofStockcheck(true);
    } else setOutofStockcheck(false);
    if (checkStockDelivery != '') {
      setOutofStockcheckDelivery(true);
    } else setOutofStockcheckDelivery(false);
    checkoutOfstockDineInorPick();
    checkoutOfstockDelivery();
  }, [
    itemErr,
    typeState,
    errDelivery,
    outofStock,
    checkStockDelivery,
    setOutofStockTime,
    setCheckStockDelivery,
    setOutofStockcheck,
  ]);
  return (
  item && (
    <Container maxWidth="lg">
    <Box
      sx={{
        marginBottom: '15px',
        marginTop: '20px',
        backgroundColor: !page ? '#2e2e2e' : 'transparent',
        borderRadius: '20px',
        padding: '15px',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Column on small, Row on larger screens
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: { xs: '10px', sm: '0px' }, // Add spacing between elements on small screens
        width: '100%',
      }}
    >
      {/* Image & Text Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: { xs: '100%', sm: 'auto' }, // Full width on small screens
          flexWrap: 'wrap', // Ensures wrapping if needed
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            overflow: 'hidden',
            marginRight: '10px',
          }}
        >
          <img
            src={item?.image}
            alt="card"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
  
        {/* Text Section */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              color: '#D6D6D6',
              marginBottom: '5px',
              fontSize: { xs: '14px', sm: '16px' }, // Smaller text on mobile
            }}
          >
            {item?.title}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              color: 'white',
              fontSize: { xs: '14px', sm: '16px' }, // Responsive text
            }}
          >
            ${calculatedPrice?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
  
      {/* ✅ Counter + Remove Button Section (Same Row in Small Screens) */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row', // Always row
          alignItems: 'center',
          justifyContent: 'space-between',
          width: { xs: '100%', sm: 'auto' }, // Full width on small screens
          gap: '10px',
        }}
      >
        {/* Counter Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3a3a3a',
            borderRadius: '20px',
            padding: '5px 10px',
            flexWrap: 'nowrap', // Prevent wrapping
          }}
        >
          {/* Decrease Button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              backgroundColor: value > 1 ? '#2e2e2e' : 'gray',
              cursor: value > 1 ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (value > 0) {
                handleChange('dec', item);
                setValue((v) => v - 1);
                let myvalue = value - 1;
                dispatch(CurrentItemValue(myvalue));
                setcalulatedPrice((old) => old - price.current);
              }
            }}
          >
            <IoRemove sx={{ color: '#B8B8B8', fontSize: '20px' }} />
          </Box>
  
          {/* Quantity Display */}
          <Typography
            variant="h6"
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 'bold',
              color: 'white',
              margin: '0 10px',
              fontSize: { xs: '14px', sm: '16px' }, // Adjust text size on small screens
            }}
          >
            {value}
          </Typography>
  
          {/* Increase Button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              backgroundColor: value < 10 ? '#b71c1c' : 'gray',
              cursor: value < 10 ? 'pointer' : 'not-allowed',
            }}
            onClick={() => {
              if (value < 10) {
                handleChange('inc', item);
                setValue((v) => v + 1);
                let myvalue = value + 1;
                dispatch(CurrentItemValue(myvalue));
                setcalulatedPrice((old) => old + price.current);
              }
            }}
          >
            <IoAdd style={{ color: '#fff', fontSize: '25px', fontWeight: 'bold' }} />
          </Box>
        </Box>
  
        {/* Remove Button (Always in Row with Counter) */}
        <Box>
          {loading ? (
            <Snipper color={environment.mainColor} />
          ) : (
            <FaRegTimesCircle
              onClick={() => handleRemove(item)}
              style={{
                color: '#3a3a3a',
                cursor: 'pointer',
                fontSize: '25px',
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  
    {/* Divider Section */}
    {page && (
      <Divider sx={{ border: '1px solid #373737', width: '100%', mx: 'auto', my: '40px' }} />
    )}
  </Container>
  
  
)

  );
};
export default CartDrawerCard;
