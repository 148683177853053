import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchAddress from './SearchAddress';
import { CustomButton } from '@tabletuck/components';
import { Formik } from 'formik';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import AddressPickerWithGoogle from './GoogleAutoComplete';
import { useEffect, useState } from 'react';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userAddresses } from '../../../../../../libs/redux-slices/src';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';

const AddressHandlerDialog = ({ open, handleClose, setAdressvalue }) => {
  const [googleAddress, setGoogleAddress] = useState('');
  const user = useSelector((state) => state.authReducer.userData);
  const adresses = useSelector((state) => state.authReducer.userAddresses);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const [zz, setZipCode] = useState('');
  const [err, setErr] = useState();
  const addressOptions = ['Home', 'Work', 'University'];
  const getGoogleAddress = ({
    country,
    state,
    city,
    zipCode,
    placeId,
    displayName,
    lat,
    lng,
    streetNumber,
    route,
  }) => {
    setGoogleAddress({
      country,
      state,
      city,
      zipCode,
      placeId,
      displayName,
      lat,
      lng,
      streetNumber,
      route,
    });
  };
  const handleSubmit = async (values) => {
    setZipCode(values.zipCode);
    setErr('');
    setLoading(true);
    if (Object.values(googleAddress)?.length > 0) {
    googleAddress['zipCode'] = googleAddress.zipCode
      ? googleAddress.zipCode
      : values.zipCode;

    if (!(googleAddress || googleAddress?.country || googleAddress?.city))
      return setErr('Please enter a valid addrees.');
      if (!(googleAddress?.country)) {
        return setErr('Please enter a valid addrees.');
      }
    if (googleAddress.zipCode != '' ? googleAddress.zipCode : values.zipCode) {
      const doc = await firestore
        .collection('users')
        .doc(user?.uid)
        .collection('addresses')
        .doc();
      const updateDoc = await firestore.collection('restaurant').doc();
      await firestore
        .collection('users')
        .doc(user?.uid)
        .collection('addresses')
        .doc(doc.id)
        .set({
          ...googleAddress,
          additionalDetails: values.additionalDetails,
          nickName: value,
          id: doc.id,
          deliveryInstructions: values.deliveryInstructions,
          createdAt: new Date(),
          validated_on: new Date(),
          primaryLine: '',
          is_deliverable: false,
          delivery_store_id: null,
        })
        .then(() => {
          // setKey(Date.now());
          values.additionalDetails = '';
          setGoogleAddress('');
          const newObject = {
            ...googleAddress,
            additionalDetails: values.additionalDetails,
            nickName: value,
            id: doc.id,
            deliveryInstructions: values.deliveryInstructions,
            createdAt: new Date(),
            validated_on: new Date(),
            primaryLine: '',
            is_deliverable: false,
            delivery_store_id: null,
          };
          let update = [];
          if (adresses == null) {
            update = [newObject];
          } else update = [...adresses, newObject];

          dispatch(userAddresses(update));
          console.log(update, 'update');
          handleClose();
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .set(
          {
            update_items_id: updateDoc.id,
          },
          { merge: true }
        )
        .then(() => {
          console.log('updated');
          handleClose();
        })
        .catch((e) => {
          console.log('err:', e);
        });
    } else setErr('Please add a zipcode');
  } else {
    setErr("Please Add a Valid Address");
  }

  };
  const [t, i18n] = useTranslation()

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div
          style={{
            backgroundColor:"#151414",
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              color: 'white',
              cursor: 'pointer',
              marginLeft: '15px',
            }}
            onClick={handleClose}
          />
        </div>

        <DialogTitle
          style={{
            color: 'white',
            backgroundColor:"#151414",
            display: 'grid',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {t('profilee.addressDelivery')}
          </div>
        </DialogTitle>

        <DialogContent dividers sx={{  backgroundColor: '#151414'}}>
          {/* <Typography
            component="p"
            variant="h5"
            style={{
              fontWeight: 'bold',
              marginBottom: '5px',
              color: environment.mainColor,
            }}
          >
            We Deliver There.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: '20px' }}
          >
            2266 North Prospect Avenue, Milwaukee, WI, United States
          </Typography> */}
          <Typography
            component="p"
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: '5px',
              color:"white",
              fontFamily:"Outfit",
            }}
          >
            {t('profilee.addressDetails')}
          </Typography>
          <Formik
            initialValues={{
              additionalDetails: '',
              deliveryInstructions: '',
              nickName: '',
              zipCode: '',
              address: {
                additionalDetails: '',
                deliveryInstructions: '',
              },
            }}
            onSubmit={handleSubmit}
          >
            {({
              handleBlur,
              handleChange,
              values,
              errors,
              handleSubmit,
              touched,
            }) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <AddressPickerWithGoogle
                      getValues={getGoogleAddress}
                      style={{ cursor: 'pointer !important' }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                  <InputLabel
                      htmlFor="zipcode"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      {t('profilee.zipCode')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      helperText=""
                      // label="Zip Code"
                      placeholder={'Zip Code*'}
                      name="zipCode"
                      required
                      onChange={handleChange('zipCode')}
                      onBlur={handleBlur('zipCode')}
                      value={
                        values.zipCode ? values.zipCode : googleAddress.zipCode
                      }
                      onClick={() => setZipCode(values.zipCode)}
                      variant="outlined"
                      id="zipcode" // Add an id to associate with the InputLabel
                      sx={textFieldStyles}
                    />
                    {errors.zipCode && touched.zipCode ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.zipCode}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={12} xs={12}>
                  <InputLabel
                      htmlFor="additionalDetails"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      {t('profilee.additionalDetails')}
                      
                    </InputLabel>
                    <TextField
                      fullWidth
                      helperText=""
                      label={''}
                      placeholder={'Additional Details*'}
                      name="additionalDetails"
                      onChange={handleChange('additionalDetails')}
                      onBlur={handleBlur('additionalDetails')}
                      value={values.additionalDetails}
                      variant="outlined"
                      sx={textFieldStyles}
                    />
                    {errors.additionalDetails && touched.additionalDetails ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.additionalDetails}
                      </span>
                    ) : null}
                  </Grid>
                  <div style={{ height: '30px' }} />

                  <Grid item md={12} xs={12}>
                  <InputLabel
                      htmlFor="nickname"
                      shrink
                      sx={{
                        color: 'gray',
                        fontSize: '17px',
                        marginLeft: '5px',
                        fontWeight: '400',
                        fontFamily: 'Outfit, sans-serif',
                        marginBottom: '5px',
                      }}
                    >
                      Address Name
                    </InputLabel>
                    <Autocomplete
                      freeSolo
                      options={addressOptions}
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter or Select Address Name"
                          variant="outlined"
                          sx={textFieldStyles}
                        />
                      )}
                    />
                   
                  </Grid>

                  <Grid item md={12} xs={12}>
                  <InputLabel
    htmlFor="deliveryInstructions"
    shrink
    sx={{
      color: 'gray',
      fontSize: '17px',
      marginLeft: '5px',
      fontWeight: '400',
      fontFamily: 'Outfit, sans-serif',
      marginBottom: '5px',
    }}
  >
    {t('profilee.deliveryInstructions')}
  </InputLabel>
                    <TextField
                      placeholder={`${t('profilee.deliveryInstructions')}`}
                      fullWidth
                      helperText=""
                      variant="outlined"
                      // label={'Delivery Instructions'}
                      name="deliveryInstructions"
                      onChange={handleChange('deliveryInstructions')}
                      onBlur={handleBlur('deliveryInstructions')}
                      sx={textFieldStyles}
                      value={values.deliveryInstructions}
                      multiline
                      rows={3} // Set the number of rows to 3
                    />
                    {!errors.deliveryInstructions &&
                    touched.deliveryInstructions ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.deliveryInstructions}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid
                    lg={12}
                    style={{
                      display: 'flex',
                      position: 'relative',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '50%',
                        alignSelf: 'center',
                        position: 'relative',
                        // left:'10',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Typography
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                          fontSize: 15,
                          margin: 5,
                        }}
                      >
                        {err}
                      </Typography>
                      <CustomButton
                        bg={environment.mainColor}
                        loading={loading}
                        buttonText={t('profilee.saveAddress')}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        </DialogContent>
       
      </Dialog>
    </div>
  );
};

export default AddressHandlerDialog;


const textFieldStyles = {
  backgroundColor: '#121212', 
  '& label.Mui-focused': { color: 'gray' }, 
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px', 
    '& fieldset': { borderColor: '#333' }, 
    '&:hover fieldset': { borderColor: '#555' }, 
    '&.Mui-focused fieldset': { borderColor: 'gray' }, 
    '& input': { color: 'white' }, 
    '& input::placeholder': { color: 'gray', opacity: 1 }, 
  },
};
