import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  InputLabel,
} from '@mui/material';
import { Formik } from 'formik';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { firestore } from '@tabletuck/firebase-services';
import { CustomButton } from '@tabletuck/components';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { environment } from '../../../environments/environment';
import { appearance } from './cards';
import { LableForm } from './dialog';
import visa from '../../../assets/visa.png';
import axios from 'axios';
import CreditCardsform from './CreditCardsform';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {handleToaster } from '../../Utils/UtilsFunctions';

const NewPaymet = ({
  cards,
  setCards,
  open,
  setOpen,
  handleChange,
  onSelect,
  paymentFlow,
  onConfirmClover,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [err, setErr] = useState('');
  const stripe = useStripe();
  const elements = useElements({ appearance });
  const token = useSelector((state) => state.authReducer.token);
  const userData = useSelector((state) => state.authReducer.userData);
  // const [cards, setCards] = useState([]);
  const [t, i18n] = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Required'),
    zip: Yup.number()
      .typeError('Please enter a valid zip code')
      .required('Required')
      .test(
        'len',
        'Must be exactly 5 characters',
        (val) => !val || (val && val.toString().length === 5)
      ),
  });
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values) => {
    setErr('');
    if (values.name.replace(/ /g, '').length !== 0) {
      if (!stripe || !elements) return;
      try {
        setLoading(true);

        const cardElement = elements.getElement(CardNumberElement);
        let result = await stripe.createToken(cardElement, {
          name: values.name,
        });

        if (result.error) {
          console.log('error at resgistration', result.error);
          setError(result.error.message);
          setLoading(false);
          return;
        }

        createPaymentSource(result, token, values.zip);
      } catch (e) {
        setError(e.message);
        console.log('error api api api ', e.message);
        setLoading(false);
      }
    } else {
      setErr('Please enter a valid name');
    }
  };
  const createPaymentSource = async (result, token, zipCode) => {
    auth?.currentUser
      .getIdToken()
      .then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        setLoading(true);
        const data = JSON.stringify({
          token: result.token.id,
          zipCode,
        });

        return fetch(
          `${environment?.apiUrl}/addCard?restaurantId=${environment.restaurantId}`,
          {
            method: 'POST',
            headers: headers,
            body: data,
          }
        );
      })

      .then(async (response) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          console.log('Error inside response not ok:', errorResponse.message);
          setLoading(false);
          setOpen(false);

          handleToaster('error', errorResponse.message);
        }

        const Response = await response.json();
        console.log(' response inside  ok:', Response.msg);
        setLoading(false);
        setOpen(false);
        getCardsData()
        handleToaster('success', Response.msg);
      })
      .catch(async (e) => {
        console.log('api error catch', e);
        setLoading(false);
        setOpen(false);
        handleToaster('error', e.response.data.message || e.response.data.shortMessage);
      });
  };
  const getCardsData = async () => {
    console.log("get cards data function")
    try {
      firestore
        .collection(
          `users/${auth?.currentUser?.uid}/private_data/cards/sources`
        )
        .get()
        .then((docs) => {
          let arr = [];
          docs.forEach((doc) => {
            arr.push(doc.data());
          });
          setCards(arr);
        });
    } catch (e) {
      console.log('error in payment', e.message);
    }
  };

  
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Dialog
        
        open={open}
        onClose={() => {
          handleClose();
        }}
        maxWidth="sm"
      >
        <div
          style={{
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'end',
            backgroundColor:"#252525"
          }}
        >
          {' '}
          {/* <CancelRoundedIcon
            style={{
              cursor: 'pointer',
              marginLeft: '15px',
              color: 'rgb(160, 25, 19)',
            }}
            onClick={handleClose}
          /> */}
        </div>
        <DialogTitle
          style={{
            padding: 0,
            fontSize: "20px",
            textAlign: 'start',
            paddingLeft:"10px",
            color: 'white',
            fontFamily:'Outfit',
            backgroundColor:"#252525"
          }}
        >
          {paymentFlow === 'stripe'
            ? `${t('confirmPage.addNewpyament')}`
            : 'Pay for place order'}
        </DialogTitle>
        <DialogContent sx={{ padding: 2  ,backgroundColor:"#252525"}}>
          {paymentFlow === 'stripe' ? (
            <Formik
              initialValues={{
                name: '',
                zip: '',
                disabled: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleBlur, handleChange, values, errors, handleSubmit }) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <div style={{ height: 30 }} />
 <InputLabel htmlFor="zipcode" shrink sx={{color:'#C3C3C3' ,fontFamily:"Outfit" ,fontSize:"16px"}}>
                      {t('profilee.cardName')}
                    </InputLabel>
                      <TextField
                        fullWidth
                        
                    
                        name="name"
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        sx={{
                          '& label': {
                            color: '#A0A0A0', 
                          },
                          '& label.Mui-focused': {
                            color: '#A0A0A0', 
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#333', 
                            },
                            '&:hover fieldset': {
                              borderColor: '#A0A0A0', 
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#A0A0A0', 
                            },
                            '& input': {
                              color: 'white',
                            },
                          },
                        }}
                        helperText={errors.name}
                        value={values.name}
                        variant="outlined"
                      />
                      {values?.name?.replace(/ /g, '').length === 0 && (
                        <Typography variant="caption" style={{ color: 'red' }}>
                          {err}
                        </Typography>
                      )}
                    </Grid>
                    <div style={{ height: '30px' }} />
                    <Grid sx={{ display: 'flex', width: '100%' , flexDirection: "column" ,marginLeft:"25px" ,marginTop:"20px"}}>
                    <InputLabel
    htmlFor="cvc"
    shrink
    sx={{
      color: '#C3C3C3',
      fontFamily: "Outfit",
      fontSize: "18px",
    }}
  >
  {t('profilee.cardNumber')}
  </InputLabel>
                      <div
                       style={{
                        padding: 10,
                        paddingBottom: 15,
                        paddingTop: 15,
                        height: 50,
                        borderRadius: 5,
                        border: 'solid 1px #333',
                        width: '100%',
                      }}
                      >
                        {/* <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                  Card Number
                </p> */}

                        <CardNumberElement
                          id="cardNumber"
                          options={{
                            showIcon: false,
                            style: {
                              base: {
                                color: "#ffffff", 
                               
                              },
                              invalid: {
                                color: "#ff4d4d", 
                              },
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{ display: 'flex', width: '100%' }}
                    >
                       <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <InputLabel
                            htmlFor="cvc"
                            shrink
                            sx={{
                              color: '#C3C3C3',
                              fontFamily: "Outfit",
                              fontSize: "18px",
                            }}
                          >
                          MM/YY
                          </InputLabel>
                      
                      <div
                        style={{
                          marginRight: 10,
                          padding: 10,
                          paddingBottom: 15,
                          paddingTop: 15,
                          height: 50,
                          borderRadius: 5,
                          border: 'solid 1px #333',
                          width: '100%',
                          // backgroundColor: '#BDC0C3',
                        }}
                      >
                        <CardExpiryElement
                          options={{
                            placeholder: 'MM/YY*',
                          }}
                          id="expiry"
                        />
                      </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", width: "100%" , marginLeft:"20px", }}>
                      <InputLabel
                          htmlFor="cvc"
                          shrink
                          sx={{
                            color: '#C3C3C3',
                            fontFamily: "Outfit",
                            fontSize: "18px",
                          }}
                        >
                          CVC
                        </InputLabel>
                      <div
                        style={{
                          // display:'flex',alignItems:'center',
                          // margin:20,
                          padding: 10,
                          paddingBottom: 15,
                          paddingTop: 15,
                          height: 50,
                          borderRadius: 5,
                          border: 'solid 1px #333',
                          width: '100%',
                          // backgroundColor: '#BDC0C3',
                        }}
                      >
                        <CardCvcElement
                          options={{
                              
                            style: {
                              base: {
                                color: "#ffffff", 
                                
                              },
                              invalid: {
                                color: "#ff4d4d", 
                              },
                            },
                          }}
                          id="cvc"
                        />
                      </div>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {/* <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                  Zip Code
                </p> */}
                 <InputLabel htmlFor="zipcode" shrink sx={{color:'#C3C3C3' ,fontFamily:"Outfit" ,fontSize:"18px"}}>
                                      {t('profilee.zipCode')}
                                    </InputLabel>
                      <TextField
                        fullWidth
                        placeholder={'Zip Code*'}
                        name="zip"
                        onChange={handleChange('zip')}
                        onBlur={handleBlur('zip')}
                      
                        helperText={errors.zip}
                        // error
                        value={values.zip}
                        type='number'

                        variant="outlined"
                        sx={{
                          '& label': {
                            color: '#A0A0A0', // Adjust label color to match the second image
                          },
                          '& label.Mui-focused': {
                            color: '#A0A0A0', // Keeps label color the same when focused
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#333', // Set default border color
                            },
                            '&:hover fieldset': {
                              borderColor: '#A0A0A0', // Keeps the border color on hover
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#A0A0A0', // Keeps border color when focused
                            },
                            '& input': {
                              color: 'white', // Input text color
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <p style={{ color: 'red' }}>{error}</p>
                    </Grid>
                     <Box
                                            sx={{
                                              mx: 4,
                                              display: 'flex',
                                              width: '100%',
                                              alignItems: 'center',
                                              justifyContent: 'start',
                                            }}
                                          >
                                            {loading ? (
                                              <p
                                                style={{
                                                  color: environment.mainColor,
                                                  fontWeight: '700',
                                                  fontSize: 20,
                                                }}
                                              >
                                                {t('profilee.loading')}
                                              </p>
                                            ) : (
                                              <>
                                                <button
                                                 style={{
                                                           width: '15%',
                                                           boxShadow:
                                                             '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                                           color: "#fff",
                                                           fontFamily:"OutFit",
                                                           backgroundColor:"#4B4B4B",
                                                           padding: '10px',
                                                           borderRadius: '20px',
                                                           fontWeight: 'bold',
                                                           marginRight:"20px",
                                                         }}
                                                
                                                 onClick={handleClose}
                                               >
                                                 {t('profilee.cancel')}
                                                 </button>
                                              <button
                                                style={{
                                                          width: '15%',
                                                          boxShadow:
                                                            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                                          color: "#fff",
                                                          fontFamily:"OutFit",
                                                          backgroundColor:environment.mainColor,
                                                          padding: '10px',
                                                          borderRadius: '20px',
                                                          fontWeight: 'bold',
                                                        }}
                                               
                                                onClick={handleSubmit}
                                              >
                                                {t('menu.add')}
                                                </button>
                                               
                                                 </>
                                            )}
                                          </Box>
                   
                  </Grid>
                </div>
              )}
            </Formik>
          ) : (
            <CreditCardsform
              paymentFlow={paymentFlow}
              onConfirmClover={onConfirmClover}
              handleClose={handleClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewPaymet;
