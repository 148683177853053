import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  useTheme,
  CardContent,
  CardMedia,
  Card,
  Hidden,
  Divider,
  Dialog,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import reviewschool from '../../../assets/reviewschool.png';
import { useNavigate, useParams } from 'react-router-dom';
import banner from '../../../assets/banner.png';
import logo from '../../../assets/white-logo.png';
import Frame from '../../../assets/Frame 37415 (1).png';
import MenuTTdemo from '../../../assets/MenuTTdemo.png';
import menuAlyosef from '../../../assets/alyousef/Menu Image.png';
import TableTuck from '../../../assets/menufilfil.png';
import CategoryBackGround from '../../../assets/menuBackground.png';
import Entrées from '../../../assets/Entrées .png';
import Soups from '../../../assets/Soups and Salad.png';
import Breakfast from '../../../assets/Breakfast.png';
import Manakish from '../../../assets/Manakish.png';
import Kids from '../../../assets/Kids Menu.png';
import Fatayer from '../../../assets/Fatayer.png';
import Popular from '../../../assets/Popular.png';
import APPETIZERS from '../../../assets/APPETIZERS.png';
import Dessert from '../../../assets/Dessert.png';
import Bread from '../../../assets/Bread.png';
import Wraps from '../../../assets/Wraps.png';
import Drinks from '../../../assets/Drinks.png';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';

import { borderRadius, Box } from '@mui/system';
import { Link } from 'react-scroll';
import './index.css';
import { useSelector } from 'react-redux';
import { auth } from '../../../../../../libs/firebase-service/src';
import CustomizeDialoge from '../../components/CustomizeDialoge/CustomizeDialoge';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../../libs/components/src';
import {
  LoadItems,
  menues,
  res,
  setModifiers,
  setStores,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Utils/UtilsFunctions';
import Loading from 'react-fullscreen-loading';
import { Helmet } from 'react-helmet';
import { FiMinusSquare } from 'react-icons/fi';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { FaShoppingBasket } from 'react-icons/fa';
function MenuItems2() {
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [cat, setCat] = useState([]);
  const [custm, setcustom] = useState('');
  const [extraquantity, setExtraquantity] = useState({});
  const [favorites, setfav] = useState([]);
  const [extraQuantity, setExtraQuantity] = useState({});
  const [defs, setDefs] = useState([]);
   const [items, setItems] = useState([]);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const [data, setData] = React.useState([]);
  const [openn, setOpenn] = useState(false);
  const params = useParams();
  const [enableAdd, setEnableAdd] = useState(false);
  let foundType = false; // Track if 'type' category is found
  const userData = useSelector((state) => state.authReducer.userData);
  
  const menuess = useSelector((state) => state.authReducer.menues);
  const [menuItems, setMenuItems] = useState(null);
  const storess = useSelector((state) => state.shopReducer.stores);
  const [store, setStore] = useState([]);
  const [type, setType] = useState('Popular');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const activeMenu = useSelector((state) => state.authReducer.activeMenu);

  const allactiveMenues = useSelector(
    (state) => state.authReducer.allactiveMenues
  );

  // const items = useSelector((state) => state.shopReducer.currentItem);
  
  const [option, setOption] = useState(null);

  const categories = useSelector(
    (state) => state.shopReducer.currentCategories
  );
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [item, setItem] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [optionsPrice, setOptionsPrice] = useState(0);
  const user = useSelector((state) => state.authReducer.userData);

  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const [storeId, setStoreId] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const stores = useSelector((state) => state.shopReducer.stores);
  const navRef = useRef(null);

   const getUserAddress = async (token, callback, handleErr) => {
      try {
        await firestore
          .collection('users')
          .doc(userData?.uid)
          .collection('addresses')
          .get()
          .then((docs) => {
            let adds = [];
            docs.forEach((doc) => {
              adds.push(doc.data());
            });
            dispatch(userAddresses(adds));
          });
      } catch (e) {
        console.log(e);
      }
    };

  const getItems = async () => {
    try {
      let ItemsRef = firestore.collection('items');
      let list = [];
      const itemsList = await ItemsRef.where('item_state', '==', 'available').get();

      itemsList.docs.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        list.push(item);
      });

      dispatch(LoadItems(list)); // Updates Redux
      setItems(list); // Updates local state
    } catch (err) {
      console.error("Error fetching items:", err);
    }
  };


   useEffect(() => {
      getUserAddress(auth?.currentUser?.uid);
    }, []);

  useEffect(() => {
    getItems(); 
  }, []);

  useEffect(() => {
    if (!items || items.length === 0) {
      getItems();
    }
  }, [items]);

  const getMenues = async () => {
    try {
      setLoading(true);
      const apiUrl = `${environment?.apiUrl}/getMenu?restaurantId=${environment?.restaurantId}`;

      const response = await fetchData(apiUrl, 'get');
      dispatch(res(response.data));

      if (stores.length === 1) {
        const updatedUser = {
          ...userData,
          defaultStore: stores[0].id,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[stores[0]?.id]?.menus));
      } else {
        const updatedUser = {
          ...userData,
          defaultStore: userData.defaultStore,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[userData.defaultStore]?.menus));
      }
    } catch (error) {
      console.log('message Error:', error.response?.data?.message);
      // handleToaster('error' , error.response.data.message || error.response.data || "error in get menues" )
      console.log('FetchData Error:', error);
    }
    setLoading(false);
  };
  const getModifierss = async () => {
    try {
      let modifiersRef = firestore.collection('modifier_groups');
      let list = [];
      const modifierList = await modifiersRef.get();
      for (let index = 0; index < modifierList?.docs?.length; index++) {
        let element = modifierList?.docs[index]?.data();
        element.id = modifierList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setModifiers(list));
      // setModifiersGroups(modifiers);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNav = (direction) => {
    if (navRef.current) {
      if (direction === 'left') {
        navRef.current.scrollLeft -= 500;
      } else {
        navRef.current.scrollLeft += 500;
      }
    }
  };

 
 
  const getUser = async () => {
    setfav(user?.favorites);
  };

   useEffect(() => {
      getUser();
    }, [auth?.currentUser?.uid, favorites]);
  

    const addFavorite = async (id) => {
      try {
        let arr = userData?.favorites ? userData.favorites : []; // Default to empty array if undefined
        await firestore
          .collection('users')
          .doc(auth?.currentUser?.uid)
          .set(
            {
              favorites: [...arr, id],
            },
            { merge: true }
          )
          .then(() => {
            const updatedUser = { ...userData, favorites: [...arr,id] };
            dispatch(setUser({ userData: updatedUser }));
          });
      } catch (error) {
        console.log('error', error);
      }
    };
    
    const removeFavoriteTasker = async (taskerID) => {
      try {
        let arr = userData?.favorites ? userData.favorites : []; // Ensure it's always an array
    
        arr = arr.filter(function (favId) {
          return favId !== taskerID; // Use taskerID for filtering
        });
    
        await firestore
          .collection('users')
          .doc(auth?.currentUser?.uid)
          .set(
            {
              favorites: arr,
            },
            { merge: true }
          )
          .then(() => {
            const updatedUser = { ...userData, favorites: arr };
            dispatch(setUser({ userData: updatedUser }));
          });
      } catch (error) {
        console.log('error', error);
      }
    };
    



  const handleOpenn = () => {
    setId(id);
    setOpenn(true);
    setItem(item);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          storess?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };
  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraquantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item.modifier_group_ids.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setcustom((c) => (c ? c + `, ${opt.title}` : opt.title));
              setDefs((defs) =>
                defs.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
        setOptionsPrice(o > 0 ? o : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getModifiers = async () => {
    setData(modifiers);
  };

  const handleOpen = (id, item) => {
    setId(id);
    setOpen(true);
    setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = async (value) => {
    setType(value);
  };

  useEffect(() => {
    getStoreDelivery();
  }, [outofStockDelivery]);

  useEffect(() => {
    if (items?.length) {
      getDefaults();
    }
  }, [id, items?.length]);

  useEffect(() => {
    getModifiers();
    getModifierss();
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpen(false);
      } else {
        let item = items?.find((item) => item?.id === params?.id);
        handleOpen(item?.id, item);
      }
    })();
  }, [params.id]);

  useEffect(() => {
    menuess?.map((c) =>
      c.categories?.map((category, index) => {
        if (index == 1 && c?.categories[0]?.items?.length == 0) {
          setType(c?.categories[1]?.id);
        }
      })
    );
  }, [menuess]);

  useEffect(() => {
    if (menuess === undefined) {
      setLoading(true);
      getMenues();
      console.log(storess);
    }
    // setLoading(false);
  }, [storess, menuess]);

 

  return (
    <>
      {environment?.restaurantId === 'istanbulDk' ||
        (environment.restaurantId === 'taqwasbakery' && (
          <Helmet>
            <title>Taqwa's Bakery and Restaurant | Menu</title>
          </Helmet>
        ))}
      <div
        style={{
          backgroundColor: '#050505FC',
          position: 'relative',
          width: '100%',
          height: '450px',
          backgroundImage:
            environment.restaurantId === 'alyousef'
              ? `url(${menuAlyosef})`
              : environment.restaurantId === 'filfil'
              ? `url(${TableTuck})`
              : environment.restaurantId === 'ttDemo'
              ? `url(${MenuTTdemo})`
              : environment.restaurantId === 'istanbulDk'
              ? `url(${banner})`
              : environment.restaurantId === 'taqwasbakery'
              ? `url(${banner})`
              : `url(${reviewschool})`,
          // MenuTTdemo

          backgroundRepeat: 'no-repeat',
        }}
      >
         <a href="https://taqwasbakery.com/" >
        <img
          src={logo}
          alt="logo"
          style={{
            paddingTop: smDown?'50px':'10px',
            paddingLeft: '20px',
            display: 'flex',
            cursor: 'pointer',
            width: '6rem',
          }}
        />
      </a>
        <img
          src={Frame}
          alt="title"
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
      <div className="menu-container" style={{ backgroundColor: '#050505FC' }}>
        <div>
          {menuess?.length > 5 ? (
            <button onClick={() => handleNav('left')}>
              <p className="fa fa-lg fa-chevron-left">
                <KeyboardDoubleArrowLeftTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
        <div
          className="nav-items"
          ref={navRef}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            padding: '1rem',
            scrollSnapType: 'x mandatory',
            alignItems: 'stretch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {menuess?.map((c) => (
            <div>
              {c.categories?.length > 0 &&
                c.categories?.map((category, index) => (
                  <Grid
                    item
                    lg={4}
                    style={{
                      display: 'inline-block',
                      color: 'white',
                      textAalign: 'center',
                      padding: '14px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <Card
                      key={category.id}
                      style={{
                        border: '2px solid lightgray',
                        borderRadius: '20px',
                        backgroundColor: '#151414',

                        borderColor:
                          type === category?.id && category?.items?.length !== 0
                            ? '#252525'
                            : '#252525',
                        width: smDown ? '120px' : '140px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleChangeType(category.id);
                        setEnableAdd(false);
                      }}
                    >
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={category.category_image_thumb_url}
                          alt={category.category_image_thumb_url}
                          style={{ width: '60px', height: '60px' }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 'bold',
                            color: 'white',
                          }}
                        >
                          {category.name?.length > 12
                            ? category.name.slice(0, 12)
                            : category.name}
                          {category.name?.length > 12 ? '...' : ''}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </div>
          ))}
        </div>
        <div>
          {menuess?.length > 5 ? (
            <button onClick={() => handleNav('right')}>
              <p className="fa fa-lg fa-chevron-right">
                <KeyboardDoubleArrowRightTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>

      {menuess?.map((c) => (
        <div style={{  backgroundColor: '#050505FC', }}>
          {c.categories?.length > 0 &&
            c.categories?.map(
              (category, index) =>
                category.id === type && (
                  <>
                    <Box
                      key={category.id}
                      container
                      sx={{
                        height: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage:
                        category?.name === 'Breakfast'
                          ? `url(${Breakfast})`
                          :category?.name  === 'Fatayer / فطاير'
                          ? `url(${Fatayer})`
                          : category?.name === 'Manakish / مناقيش'
                          ? `url(${Manakish})`
                          : category?.name === 'Dessert'
                          ? `url(${Dessert})`
                          : category?.name === "Soups and Salads"
                          ? `url(${Soups})`
                           : category?.name === "Hot Appetizers / مقبلات"
                          ? `url(${APPETIZERS})`
                           : category?.name === "Cold Appetizers / مقبلات"
                          ? `url(${APPETIZERS})`
                          : category?.name === "Kids Menu"
                          ? `url(${Kids})`
                          : category?.name === "Bread"
                          ? `url(${Bread})`
                           : category?.name === "Wraps"
                          ? `url(${Wraps})`
                           : category?.name === "Drinks"
                          ? `url(${Drinks})`
                          : category?.name === "Entrées / الأطباق الرئيسية"
                          ? `url(${Entrées })`
                          : category?.name === "Popular"
                          ? `url(${Popular})`

                          : `url(${CategoryBackGround})`,
                       
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        marginLeft: smDown ? '-10px' : '0px',
                        marginBottom: smDown ? '30px' : '60px',
                        position: 'relative',
                      }}
                    >
                      <div
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: 'rgba(0, 0, 0, 0.5)',
                          filter: 'blur(5px)',
                          zIndex: -1,
                        }}
                      />

                      <Typography
                        variant={'h4'}
                        sx={{
                          fontFamily: 'Cormorant Infant',
                          color: 'white',
                          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
                          position: 'absolute',
                          bottom: '70px',
                          left: '50px',
                          zIndex: 2,
                          fontSize: '40px',
                          '@media (max-width: 600px)': {
                            fontSize: '25px',
                          },
                        }}
                      >
                        {category?.name}

                        <span
                          style={{
                            display: 'block',
                            width: '100%',
                            height: '20px',
                            backgroundColor: '#D92B2A',
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            zIndex: 1,
                          }}
                        />
                      </Typography>
                    </Box>

                    <Container maxWidth={'lg'} sx={{paddingBottom:"200px" }} >
                      <div id={category?.id} key={index}>
                        {
                          <>
                            {' '}
                            <Grid
                              container
                              spacing={1}
                              direction="row"
                              sx={{
                                display: { sm: 'block', lg: 'flex' },
                              }}
                            >
                              {category.items?.length > 0 &&
                                category.items?.map(
                                  (item, index) =>
                                    // item.parent_menu_id === category.id &&
                                    item.item_state === 'available' && (
                                      <>
                                        {environment?.restaurantId ===
                                        'elmbrookschools' ? (
                                          <Grid
                                            key={item.id}
                                            item
                                            lg={3}
                                            sm={6}
                                            style={{
                                              marginTop: '30px',
                                              marginBottom: '50px',
                                              display: 'grid',
                                            }}
                                          >
                                            <div
                                              onClick={() =>
                                                handleOpen(item?.id, item)
                                              }
                                            ></div>
                                            <div className="menu-item-image-container">
                                              <div
                                                className="menu-item-image-circle"
                                                style={{
                                                  backgroundColor: '#fadcbb',
                                                  borderRadius: '100px',
                                                  width: '200px',
                                                  backgroundImage:
                                                    ' linear-gradient(to top, #FBF1EA, #FBF1EA, #FFFDFC, #FFFDFC, #FFFDFC)',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <img
                                                    src={item?.item_image_url}
                                                    alt="Menu Item"
                                                    style={{
                                                      height: '80%',
                                                      width: '80%',
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '10px',
                                                  }}
                                                >
                                                  <Button
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor: 'white',
                                                      color:
                                                        environment.primaryColor,
                                                      borderRadius: '30px',
                                                    }}
                                                    onClick={() =>
                                                      handleOpen(item?.id, item)
                                                    }
                                                  >
                                                    Add to cart
                                                  </Button>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  width: '64%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                <Typography
                                                  variant="h5"
                                                  style={{
                                                    color:
                                                      environment.primaryColor,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                              </div>
                                            </div>
                                          </Grid>
                                        ) : (
                                          <>
                                            {' '}
                                            <Hidden smDown>
                                              <Grid item sm={12} lg={6}>
                                                <div
                                                 
                                                >
                                                  <div className="menu-item-details">
                                                    <Grid item lg={8} xl={8}>
                                                      <Typography
                                                        variant={'body1'}
                                                        style={{
                                                          fontWeight: 'bold',
                                                          color: '#833720',
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        {item.isCatering ? (
                                                          <>
                                                            <FiMinusSquare
                                                              style={{
                                                                marginTop:
                                                                  '4px',
                                                                marginRight:
                                                                  '3px',
                                                              }}
                                                            />
                                                            {'Catering'}
                                                          </>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </Typography>
                                                    </Grid>
                                                    <div
                                                      className="menu-item-image-container"
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom:'20px',
                                                        height:"100px",
                                                        justifyContent:
                                                          'space-between', // Spread items across the container
                                                        gap: '1rem',
                                                        width: '100%', // Ensure it spans the full width
                                                      }}
                                                    >
                                                      {/* Image Section */}
                                                      <img
                                                        src={
                                                          item?.item_image_url
                                                        }
                                                        style={{
                                                          borderRadius: '50%',
                                                          width: '80px',
                                                          height: '80px',
                                                        }}
                                                        alt="Menu Item"
                                                        className="menu-item-image"
                                                      />

                                                      {/* Item Info Section */}
                                                      <div style={{ flex: 1 }}>
                                                        {' '}
                                                        {/* Allows content to grow while keeping button aligned */}
                                                        <h6
                                                          className="menu-item-title"
                                                          style={{
                                                            color: '#D6D6D6',
                                                            fontFamily:"Outfit",
                                                            fontWeight:500,
                                                            fontSize:"20px",
                                                          }}
                                                        >
                                                          {item?.name}
                                                        </h6>
                                                        
                                                        <p className="menu-item-description" style={{
                                                          color: '#9C9C9C',
                                                          fontFamily:"Outfit",
                                                          fontWeight:400,
                                                          fontSize:"14px",
                                                        }}>
                                                          {item?.description
                                                            .length > 99
                                                            ? item?.description.slice(
                                                                0,
                                                                99
                                                              )
                                                            : item?.description}
                                                          {item?.description
                                                            .length > 99
                                                            ? '...'
                                                            : ''}
                                                        </p>
                                                      </div>
                                                      
                                                      <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#3A3232FC",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginTop:"40px",
                  }}
                  onClick={() => {
                    if (userData?.favorites?.includes(item?.id)) {
                      removeFavoriteTasker(item?.id);
                    } else {
                      addFavorite(item?.id);
                    }
                  }}
                >
                  {user?.favorites?.includes(item?.id) ? (
                    <FavoriteIcon sx={{ color: "#A01913" }} />
                  ) : (
                    <FavoriteBorderIcon sx={{ color: "#fff" }} />
                  )}
                </Box>
                                                      {/* Button Section */}
                                                      <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
     marginTop:"0px",
  }}
>
  <h6
    className="menu-item-title"
    style={{
      color: "#A01913",
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: '18px',
      marginBottom: '10px', 
    }}
  >
    ${item?.price}
  </h6>
  <button
    onClick={() => handleOpen(item?.id, item)}
    style={{
      backgroundColor: "#A01913",
      color: 'white',
      padding: '8px 25px',
      borderRadius: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Outfit',
      gap: '8px',
      border: 'none',
      cursor: 'pointer',
    }}
  >
    <FaShoppingBasket style={{ color: '#ffffff', fontSize: '14px' }} />
    <span style={{ fontSize: '14px' }}>{t('menu.add')}</span>
  </button>
</div>

                                                    </div>
                                                  </div>
                                                </div>
                                                <Divider sx={{ borderColor: '#2E2E2E', marginY: '10px' ,mx:"10px" ,height:"1px" }} />
                                              </Grid>
                                            </Hidden>
                                            {/* //in mobile */}
                                            {/* Text content */}
                                            <Hidden smUp>
                                            <Grid item xs={12} sm={12} lg={12}>
  <div
    className="menu-item-image-container"
    style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      justifyContent: 'space-between',
      gap: '1rem',
      width: '100%',
      height: 'auto',
      flexWrap: 'wrap', // Allow wrapping for smaller screens
    }}
  >
    {/* Image Section */}
    <img
      src={item?.item_image_url}
      style={{
        borderRadius: '50%',
        width: '80px',
        height: '80px',
      }}
      alt="Menu Item"
      className="menu-item-image"
    />

    {/* Text and Actions Container */}
    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
      {/* Title and Favorite Icon */}
      <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h6
          className="menu-item-title"
          style={{
            color: '#D6D6D6',
            fontFamily: "Outfit",
            fontWeight: 500,
            fontSize: "16px", // Adjusted for mobile
            flexGrow: 1,
          }}
        >
          {item?.name}
        </h6>

        {/* Favorite Icon */}
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#3A3232FC",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            if (userData?.favorites?.includes(item?.id)) {
              removeFavoriteTasker(item?.id);
            } else {
              addFavorite(item?.id);
            }
          }}
        >
          {user?.favorites?.includes(item?.id) ? (
            <FavoriteIcon sx={{ color: "#b71c1c" }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: "#fff" }} />
          )}
        </Box>
      </Grid>

      {/* Description */}
      <Grid item xs={12} sm={12}>
        <p className="menu-item-description"
          style={{
            color: '#9C9C9C',
            fontFamily: "Outfit",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {item?.description.length > 99
            ? item?.description.slice(0, 99) + "..."
            : item?.description}
        </p>
      </Grid>

      {/* Add to Cart Button */}
      <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
        onClick={() => handleOpen(item?.id, item)}
          style={{
            backgroundColor: environment.mainColor,
            color: 'white',
            padding: '8px 25px',
            borderRadius: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <FaShoppingBasket style={{ color: '#ffffff', fontSize: '14px' }} />
          <span style={{ fontSize: '14px' }}>{t('menu.add')}</span>
        </button>
      </Grid>
    </Grid>
  </div>

  <Divider sx={{ borderColor: '#2E2E2E', marginY: '10px', mx: "10px", height: "1px" }} />
</Grid>

                                            </Hidden>
                                            {/* Image */}
                                            {/* Divider */}
                                            {/* ====== */}
                                          </>
                                        )}

                                        {item?.id === id ? (
                                          <CustomizeDialoge
                                            open={open}
                                            handleClose={handleClose}
                                            option={option}
                                            c={item}
                                            priceToShow={
                                              item?.price + optionsPrice1
                                            }
                                            data={data}
                                            defs={defs}
                                            defaultPrices={optionsPrice1}
                                            extraQuantity={extraQuantity}
                                            setExtraQuantity={setExtraQuantity}
                                            outofStockDelivery={
                                              outofStockDelivery
                                            }
                                            openn={openn}
                                            handleClosee={handleClosee}
                                            handleOpenn={handleOpenn}
                                            enableAdd={enableAdd}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )
                                )}
                            </Grid>
                          </>
                        }
                      </div>
                    </Container>
                  </>
                )
            )}
        </div>
      ))}

      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </>
  );
}
export default MenuItems2;
