import { ReduxWrapper } from '@tabletuck/components';
import { FirebaseService } from 'libs/firebase-service/src';
import { environment } from '../environments/environment';
import Navigation from './Navigation/Navigation';
import { Header } from '@tabletuck/components';
import { Fade, Typography, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Footer from './components/footer/footer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from './constants';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../assets/blacklogo.png';
import ttDemo from '../assets/ttDemo.png';
import schoolLogo from '../assets/logoschool.png';
import alyosefLogo from '../assets/alyousef/alyousof-logo.png';
import logofilfil from '../assets/logofilfil.png';
import { useTranslation } from 'react-i18next';
import SchoolHeader from './components/SchoolHeader/SchoolHeader';
import '@fontsource/outfit'; 
import '@fontsource/outfit/600.css'; 

const stripePromise = loadStripe(STRIPE_KEY);
export function App() {
  const [t, i18n] = useTranslation();

  const theme = useTheme({
    breakpoints: { values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 } },
    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
  });
  const stripePromise = loadStripe(
    environment?.restaurantId == 'istanbulDk' ?
    'pk_test_51KgST9Jz0Xxp8umGhlMbwhGZywO1hI5EZbZDAEiXUZysXa6rcJ94BSQezjAdmsR2ltHkg7BwAWs2x3b0t1QJJ7xX00bg7zFH2I'
    :
    'pk_live_51KgST9Jz0Xxp8umGLUz9lWTQDmShOY4sF9XC57aj7473huXokAKlYRAm5of1k0ccnX5hboYeWlzuFYDElk12QSKN0045v8wsfg'

  );
  const [qr, setQR] = useState(false);
  const [tableNo, setTableNo] = useState('');
  const location = useLocation();
  const isSitemapPath = location.pathname === '/sitemap.xml';

  useEffect(() => {
    if (
      location?.search != '' &&
      location?.search != '?type=order' &&
      location?.search != '?type=orderDetails' &&
      location.search != '?type=personalInfo' &&
      location.search != '?type=address' &&
      location.search != '?type=payment' &&
      location.search != '?type=favoriteItems'
    ) {
      setQR(true);
      setTableNo(location?.search?.split('?table=')[1]);
    } else {
      setQR(false);
      setTableNo('');
    }
    window.localStorage.setItem('items', qr);
    window.localStorage.setItem('tabelNo', tableNo);
  }, [qr, tableNo, location?.search]);

  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   if (auth?.currentUser?.uid ) {
  //     setLoading(true)
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   } else if (auth?.currentUser?.uid === undefined) {
  //     setLoading(true)
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 3000);
  //   }
  // }, [auth?.currentUser?.uid, ]);
  useEffect(() => {
    document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n]);

  return (
    <>
      {isSitemapPath ? (
        <>
          <ReduxWrapper>
            <Navigation />
          </ReduxWrapper>
        </>
      ) : (
        <ThemeProvider theme={theme} className="App">
          {environment?.restaurantId == 'istanbulDk' || environment.restaurantId === 'taqwasbakery'  && (
            <Helmet>
              <link rel="icon" href={logo} type="image/png" />
            </Helmet>
          )}
          {/* {environment?.restaurantId == 'taqwasbakery' && (
            <Helmet>
              <link
                rel="icon"
                href={
                  'https://firebasestorage.googleapis.com/v0/b/wl-restaurants.appspot.com/o/istanbulDk%2Fsplash-image.png?alt=media&token=ce61edab-c354-4298-b0f5-600d60efa4e8'
                }
                type="image/png"
              />
            </Helmet>
          )} */}
          {environment?.restaurantId == 'alyousef' && (
            <Helmet>
              <link rel="icon" href={alyosefLogo} type="image/png" />
            </Helmet>
          )}
          {environment?.restaurantId == 'filfil' && (
            <Helmet>
              <link rel="icon" href={logofilfil} type="image/png" />
            </Helmet>
          )}
          {environment?.restaurantId == 'ttDemo' && (
            <Helmet>
              <link rel="icon" href={ttDemo} type="image/png" />
            </Helmet>
          )}
          {environment?.restaurantId == 'elmbrookschools' && (
            <Helmet>
              <link rel="icon" href={schoolLogo} type="image/png" />
            </Helmet>
          )}
          <ReduxWrapper>
            <FirebaseService
              configs={environment.firebaseConfig}
              restaurantId={environment.restaurantId}
              isRoot={environment.isRoot}
            >
              {environment.restaurantId === 'elmbrookschools' ||
              environment.restaurantId === 'alyousef' ||
              environment.restaurantId === 'filfil' ? (
                <SchoolHeader />
              ) : (
                <Header
                  env={environment}
                  isAdmin={false}
                  location={location.pathname}
                  title={environment.headerTitle}
                  restaurantId={environment.restaurantId}
                />
              )}

              <Fade in={loading} mountOnEnter unmountOnExit>
                <div
                  style={{
                    transition: '0.3s',
                    transitionDelay: '500ms',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    zIndex: 10000,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={logo}
                      width={64}
                      alt={'Taqwa'}
                      style={{ marginBottom: '12px' }}
                    />
                    <Typography
                      variant={'h5'}
                      style={{ color: 'rgba(80,80,100,0.3)' }}
                      medium
                    >
                      Taqwa's
                    </Typography>
                    <Typography
                      variant={'h5'}
                      style={{ color: 'black' }}
                      medium
                    >
                      Loading...
                    </Typography>
                  </div>
                </div>
              </Fade>
              <Elements stripe={stripePromise}>
                {!loading && <Navigation />}
              </Elements>
              {!loading && <Footer />}
            </FirebaseService>
          </ReduxWrapper>
        </ThemeProvider>
      )}
    </>
  );
}
export default App;
