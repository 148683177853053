import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Grid,
  AppBar,
  Toolbar,
  Divider,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container } from '@mui/system';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { environment } from '../../../environments/environment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


const OrderSummary = ({ open, handleClose, Transition, storeName, order }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('md'));
  const {
    uid,
    order_id,
    deliveryOption,
    store,
    order_total,
    item_total,
    order_items,
    created_at,
    order_status,
    cancellation_time,
    completed_at,
    scheduled_at,
    order_delivery_status,
    order_delivery_charge,
    order_discount,
    order_tips,
    order_tax,
    service_charges,
  } = order;
  let storeAddress = store?.address?.displayName;

  const [cancelledTime, setCancelledTime] = useState(
    new Date(cancellation_time?.seconds * 1000)
  );
  const [created, setCreated] = useState(new Date(created_at?.seconds * 1000));
  const [completed, setCompleted] = useState(new Date(completed_at?.seconds * 1000));
 const steps = ['Order Placed', 'In Progress', 'Shipped', 'Delivered'];
 const items = order_items || []; 
// Map order.order_status to a step
const statusToStep = {
  placed: 'Order Placed',
  in_progress: 'In Progress',
  shipped: 'Shipped',
  delivered: 'Delivered',
};

const currentStep = statusToStep[order?.order_status] || 'Order Placed';
 
 
  const [scheduled, setScheduled] = useState(
    new Date(scheduled_at?.seconds * 1000)
  );
  const checkItem = () => {
    if (deliveryOption === 'dinein') {
      if (order_status === 'delivered')
        return ` Order Dine-in on ${moment(completed).format('LLL')}`;
      else if (order_status === 'ready_to_pickup')
        return ' Order is ready to Dine-in';
    } else if (deliveryOption === 'pickup') {
      if (order_status === 'delivered')
        return `Order Picked up on ${moment(completed).format('LLL')}`;
      else if (order_status === 'ready_to_pickup')
        return 'Order is ready to Pickup ';
    } else if (
      deliveryOption === 'delivery' ||
      deliveryOption === 'noDriverDelivery'
    ) {
      if (order_status === 'delivered' && order_delivery_status === 'delivered')
        return `Deliverd on ${moment(completed).format('LLL')}`;
      else if (
        order_status == 'ready_to_pickup' &&
        order_delivery_status === 'pending'
      )
        return 'We are looking for a driver';
      else if (
        order_status === 'ready_to_pickup' &&
        order_delivery_status === 'assigned'
      )
        return ' Your driver is on his way to get your order';
      else if (
        order_status == 'pickedup' &&
        order_delivery_status === 'pickedup'
      )
        return 'Your driver is on his way to you';
    }
  };

  const [t, i18n] = useTranslation();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}

    >
      <Box sx={{backgroundColor: '#151414',height:"700px"}}>
     <Box
  sx={{
    position: 'relative',
    backgroundColor: '#151414',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    padding: '10px', 
  }}
>
  <Typography
    sx={{
      ml: 8,
      fontWeight: 'bold',
      color: 'white',
      fontSize: xsDown ? 25 : 33,
    }}
  >
    {t('profilee.order')} Details
  </Typography>
  <CancelRoundedIcon
    style={{ color: 'white', cursor: 'pointer' }}
    onClick={handleClose}
  />
</Box>

<Container maxWidth="lg" sx={{ backgroundColor: '#333', padding: '20px', borderRadius: '12px', color: 'white'  }}>
  <Grid container spacing={2} alignItems="center">
    {/* Order Number */}
    <Grid item xs={12} sm={6} md={6}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Order no: <span style={{ color: '#fff' }}>#{order_id}</span>
      </Typography>
    </Grid>

    {/* Order Details */}
    <Grid item xs={12} sm={6} md={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Picked Up: <span style={{ color: '#fff' }}>From Taqwas Bakery</span>
      </Typography>
    </Grid>
  </Grid>

  <Divider sx={{ my: 2, borderColor: 'gray' }} />

  <Grid container spacing={3} sx={{backgroundColor: '#333'}} >
    {/* Order Date */}
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Order Date: <span style={{ color: '#fff' }}>{moment(created).format("LLL")}</span>
      </Typography>
    </Grid>

    {/* Order Status */}
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Order Status: <span style={{ color: '#fff' }}>{order?.order_status}</span>
      </Typography>
    </Grid>

    {/* Payment Method */}
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Payment Method: <span style={{ color: '#fff' }}>{order?.payment_method}</span>
      </Typography>
    </Grid>

    {/* Estimated Delivery Date */}
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        Estimated Delivery Date: <span style={{ color: '#fff' }}>{moment(completed).format("LLL")}</span>
      </Typography>
    </Grid>
  </Grid>
</Container>
<Box
  sx={{
    display: 'flex',
    alignItems: 'center', // Ensures alignment for the circles and lines
    maxWidth: 'lg',
    marginTop: '50px',
    marginLeft: '80px',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '12px',
    color: 'white',
  }}
>
  {steps.map((step, index) => (
    <React.Fragment key={step}>
      {/* Left Line */}
      {index > 0 && (
        <Box
          sx={{
            flex: 1,
            height: '8px', 
            marginBottom:"0px",
            // Thickness of the line
            backgroundColor:
              steps.indexOf(currentStep) >= index ? '#4B4B4B' : '#444',
          }}
        />
      )}
      {/* Step Circle */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Circle */}
        <Box
          sx={{
            width: '20px',
            height: '20px',
            backgroundColor:
              currentStep === step || steps.indexOf(currentStep) > index
                ? 'red'
                : '#444',
            borderRadius: '50%',
            border: currentStep === step ? '4px solid #4B4B4B' : '2px solid transparent',
          }}
        />
        {/* Text */}
       

      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
           position: 'relative'
        }}
      >
      <Typography
          variant="body2"
          sx={{
            marginTop: '8px', 
            position: 'absolute', 
            top: '10px', 
            color:
              currentStep === step || steps.indexOf(currentStep) > index
                ? 'white'
                : 'gray',
                fontSize: '15px',
                textAlign: 'center',
                lineHeight: '1.2', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textTransform: 'capitalize',
                
          }}
        >
          {step}
        </Typography>
        </Box>
      {/* Right Line */}
      {index < steps.length - 1 && (
        <Box
          sx={{
            flex: 1,
            height: '8px',
            marginBottom:"0px", // Thickness of the line
            backgroundColor:
              steps.indexOf(currentStep) > index ? '#4B4B4B' : '#444',
          }}
        />
      )}
    </React.Fragment>
  ))}
</Box>


<Box
  sx={{
    backgroundColor: '#333',
    marginTop: '30px', 
    padding: '16px',
    borderRadius: '12px',
    color: 'white',
    width: '88%',
    mx:"auto",
  }}
>
 
    <Box
    
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#333',
        marginBottom: '12px',
      }}
    >
      {/* Item Image */}
      <Box
        component="img"
        src={order?.order_items[0]?.image}
        alt={"item"}
        sx={{
          width: '50px',
          height: '50px',
          borderRadius: '8px',
          objectFit: 'cover',
        }}
      />

      {/* Item Details */}
      <Box sx={{ marginLeft: '16px', flex: 1 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
        {items[0]?.title
 || 'Item Name'}
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
        {items[0]?.quantity || 1}
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
        ${items[0]?.price || '0.00'}
        </Typography>
      </Box>

      
      <Box>
        <IconButton
          onClick={() => handleDelete(item.id)}
          sx={{
            color: 'white',
          }}
        >
          <CancelRoundedIcon />
        </IconButton>
      </Box>
    </Box>

</Box>

</Box>
    </Dialog>
  );
};
export default OrderSummary;
