import { useState } from 'react';
import {
  Typography,
  TextField,
  Grid,
  useTheme,
  Button,
  Container,
  Hidden,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Box,
} from '@mui/material';
import { Field, Formik } from 'formik';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Loading from 'react-fullscreen-loading';
import ReactCodeInput from 'react-code-input';
import signupSchool from '../../../assets/signupSchool.png';
import signupAlyosef from '../../../assets/alyousef/login.png';
import {
  firestore,
  auth,
  recaptchaVerifier,
  _customApp,
} from '@tabletuck/firebase-services';
import signup from '../../../assets/signup.gif';
import tabletuck from '../../../assets/tabletuck.gif';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { loggedInSuccess } from '@tabletuck/redux-slices';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import CustomPhoneNumber from '../../components/PhoneNumber/PhoneInput';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { setCart, setUser } from '../../../../../../libs/redux-slices/src';
import banner from '../../../assets/background12.png';
import logo from '../../../assets/white-logo.png';

const SignUp = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [verifiedNum, setVerifiedNum] = useState(false);
  const [user, setUserstate] = useState();
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState('');
  const [t] = useTranslation();

  const storedItems = JSON.parse(localStorage.getItem('cartItems'));

  const openDialog = (user) => {
    if (user.fullName.length === 0 && user.email.length === 0) {
      setAuthenticated(false);
      // Save the current URL before login
      setOpen(true);
    } else if (user.fullName.length > 0 && user.email.length > 0) {
      setAuthenticated(true);
      setOpen(false);
      navigate('/profile');
      // window.location.href ="https://taqwasbakery.com/";
      dispatch(loggedInSuccess({ token: user.uid }));
    } else {
      setOpen(true);

      // open dialog with add email and username
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (!authenticated) {
        const formData = {
          email: values.email,
          fullName: values.fullName,
          sendSms: values.sendSms,
        };
        await firestore
          .collection('users')
          .doc(id)
          .set(formData, { merge: true })
          .catch((e) => {
            console.log(e);
          });
        setLoading(false);
        setAuthenticated(true);
        setOpen(false);
        setErr('');
        dispatch(loggedInSuccess({ token: user.uid }));
        window.location.href ="https://taqwasbakery.com/";
      } else {
        setLoading(false);
        setOpen(false);
        setErr('');
      }
    } catch (e) {
      console.log(e.message);
      setErr(`${t('tryAgain')}`);
      setLoading(false);
    }
  };
  let phoneNumbert = phoneNumber.toString();
  phoneNumbert = phoneNumbert.replace(/\s/g, '');
  phoneNumbert = phoneNumbert.replace(/-/g, '');
  phoneNumbert = phoneNumbert.replace(/[{()}]/g, '');
  phoneNumbert = '+1' + phoneNumbert;
  const handleSubmitt = async (values) => {
    setLoading(true);
    try {
      if (!user) {
        const authUser = await auth.currentUser;
        const doc = await firestore.collection('users').doc(authUser?.uid);
        await firestore
          .collection('users')
          .doc(doc.id)
          .set({
            fullName: '',
            email: '',
            phoneNumber: phoneNumbert,
            uid: authUser.uid,
            bagItems: [],
            defaultPaymentAddress: '',
            defaultDeliveryAddress: '',
            defaultStore: '',
            pickupOrDelivery: '',
            creadtedAt: new Date(),
          })
          .then(async () => {
            await getUser(doc.id);
            setErr('');
          });
      } else {
        openDialog(user);
      }
    } catch (e) {
      console.log('erorr', e.message);
      setErr(e.message);
      setLoading(false);
    }
  };

  const getUser = async (userId) => {
    setLoading('register');
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }

      const userDoc = await firestore.collection('users').doc(userId).get();

      if (userDoc.exists) {
        setUserstate(userDoc.data());
        setId(userDoc.id);
        window.localStorage.setItem('docid', userDoc.id);

        await LocalstorageAdd(userDoc.data());
        openDialog(userDoc.data());
      } else {
        console.log("Doc doesn't exist");
        handleSubmitt();
      }
    } catch (e) {
      console.log(e.message);
      setErr(e.message);
    }
  };

  const handlePinChange = (pinCode) => {
    setOTP(pinCode);

    if (pinCode.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(pinCode)
        .then(async (userCredential) => {
          const uid = userCredential.user.uid;

          await getUser(uid);

          setErr('');
          setVerifiedNum(true);
        })
        .catch((e) => {
          console.log(e.message);

          let notCorrect =
            'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code SMS and be sure to use the verification code provided by the user.';
          e.message === notCorrect
            ? setErr('Oops, incorrect code! Resend the code.')
            : setErr(e.message);
        });
    }

    setOTP('');
  };

  const requestOTP = async (e) => {
    setErr('');
    dispatch(setCart([]));
    if (phoneNumbert.length < 12) {
      setErr(`${t('validNumber')}`);
      e.preventDefault();
    } else {
      setErr('');
      e.preventDefault();
      // setExpandForm(true);
      geterate();
      let appvarifier = window.recaptchaVerifier;
      await auth
        .signInWithPhoneNumber(phoneNumbert, appvarifier)
        .then((confirmationResult, result) => {
          setExpandForm(true);
          window.confirmationResult = confirmationResult;
          window.recaptchaVerifier = null;
          setErr('');
        })
        .catch((error) => {
          console.log('rechpecha error', error.message);
          window.recaptchaVerifier.reset();
          setExpandForm(false);
          setErr(`${t('validNumber')}`);
        });
    }
  };

  const geterate = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new recaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        _customApp
      );
      // window.recaptchaVerifier.reset()

      // window.recaptchaVerifier.clear();
    } else window.recaptchaVerifier.render();
  };


  const LocalstorageAdd = async (userdata) => {
    try {
   
      setUserstate(userdata);
      const firestoreItems = userdata.bagItems;
      console.log("firestore items",firestoreItems)
      let items = [...firestoreItems];
      console.log("storedItems",storedItems)
      storedItems.forEach((newOption) => {
        const optionKeys = Object.keys(newOption?.customization);
        let optionIds = [];
        optionKeys.forEach((key) => {
          const innerArray = newOption?.customization[key];
          const ids = innerArray?.map((item) => item?.id);
          optionIds = [...optionIds, ...ids];
        });

        let itemUpdated = false;

        items = items.map((element) => {
          const elementKeys = Object.keys(element?.customization);
          let ids = [];

          elementKeys.forEach((key) => {
            const innerArray = element?.customization[key];
            const innerIds = innerArray?.map((item) => item?.id);
            ids = [...ids, ...innerIds];
          });

          if (
            element.itemId === newOption.itemId &&
            JSON.stringify(ids) === JSON.stringify(optionIds)
          ) {
            itemUpdated = true;

            return {
              ...element,
              quantity: element.quantity + newOption.quantity,
              calculatedPrice:
                newOption.calculatedPrice *
                (element.quantity + newOption.quantity),
            };
          }

          return element;
        });

        if (!itemUpdated) {
          items.push(newOption);
        }
      });
      console.log("all items",items)
      await firestore.collection('users').doc(auth?.currentUser?.uid).set(
        {
          bagItems: items,
        },
        { merge: true }
      );

      DispatchAllitems(items, doc.data());
    } catch (error) {
      console.error('Error updating Firestore and local storage:', error);
    }
  };

  const DispatchAllitems = (items, user) => {
    const updatedUser = { ...user, bagItems: items };
    dispatch(setCart(items));
    dispatch(setUser({ userData: updatedUser }));
  };

  return (
    <Box maxWidth={'xl'}  style={{
                  backgroundColor:"#050505FC",
                          position: 'relative',
                          width: '100%',
                          height: '650px',
                          backgroundImage: `url(${banner})`,
                          backgroundRepeat: 'no-repeat',
            }}>
      <Grid container >
      <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
        backgroundImage: 'url(/path-to-your-background-image.jpg)', // Replace with your image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay for readability
          padding: '30px',
          borderRadius: '10px',
          width: '90%',
          maxWidth: '400px',
          textAlign: 'center',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: 'bold',
            color: '#fff',
            marginBottom: '10px',
          }}
        >
          {t('signUp')}
        </Typography>

        <Typography
          variant="subtitle1"
          style={{
            color: 'lightgray',
            marginBottom: '20px',
          }}
        >
          {t('Once you try it, you will love it.')}
        </Typography>

        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)', 
            padding: '15px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <PhoneVerificationForm
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            expandForm={expandForm}
            verifiedNum={verifiedNum}
            requestOTP={requestOTP}
            handlePinChange={handlePinChange}
            err={err}
            inputStyle={{
              backgroundColor: '#fff', // White background for input boxes
              borderRadius: '4px',
              border: '1px solid #ccc',
              padding: '10px',
              margin: '5px',
              fontSize: '16px',
              color: '#000', // Black text for readability
              width: '50px', // Adjust width for PIN inputs
              textAlign: 'center',
            }}
          />
        </div>

        {/* <Typography
          variant="body2"
          style={{
            color: 'lightgray',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: '15px',
          }}
        
        >
          {t('loginToAccount')}
        </Typography> */}
      </div>
    </Grid>

      </Grid>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </Box>
  );
};
export default SignUp;

const SignUpDailog = ({ open, handleSubmit }) => {
  const { t } = useTranslation();
  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'User name must be only characters.')
      .min(3, 'User name must be at least 3 characters.')
      .required('User name is a required field.'),
    sendSms: Yup.boolean().oneOf([true], 'You must agree to receive messages'),
  });
  return (
    <Formik
      initialValues={{
        firstName: '',
        email: '',
        sendSms: false,
      }}
      validationSchema={validateSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleSubmit, values, handleChange }) => (
        <Dialog open={open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {t('updateYourData')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '10px' }}>
              {t('subscribeWebsite')}
            </DialogContentText>
            <Grid item xs={12}>
              <Grid item xs={12} lg={12} style={{ paddingBottom: '10px' }}>
                <Field
                  fullWidth
                  as={TextField}
                  variant="outlined"
                  name="fullName"
                  label="Full name *"
                  helperText={touched.fullName && errors.fullName}
                  error={touched.fullName && errors.fullName && true}
                  onChange={handleChange('fullName')}
                  sx={{
                    borderColor: 'gray',
                    '& label.Mui-focused': { color: 'black' },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': { borderColor: 'gray' },
                    },
                  }}
                />
              </Grid>
              <Field
                fullWidth
                as={TextField}
                type="email"
                variant="outlined"
                name="email"
                label="Email *"
                error={touched.email && errors.email && true}
                helperText={touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <MailOutlineIcon
                      style={{
                        color: environment.mainColor,
                      }}
                    />
                  ),
                }}
                onChange={handleChange('email')}
                sx={{
                  borderColor: 'gray',
                  '& label.Mui-focused': { color: 'black' },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': { borderColor: 'gray' },
                  },
                }}
              />
              <FormControl
                required
                error={touched.sendSms && Boolean(errors.sendSms)}
                component="fieldset"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.sendSms}
                        onChange={handleChange('sendSms')}
                        color="primary"
                      />
                    }
                    label="You agree to receive sms messages from our website"
                  />
                </FormGroup>
                {touched.sendSms && errors.sendSms && (
                  <FormHelperText>{errors.sendSms}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit}
              type="submit"
              style={{ color: environment.mainColor }}
            >
              {t('signUp')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

const PhoneVerificationForm = ({
  phoneNumber,
  setPhoneNumber,
  expandForm,
  verifiedNum,
  requestOTP,
  handlePinChange,
  err,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Formik
      initialValues={{
        phoneNumber: '',
      }}
    >
      {() => (
        <>
          <Grid spacing={3} container>
            <Grid
              item
              justify="space-around"
              container
              xs={12}
              spacing={1}
              style={{
                flexDirection: xsDown ? 'row' : 'unset',
                marginTop: '10px',
              }}
            >
              <Grid
                item
                xs={12}
                lg={12}
                style={{ display: 'flex', justifyContent: 'center' , color: '#fff',}}
              >
                <form onSubmit={requestOTP}>
                  <Field
                    fullWidth
                    as={CustomPhoneNumber}
                    variant="filled"
                    name="phoneNumber"
                    required
                    style={{
                      position: 'relative',
                      borderRadius: '50%',
                      direction: 'initial',
                      color:'#fff',
                    }}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    value={phoneNumber}
                    InputProps={{
                      readOnly: expandForm ? true : false,
                      style: {
                        color: '#fff', 
                      }
                    }}
                    disabled={expandForm ? true : false}
                    sx={{
                      borderColor: 'gray',
                      '& label.Mui-focused': {
                        color: '#fff',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'gray',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                  />
                  <br />
                  {!expandForm && (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{
                          backgroundColor: environment.mainColor,
                          marginTop: '15px',
                        }}
                      >
                        {t('verifyNumber')}
                      </Button>
                    </Box>
                  )}
                  <div style={{ height: '10px', width: '100px' }} />
                  {expandForm === true && verifiedNum === false && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          direction: 'initial',
                        }}
                      >
                        <ReactCodeInput
                          type="text"
                          onChange={handlePinChange}
                          fields={6}
                          inputMode="numeric"
                          pattern="[0-9]*"
                        />
                      </div>
                      <br />
                    </>
                  )}
                
                  <div id="recaptcha-container"></div>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="body"
              style={{
                color: 'red',
                fontSize: 15,
              }}
            >
              {err}
            </Typography>
          </Box>
        </>
      )}
    </Formik>
  );
};
