import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa'; 
import SuccessImage from "../../../assets/confirmed.png" ;
import { useNavigate } from 'react-router';

function OrderSuccess() {
    const navigate = useNavigate();

    const navigateToTheHistory = () => {
        navigate(`/profile/${auth?.currentUser?.uid}?type=order`);
      };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#151414' }}>
      <Box
        sx={{
          backgroundColor: '#151414',
          borderRadius: '20px',
          padding: '40px',
          boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.7)', 
          textAlign: 'center',
          marginTop:"100px" ,
          marginBottom:"200px" ,
          maxWidth: 'lg',
          width: '100%',
        }}
      >

<img
          src={SuccessImage}
          alt="Success"
          style={{
            width: '350px',
            margin: '0 auto 20px auto',
            objectFit: 'cover',
            borderRadius: '50%',

          }}
        />

       
        {/* Success Message */}
        <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>
          Your order is successfully placed
        </Typography>
        <Typography variant="body2" sx={{ color: '#d6d6d6', marginBottom: '30px' }}>
          We’ve received your order. You will receive an email confirmation soon.
        </Typography>

        {/* Buttons */}
        <Button
          variant="contained"
          onClick={() => window.location.href = 'https://taqwasbakery.com/'}
          sx={{
            backgroundColor: '#474747',
            color: 'white',
            borderRadius: '30px',
            padding: '10px 20px',
            marginRight:"20px",
            fontWeight: 'bold',
            fontFamily: 'Outfit',
            '&:hover': {
              borderColor: '#474747',
              backgroundColor:'#474747',
              color: 'white',
            },
           
            width: '200px', // Width of the button
          }}
        >
          Back to Home
        </Button>

        

        <Button
          variant="outlined"
          onClick={navigateToTheHistory}
          sx={{
           backgroundColor:"#B02719",
            color: 'white',
            borderRadius: '30px',
            padding: '10px 20px',
            fontWeight: 'bold',
            fontFamily: 'Outfit',
            '&:hover': {
              borderColor: '#B02719',
              color: '#B02719',
            },
            width: '200px',
          }}
        >
          View Order
        </Button>
      </Box>
    </div>
  );
}

export default OrderSuccess;
